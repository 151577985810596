import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import Header from "../../components/header";
import moment from "moment/moment";
import * as Api from "../../helper/api";
import Loader from "../../components/Loader";
import {PackingVo} from "../../helper/vo";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import WorkPackingDetailPage from "./WorkPackingDetailPage";
import {packingProductsData} from "../../redux/actions/productAction";

const WorkPackingPage = () => {
	const workPackingRef = useRef(null);
	const dispatch = useDispatch();
	const {profile} = useSelector(state => state.userState);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isOpenDt, setIsOpenDt] = useState(false);
	const [dtData, setDtData] = useState({paId: 0, code: '', date: ''});
	const [deliveryDate, setDeliveryDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
	// const [deliveryDate, setDeliveryDate] = useState(moment(new Date()).add(0, 'd').format('YYYY-MM-DD'));
	const [dimensions, setDimensions] = useState({width: '', height: 1000})
	const [packingData, setPackingData] = useState([]);
	const {userType} = profile;


	const goPage = (paId: number, code: string | number = '', date: string = '') : void => {
		if(!paId || !code || !date) return

		// 페이지 갱신에서 팝업으로 변경
		// navigate(`/work/pack/${paId}/${code}/${date}`)
		// setDtData({paId: 0, code: '', date: ''}) // 초기화
		setDtData({paId, code, date}) // 상세페이지에 넘겨줄 데이터 세팅
		// dtData.paId !== 0 && setIsOpenDt(true)
		setIsOpenDt(true)
	}

	const popupCallback = (callBackData: {paId: number, price : number}) => {
		// console.log('popupCallback')
		/**
		 * 부분 데이터 변경으로 상태를 변경하는걸로
		 * */
		if(callBackData.paId && callBackData.price) {
			packingData && packingData.map(item => {
				if(item.paId === callBackData.paId) {
					item.buyPrice = callBackData.price
				}
				return item
			})
		}
	}

	const updateDimensions = () => {
		if (workPackingRef.current) {
			setDimensions({
				width: workPackingRef.current.offsetWidth,
				height: workPackingRef.current.offsetHeight,
			});
		}
	};

	const getData = async (date: string) : void => {
		setIsLoaded(false)
		const targetDate = date ? date : moment(new Date()).format('YYYY-MM-DD')
		try {
			const resData = await Api.getPackingOrder_API(targetDate)
			// console.log('PackingOrder resData >>>>>', resData)
			if (!resData.data) return
			const { data, status } = resData.data
			if(status) {
				const resultData = data && data.length > 0 ? data.map(item => {
					const tempObj:PackingVo={}
					tempObj.paId = Number(item.pa_id)
					tempObj.code = item.code
					tempObj.orderTotalCount = item.order_cnt && Number(item.order_cnt)
					tempObj.deliveryDate = moment(item.delivery_dt).format('YYYY-MM-DD')
					tempObj.nameKo = item.name_ko
					tempObj.nameTh = item.name_th
					tempObj.nameEn = item.name_en
					tempObj.unit = item.unit && Number(item.unit)
					tempObj.unitName = item.unit_name
					tempObj.ssId = Number(item.ss_id)
					tempObj.buyPrice = Number(item.buy_price)

					return tempObj
				}) : []

				/**
				 * 정렬 [구매수량 많은순]
				 * */
				resultData.sort((a, b) => {
					return b.orderTotalCount - a.orderTotalCount
				})

				setPackingData(resultData)
				dispatch(packingProductsData(resultData))
			}
		}catch (e) {
			// console.log('resData error>>>>>', e)
		}finally {
			setTimeout(()=>{
				setIsLoaded(true)
			}, 300)
			updateDimensions()
		}
	}

	useEffect(()=> {
		deliveryDate && getData(deliveryDate)
	}, [deliveryDate])

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<div className={'workOrderWrapper'} ref={workPackingRef}>
			<Header userType={'MANAGER'} />
			<div className={'topTitleBox'}>
				<div className={'topTitle proUnderBox '}>상품준비<span className={'topSubTitle'}>รายการซื้อของวันนี้</span></div>
				<div className={'fontSize20 textBold'}>{
					userType === 'ADMIN'
						?
						<DatePicker
							dateFormat="yyyy-MM-dd"
							className={'datePicker'}
							// minDate={new Date(new Date().setDate(new Date().getDate() + 1))} // 선택할 수 있는 최소 날짜값 지정
							closeOnScroll={true}    // 스크롤을 움직였을 때 자동으로 닫히도록 설정 기본값 false
							selected={new Date(deliveryDate)}
							onChange={(date) => setDeliveryDate(moment(date).format('YYYY-MM-DD'))}
							onFocus={e => e.target.blur()}
							onKeyDown={(e) => {
								e.preventDefault();
							}} // 화면을 직접 수정할 수 없게 함
						/>
						:
					moment(new Date()).format('YYYY-MM-DD')
				}</div>
			</div>
			<div className={'packingListBox scrollWrapper'} style={{height: `${dimensions.height - 170}px`}}>
				{
					packingData && packingData.length ? packingData.map( (item, index) => {
						return(
							<div className={'packingItem'} key={item.paId} onClick={()=>goPage(item.paId, item.code, item.deliveryDate)}>
								<div className={'textBox'}>
									<span>{index+1}</span>
									<span className={'text01 textThi'}>{item.nameTh}</span>
									<span className={'text02'}>{item.nameKo}</span>
								</div>
								<div className={'statusBox'}>
									<div>{item.orderTotalCount} {item.unitName}</div>
									<div className={`text02 ${item.buyPrice ? 'mainColorText': 'subText777'}`}>{item.buyPrice > 0 ? 'ซื้อเสร็จแล้ว' : 'เขียนราคา'}</div>
								</div>
							</div>
						)
					}) :
					<div className={'noList'}>준비할 상품이 없습니다.</div>
				}
				<WorkPackingDetailPage isOpen={isOpenDt} setIsOpen={setIsOpenDt} paId={dtData.paId} code={dtData.code} date={dtData.date} pageCallback={popupCallback}/>
			</div>
			<Loader loading={!isLoaded}/>
		</div>
	);
};

WorkPackingPage.propTypes = {};

export default WorkPackingPage;

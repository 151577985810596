export const initialUserState = {
  isLogin: false,
  profile: {
    userId: '',
    userEmail: '',
    userType: 'NORMAL',   // NORMAL, MANAGER, ADMIN
    payType: 'CASH',      // CASH, CREDIT
    isAuthCompany: false,
    isReqGrade: false,
    grade: '',
    feeRate: 0,
    totalPrice3m: 0,
    totalPriceAll: 0,
    // isCompany: false,
  },
  company: {
    companyId: null,
    name : '',
    companyLogo: '',
    address: '',
  },
}


export const initialCommState = {
  tabName: 'HOME',
  isOpenSide: false,
  isShowBottomTab: true,
  isShowHeader: true,
  isOrderModal: false,
  isNativeWebView: false,
  alertMessage: {
    isShow: false,
    message: '',
    type: '',
  },
  board: {
    noticeList: [],
  }
}


export const initialProductState = {
  product: {
    category: 'ALL',
    initData: [],
    filteredData: [],
    marketPriceData: [],
    recentlyData: [],
    packingData: [],
  }
}


export const initialCartState = {
  cartList: []
}


export const initialOrderState = {
  orderList: []
}

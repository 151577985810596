import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import Select from "react-select";
import * as Api from "../../../helper/api";
import * as Vo from "../../../helper/vo";



Modal.setAppElement('#root');
const DeliveryZoneSelectModal = props => {
	const {success, isOpen, setIsOpen, oldZone} = props
	const [deliveryZoneList, setDeliveryZoneList] = useState([]);
	const [selectedZone, setSelectedZone] = useState(null);

	const closeModal = () => {
		setIsOpen(false)
	}

	const confirmSuccess = () => {
		if(selectedZone) {
			success(selectedZone)
			closeModal()
		}else{
			alert('배송지역을 선택해주세요.')
		}
	}

	const initView = () => {
		if(oldZone) {
			setSelectedZone(oldZone)
		}
		getDeliveryZoneData()
	}

	const getDeliveryZoneData = async () : void => {
		try {
			const resData = await Api.getDeliveryZone_API()

			if (!resData.data) return false

			const { data, status } = resData.data

			if(status){
				const resultZone: Vo.DeliverZoneVo = data.length > 0 ? data.map(item => {
					const tempObj: Vo.DeliverZoneVo = {}
					tempObj.dzId = Number(item.dz_id)
					tempObj.value = String(item.dz_id)
					tempObj.zoneCode = item.zone_code
					tempObj.label = `[${item.zone_code}] ${item.zone_name_ko}`
					tempObj.zoneNameKo = item.zone_name_ko
					tempObj.zoneNameEn = item.zone_name_en
					tempObj.zoneNameTh = item.zone_name_th

					return tempObj
				}) : []

				setDeliveryZoneList(resultZone)
			}
		}catch (e) {
			setDeliveryZoneList([])
		}finally {

		}
	}

	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={initView}
			// onRequestClose={()=>setIsOpen(false)}
			contentLabel="Example Modal"
			className="orderModalWrapper"
			overlayClassName={'modalOverlay'}
			shouldCloseOnOverlayClick={false}
		>
			<div className={'confirmModalWrapper deliveryZoneSelectModalWrapper'}>
				<div className={'confirmInBox'}>
					<div className={'text01'}>배송지역선택</div>
					<div className={'selectView'}>
						<Select
							className={'selectBox'}
							value={selectedZone}
							onChange={setSelectedZone}
							options={deliveryZoneList}
							placeholder={'지역을 선택해주세요.'}
							isSearchable={false}
						/>
					</div>
				</div>
				<div className={'flexRow confirmBtnBox'}>
					<div className={'buttonBtn cancelBtn'} onClick={closeModal}>취소</div>
					<div className={'buttonBtn confirmBtn'} onClick={confirmSuccess}>확인</div>
				</div>
			</div>
		</Modal>
	);
};

DeliveryZoneSelectModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	success: PropTypes.func.isRequired,
	oldZone: PropTypes.object,
};

export default DeliveryZoneSelectModal;

import React, {useCallback, useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {getProductsData, getRecentlyProductsData} from "./redux/actions/productAction";
import {getUserProfile} from "./redux/actions/userAction";
import {getBoardNotice, setIsOpenSide, setNativeView} from "./redux/actions/commAction";
import * as Config from './helper/config';
import * as Utils from "./helper/utils";
import './styles/css/App.scss';
import Footer from "./components/footer";
import SideBar from "./components/sideBar";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import OrderPage from "./pages/OrderPage";
import OrderDetailPage from "./pages/OrderDetailPage";
import HelpPage from "./pages/HelpPage";
import CartPage from "./pages/CartPage";
import LoginPage from "./pages/auth/LoginPage";
import SignUpPage from "./pages/auth/SignUpPage";
import FindPassPage from "./pages/auth/FindPassPage";
import WorkPackingPage from "./pages/work/WorkPackingPage";
import WorkDeliveryPage from "./pages/work/WorkDeliveryPage";
import WorkDeliveryDetailPage from "./pages/work/WorkDeliveryDetailPage";
import CompanyPage from "./pages/auth/CompanyPage";
import AuthCompanyPage from "./pages/auth/AuthCompanyPage";
import MarketPricePage from "./pages/MarketPricePage";
import LevelPage from "./pages/LevelPage";
import QuickMessage from "./components/QuickMessage";
import Loader from "./components/Loader";
import MemberMangePage from "./pages/admin/MemberMangePage";
import ChangePassPage from "./pages/auth/ChangePassPage";
import ProductMangePage from "./pages/admin/ProductMangePage";
import ReadyPage from "./pages/ReadyPage";
import OrderManagePage from "./pages/admin/OrderManagePage";
import PurchaseManagePage from "./pages/admin/PurchaseManagePage";



function App() {
  /**
   * NATIVE 에서 데이터 주입을 받기는 곳
   * @param type
   * @param data
   * @returns {Promise<void>}
   */
  window.receivedMessageFromReactNative = async (type: string, data: any) : void => {
    await nativeToJs(type, data)
  };

  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const nativeToJs = async (type: string, data?: any) : void => {
    switch (type) {
      case 'IS_NATIVE_WEBVIEW':
        dispatch(setNativeView(true))
        break;
      default:
        break;
    }
  };

  const autoLogin = useCallback( async () : void => {
    const token = await Utils.getAccessToken()
    token && await getUserProfile(dispatch)

    // 공지사항
    getBoardNotice(dispatch)

    setTimeout(()=> {
      setIsLoaded(true)
    }, 500)
  }, [dispatch])

  useEffect(() => {
    getProductsData(dispatch)
    getRecentlyProductsData(dispatch)
    autoLogin()
  }, [dispatch, autoLogin]);

  if(!isLoaded) return <Loader loading={!isLoaded}/>

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path={`${Config.PREFIX_ROUTE}/`} element={ <Navigate to="/home" /> } />
          <Route path={`${Config.PREFIX_ROUTE}/home`} element={<HomePage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/products`} element={<ProductPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/products/cart`} element={<CartPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/order`} element={<OrderPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/order/:id`} element={<OrderDetailPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/work/pack`} element={<WorkPackingPage />}/>
          {/*<Route path={`${Config.PREFIX_ROUTE}/work/pack/:paId/:code/:date`} element={<WorkPackingDetailPage />}/>*/}
          <Route path={`${Config.PREFIX_ROUTE}/work/delivery`} element={<WorkDeliveryPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/work/delivery/:orderId/:date`} element={<WorkDeliveryDetailPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/help`} element={<HelpPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/help/market_price`} element={<MarketPricePage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/help/level`} element={<LevelPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/login`} element={<LoginPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/signup`} element={<SignUpPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/findPass`} element={<FindPassPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/change_pass`} element={<ChangePassPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/company`} element={<CompanyPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/company/auth`} element={<AuthCompanyPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/company/auth/:companyId`} element={<AuthCompanyPage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/manage/member`} element={<MemberMangePage />}/>
          {/*<Route path={`${Config.PREFIX_ROUTE}/manage/member/:userId`} element={<MemberManageDetailPage />}/>*/}
          <Route path={`${Config.PREFIX_ROUTE}/manage/products`} element={<ProductMangePage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/manage/sell`} element={<OrderManagePage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/manage/purchase`} element={<PurchaseManagePage />}/>
          <Route path={`${Config.PREFIX_ROUTE}/ready`} element={<ReadyPage />}/>
          {/*<Route path={`${Config.PREFIX_ROUTE}/company`} element={<CompanyPage />}>*/}
          {/*  <Route path={`auth`} element={<AuthCompanyPage />}>*/}
          {/*    <Route path={`:companyId`} element={<AuthCompanyPage />}/>*/}
          {/*  </Route>*/}
          {/*</Route>*/}
        </Routes>
        <Footer />
        <QuickMessage />
        <SideBar />
      </Router>
    </div>
  );
}

export default App

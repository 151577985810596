import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {setIsOpenSide, setTabState} from "../../redux/actions/commAction";
import {logout} from "../../redux/actions/authAction";
import {IMG_COMPANY_300_URL, IMG_USER_NO_URL} from "../../helper/config";
import * as Utils from "../../helper/utils";

const SideBar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {isLogin, company, profile} = useSelector(state => state.userState);
	const {isOpenSide} = useSelector(state => state.commState);
	const {userType, isAuthCompany} = profile

	const goPage = (routeName = '/', tabName: string = '') => {
		dispatch(setIsOpenSide(false))
		navigate(routeName)
		tabName && dispatch(setTabState(tabName))
	}

	// const setType = (name): void => {
	// 	dispatch(setIsOpenSide(false))
	// 	dispatch(setUserType(name))
	// }

	const handleClickLogout = () => {
		logout(dispatch)
		goPage('/home', 'HOME')
	}

	const UserBadge = ({userType='NORMAL', userGrade='NEW'}) => {
		switch (userType) {
			case "NORMAL":
				if(userGrade) {
					return (
						<div className={`labelBadge`} style={{backgroundColor: `${Utils.levelTag(userGrade)}`}}>{userGrade}</div>
					)
				}else{
					return <div> </div>
				}

			case "MANAGER":
				return (<div className={'labelBadge'}>MANAGER</div>)
			case "ADMIN":
				return (<div className={'labelBadge'}>ADMIN</div>)
			default:
				return (<div> </div>)
		}
	}

	const UserStateView = ({isLogin}) => {
		if(isLogin) {
			return(
				<div className={'titleBox'}>
					<div className={'companyInfoBox'}>
						<div className={'companyLogoBox'}>
						{
							company.companyLogo
								? <img src={IMG_COMPANY_300_URL+`${company.companyLogo}`} alt="logo"/>
								: <img src={IMG_USER_NO_URL} alt="logo"/>
						}
						</div>
						{/*<div className={'companyStatusBox'}></div>*/}
					</div>
					<UserBadge userType={profile.userType} userGrade={profile.grade}/>
					<div className={'companyTitle'}>{company.name ? company.name : ' '}</div>
				</div>
			)
		}

		return(
			<div className={'titleBox'}>
				<div className={'companyInfoBox'}>
					<div className={'companyLogoBox'}>
						<img src={IMG_USER_NO_URL} alt="logo"/>
					</div>
					{/*<div className={'companyStatusBox'}></div>*/}
				</div>
				{/*<div onClick={() => goPage('/login')}>로그인/회원가입</div>*/}
			</div>
		)
	}

	return (
		<div>
			<div className={`sidebar ${isOpenSide === true ? 'active' : ''}`}>
				<UserStateView isLogin={isLogin}/>
				{
					userType === 'NORMAL' &&
					<div className="sdBody">
						<ul>
							{
								isLogin &&
								<li>
									<div className="sdLink" onClick={()=>goPage('company')}>
										<span>사업자관리</span><span className={'subText'}>การรับรองผู้ประกอบการ</span>
										{
											isAuthCompany
												? <div className={'companyBadgeBase activeBadge'}>인증됨</div>
												: <div className={'companyBadgeBase defaultBadge'}>미인증</div>
										}
									</div>
								</li>
							}
							<li><div className="sdLink" onClick={()=>goPage('/help/level')}><span>수수료안내</span><span className={'subText'}>นโยบายค่าธรรมเนียม</span></div></li>
							<li><div className="sdLink" onClick={()=>goPage('/help/market_price')}><span>시세</span><span className={'subText'}>ราคาตลาด</span></div></li>
							<li><div className="sdLink" onClick={()=>goPage('/help')}><span>공지사항</span><span className={'subText'}>เรื่องแจ้งเพื่อทราบ</span></div></li>
							{
								isLogin &&
								<li><div className="sdLink" onClick={()=>goPage('/change_pass')}><span>비밀번호변경</span><span className={'subText'}>เปลี่ยนรหัส</span></div></li>
							}
						</ul>
					</div>
				}
				{
					userType === 'ADMIN' &&
					<div className={'sdBody'}>
						<ul>
							<li><div className="sdLink" onClick={()=>goPage('/work/pack')}>상품준비</div></li>
							<li><div className="sdLink" onClick={()=>goPage('/work/delivery')}>배송현황</div></li>
							<li><div className="sdLink" onClick={()=>goPage('/manage/products')}>상품관리</div></li>
							<li><div className="sdLink" onClick={()=>goPage('/ready')}>공급처관리</div></li>
							<li><div className="sdLink"></div></li>
						</ul>
					</div>
				}
				{
					(userType === 'MANAGER' || userType === 'ADMIN') &&
					<div className={'sdBody'}>
						<ul>
							<li><div className="sdLink" onClick={()=>goPage('/change_pass')}><span>비밀번호변경</span><span className={'subText'}>เปลี่ยนรหัส</span></div></li>
							{/*	<li><div className="sdLink">배송관리</div></li>*/}
							{/*<li><div className="sdLink" onClick={()=>goPage('work/pack')}>주문서(관리자)</div></li>*/}
							{/*<li><div className="sdLink" onClick={()=>setType('NORMAL')}>사용자</div></li>*/}
							{/*<li><div className="sdLink" onClick={()=>setType('MANAGER')}>매니저</div></li>*/}
							{/*<li><div className="sdLink" onClick={()=>setType('ADMIN')}>관리자</div></li>*/}
						</ul>
					</div>
				}
				{
					isLogin
						? <span className={'logoutBox'} onClick={handleClickLogout}><span>로그아웃</span><span className={'subText'}>ล๊อคเอาท์</span></span>
						: <span className={'logoutBox'} onClick={() => goPage('/login')}><span>로그인/회원가입</span><span className={'subText'}>เข้าสู่ระบบ/สมัครสมาชิก</span></span>
				}
				<div className={'sidebarFooter'}>
					<div>2022 Market Dawoo in Thailand</div>
				</div>
			</div>
			<div className={`sidebar-overlay ${isOpenSide === true ? 'active' : ''}`}
					 onClick={() => dispatch(setIsOpenSide(false))}></div>
		</div>
	);
};

SideBar.propTypes = {
	isOpen: PropTypes.bool,
	setSideBar: PropTypes.func
};

export default SideBar;

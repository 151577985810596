import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ManageProductItem from "./productItem";
import ProductManageDetailPage from "../../../pages/admin/ProductManageDetailPage";

const ManageProductList = props => {
	const {data, height, pageCallback} = props
	const [isOpen, setIsOpen] = useState(false);
	const [productId, setProductId] = useState(0);

	const callback = (data) => {
		pageCallback(data)
	}

	const goPage = (id: number) => {
		if(!id) return
		setProductId(id)
		setIsOpen(true)
	}

	return (
		<div className={'manageProductListWrapper scrollWrapper'} style={{height: `${height}px`}}>
			{
				data && data.length > 0 ? data.map((item, index)=> {
					return(
						<ManageProductItem
							key={item.productId}
							item={item}
							goPage={goPage}
						/>
					)
				}) : <div className={'noList'}>상품목록이 없습니다.</div>
			}
			<ProductManageDetailPage productId={productId} isOpen={isOpen} setIsOpen={setIsOpen} pageCallback={callback} />
		</div>
	);
};

ManageProductList.propTypes = {
	data: PropTypes.array,
	height: PropTypes.number,
	pageCallback: PropTypes.func,
};

export default ManageProductList;

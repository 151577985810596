import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {HiArrowLeft} from "react-icons/hi";
import * as Api from "../../helper/api";
import * as Utils from "../../helper/utils";
import * as Vo from "../../helper/vo";
import {ProductVo} from "../../helper/vo";
import moment from "moment/moment";
import Loader from "../../components/Loader";
import DeliveryStatusButton from "../../components/buttons/deliveryStatusButton";
import AccountCheckPage from "./AccountCheckPage";



const OrderManageDetailPage = props => {
	const {id, isOpen, setIsOpen, pageCallback} = props
	const orderManageDtRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [dimensions, setDimensions] = useState({width: '', height: 450})
	const [orderProduct, setOrderProduct] = useState([]);
	const [storeInfo, setStoreInfo] = useState(null);
	const [isOpenAccount, setIsOpenAccount] = useState(false);
	const [orderInfo, setOrderInfo] = useState({
		id: null,
		orderDate: '',
		orderStatus: 'Y',
		deliveryDate: '',
		deliveryStatus: 'STAY',
		payStatus: 'STAY',
		payType: 'CASH',
		productPrice: 0,
		feePrice: 0,
		feeRate: 0,
		totalPrice: 0,
		accountPhoto: '',
		accountPhotoDate: '',
	});

	const initPage = () => {
		setIsLoaded(false)
		setTimeout(() => {
			getData(id)
		}, 200)
	}


	const setPayStatus = async (orderId) : void => {
		try {
			const resData = await Api.setPayStatus_API(orderId, 'DONE')
			if (!resData.data) return
			const { status } = resData.data
			if(status) {
				const tempData = orderInfo
				tempData.payStatus = 'DONE'
				setOrderInfo(tempData)
			}
		}catch (e) {

		}finally {
			setIsOpenAccount(false)
		}
	}

	const getData = async (id) : void => {
		try {
			const resData = await Api.getManageOrder_API(id)
			// console.log('order Dt resData >>>', resData)
			if (!resData.data) return
			const { data, status } = resData.data
			if(status) {
				const {productList, company} = data

				// 주문정보
				const resultOrderInfo: Vo.OrderVo = {}
				resultOrderInfo.id = Number(data.id)
				resultOrderInfo.orderDate = data.created_dt ? moment(data.created_dt).format('YYYY.MM.DD HH:mm') : ''
				resultOrderInfo.deliveryDate = data.delivery_dt ? moment(data.delivery_dt).format('YYYY.MM.DD') : ''
				resultOrderInfo.feeRate = data.fee_rate ? Number(data.fee_rate) : 0
				resultOrderInfo.productPrice = data.product_price ? Number(data.product_price) : 0
				resultOrderInfo.feePrice = data.fee_price ? Number(data.fee_price) : 0
				resultOrderInfo.totalPrice = data.total_price ? Number(data.total_price) : 0
				resultOrderInfo.deliveryStatus = data.delivery_status || 'STAY'
				resultOrderInfo.payType = data.pay_type || 'CASH'
				resultOrderInfo.payStatus = data.pay_status || 'STAY'
				resultOrderInfo.orderStatus = data.order_status || 'Y'
				resultOrderInfo.accountPhoto = data.account_photo
				resultOrderInfo.accountPhotoDate = data.account_photo_dt ? moment(data.account_photo_dt).format('YYYY.MM.DD HH:mm') : ''

				setOrderInfo(resultOrderInfo)

				// 주문상품 목록
				const orderProductItems = productList.length > 0 ? productList.map( (item) => {
					const tempObj: ProductVo= {}
					tempObj.id = Number(item.id)
					tempObj.code = item.code
					tempObj.category = item.category
					tempObj.nameKo = item.name_ko
					tempObj.nameTh = item.name_th
					tempObj.price = Number(item.price)
					tempObj.unit = parseFloat(item.unit)
					tempObj.unitName = item.unit_name
					tempObj.count = Number(item.cnt)
					return tempObj
				}) : []

				setOrderProduct(orderProductItems)

				// 상정점보
				if(company) {
					setStoreInfo({
						store : company.store,
						deliveryZone: company.delivery_zone
					})
				}
			}

		}catch (e) {

		}finally {
			// setTimeout(()=>{
				setIsLoaded(true)
			// }, 300)
			updateDimensions()
		}
	}

	const ItemView = ({title, value}) => {
		// const activeClassName = title === '배송상태' ? '' : ''
		const totalClassName = title === '주문합계' ? 'focusClass' : ''

		return (
			<div className={'secBox'}>
				<div className={'lSec'}>{title} {title === '수수료' && <span>{orderInfo.feeRate} %</span>}</div>
				<div className={`rSec ${totalClassName}`}>{value}</div>
			</div>
		)
	}

	const pageGoBack = () => {
		setIsOpen(false)
	}

	const setIsShippedSuccess = () => {
		getData(id)

		// 상위데이터 변경을 위한 콜백
		// 콜백이 좋을지 Redux 로 옮길지 고민 일단 콜백으로 구현
		pageCallback({id, status: 'READY'})
	}

	const updateDimensions = () => {
		if (orderManageDtRef.current) {
			setDimensions({
				width: orderManageDtRef.current.offsetWidth,
				height: orderManageDtRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<SlidingPane
			className="some-custom-class"
			overlayClassName="some-custom-overlay-class"
			isOpen={isOpen}
			width={'100%'}
			hideHeader={true}
			onRequestClose={() => {
				// triggered on "<" on left top click or on outside click
				// setIsOpen(false);
				pageGoBack()
			}}
			onAfterOpen={initPage}
		>
			<div className={'orderManageDtWrapper'} ref={orderManageDtRef}>
				<div className={'herderBtnBox'} style={{backgroundColor: '#eee'}}>
					<div className={'goBackBtn'} onClick={pageGoBack}><HiArrowLeft size={25}/></div>
					<div className={'titleText titleFont'}>판매상세</div>
				</div>
				{/*<div className={'topTitleBox'}>*/}
				{/*	<div className={'topTitle deliveryDtUnderBox'}>판매상세<span className={'topSubTitle'}>SELL DETAIL</span></div>*/}
				{/*	<div> </div>*/}
				{/*</div>*/}
				<div className={'infoBox'}>
					<div className={'secBox'} onClick={()=>setIsOpenAccount(true)}>
						<div className={'lSec'}>입금체크</div>
						<div className={`rSec cuBtn`}>
							{
								orderInfo.payStatus === 'DONE' ? <span className="cuBtn01">입금완료</span> :
								orderInfo.accountPhotoDate
									? <span className="cuBtn03">{orderInfo.accountPhotoDate}</span>
									: <span className="cuBtn02">입금대기</span>
							}
						</div>
					</div>
					<ItemView title={'배송상태'} value={Utils.getDeliveryStatusName(orderInfo.deliveryStatus)} />
					<ItemView title={'배송일'} value={orderInfo.deliveryDate} />
					<ItemView title={'주문일'} value={orderInfo.orderDate} />
					<ItemView title={'배송지역'} value={storeInfo ? storeInfo.deliveryZone : '-'} />
					<ItemView title={'상점명'} value={storeInfo ? storeInfo.store : '-'} />
					<ItemView title={'상품금액'} value={`${Utils.numberFormat(orderInfo.productPrice)} THB`} />
					<ItemView title={'수수료'} value={`${Utils.numberFormat(orderInfo.feePrice)} THB`} />
					<ItemView title={'주문합계'} value={`${Utils.numberFormat(orderInfo.totalPrice)} THB`} />
				</div>
				{
					(orderInfo.orderStatus === 'Y' && orderInfo.deliveryStatus === 'STAY') &&
					<div className={'omBtnBox'}>
						<DeliveryStatusButton type={'STAY'} id={Number(orderInfo.id)} isChecked={orderInfo.deliveryStatus === 'STAY'} setSuccess={setIsShippedSuccess}/>
					</div>
				}
				<div className={'orderProductList scrollWrapper'} style={{height: `${dimensions.height - (orderInfo.deliveryStatus === 'STAY' ?  410 : 340)}px`}}>
					<div className={'tit'}>주문상품 목록</div>
					{
						orderProduct && orderProduct.length > 0 ? orderProduct.map( (item, index) => {
							return (
								<div className={'orderProductItem'} key={item.id}>
									<div className={'box01'}>
										{index + 1}
									</div>
									<div className={'box02'}>
										<div className={'text01 textBold'}>{item.nameKo}</div>
										<div className={'text02'}>{item.nameTh}</div>
									</div>
									<div className={'box03'}>
										<div className={'inBox'}>
											<span className={'span02'}>{item.unit * item.count}</span>
											<span className={'span01'}>{item.unitName}</span>
										</div>
										<div className={'inBox'}>
											<span className={'span01'}>( {item.unit} {item.unitName} x {item.count} )</span>
										</div>
									</div>
									<div className={'box04'}>
										<span className={'text01'}>{item.count * item.price} <span>THB</span></span>
										<span className={'text02'}>{item.count} * {item.price}</span>
									</div>
								</div>
							)
						}) : null
					}
				</div>
			</div>
			<Loader loading={!isLoaded}/>
			<AccountCheckPage id={id} isOpen={isOpenAccount} setIsOpen={setIsOpenAccount} setPayStatus={()=>setPayStatus(id)} filename={orderInfo.accountPhoto}/>
		</SlidingPane>
	);
};

OrderManageDetailPage.propTypes = {
	id: PropTypes.number.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	pageCallback: PropTypes.func,
};

export default OrderManageDetailPage;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {InspectionCheckVO} from "../../../helper/vo";
import * as Api from "../../../helper/api";
import colors from "../../../styles/colors";
import {HashLoader} from "react-spinners";


const InspectionButton = props => {
	const {id, isChecked, isDisabled, setItemIsCheck} = props
	const [isLoaded, setIsLoaded] = useState(true);

	const setCheck = async (id, isValue) => {

		if(isDisabled) return

		const params: InspectionCheckVO = {}
		params.orderItemId = id
		params.isChecked = isValue

		setIsLoaded(false)
		try {
			const resData = await Api.setProductCheck_API(params)
			if (!resData.data) return
			const { status } = resData.data
			if(status) {
				// DB를 새로 불러 오는것 보다 불러온 데이터만 바꾼다.
				setItemIsCheck(id, isValue)
			}
		}catch (e) {
			console.log('setCheck error', e)
		}finally {
			setTimeout(()=> {
				setIsLoaded(true)
			}, 100)
		}
	}

	// ตรวจสอบแล้ว : 체크
	// เช็คสถานะ : 확인
	return (
		<div className={`loadingButtonWrapper inspectionBtnWrapper ${isLoaded && isChecked ? 'activeCheckBtn' : 'defaultCheckBtn'}`} onClick={()=>setCheck(id, !isChecked)}>
			{isLoaded
				? isChecked ? 'ตรวจสอบแล้ว' : 'เช็คสถานะ'
				:
				<div className={'loadingBox'}>
					<HashLoader color={colors.mainColor} loading={!isLoaded} size={15} />
				</div>
			}
		</div>
	);
};

InspectionButton.propTypes = {
	id: PropTypes.number.isRequired,
	isChecked: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	setItemIsCheck: PropTypes.func.isRequired,
};

export default InspectionButton;

import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {OrderManageListVo} from "../../helper/vo";
import {setAlertMessage} from "../../redux/actions/commAction";
import Header from "../../components/header";
import Loader from "../../components/Loader";
import ManageOrderList from "../../components/manage/manageOrderList";
import ConfirmMakeBuyingModal from "../../components/modal/confirmMakeBuyingModal";
import * as Api from "../../helper/api";
import moment from "moment/moment";

const OrderManagePage = () => {
	const orderMangePageRef = useRef(null);
	const dispatch = useDispatch();
	const [isLoaded, setIsLoaded] = useState(false);
	const [dimensions, setDimensions] = useState({width: '', height: 900})
	const [data, setData] = useState({initData: [], filteredData: []});
	const [filterName, setFilterName] = useState('TODAY');  // TODAY, ALL
	const [isBuyingOpen, setIsBuyingOpen] = useState(false); // 구매데이터 만들기 확인창

	const getData = async (date: string = '') : void => {
		try {
			const resData = await Api.getManageOrder_API(null, date)
			// console.log('getData >>>>', resData)
			if (!resData.data) return
			const { data, status } = resData.data
			if(status) {
				const resultData = data && data.length > 0 ? data.map(item => {
					const tempObj:OrderManageListVo={}
					tempObj.orderId = Number(item.id)
					tempObj.userId = Number(item.user_id)
					tempObj.userGrade = item.user_grade
					tempObj.deliveryStatus = item.delivery_status
					tempObj.deliveryDate = item.delivery_dt
					tempObj.orderDate = item.created_dt
					tempObj.orderStatus = item.order_status
					tempObj.payType = item.pay_type
					tempObj.payStatus = item.pay_status
					tempObj.totalPrice = item.total_price ? Number(item.total_price) : 0
					tempObj.productPrice = item.product_price ? Number(item.product_price) : 0
					tempObj.feePrice = item.fee_price ? Number(item.fee_price) : 0
					tempObj.feeRate = item.fee_rate ? Number(item.fee_rate) : 0
					tempObj.storeName = item.store

					return tempObj
				}) : []

				setData({
					initData: resultData,
					filteredData: resultData
				})
			}
		}catch (e) {

		}finally {
			setTimeout(()=>{
				setIsLoaded(true)
			}, 300)
			updateDimensions()
		}
	}

	const successMakeBuying = (date: string) => {
		makeBuyingData(date)
	}

	const makeBuyingData = async (date: string) : void => {
		setIsBuyingOpen(false)

		try{
			const resData = await Api.setMakePackingData_API(date)
			// console.log('makeBuyingData res >>>', resData)
			if (!resData.data) return
			const { status } = resData.data

			if(status) {
				dispatch(setAlertMessage({isShow: true, message: '데이터생성 완료'}))
			}

		}catch (e) {

		}
	}


	const setFilter = (name: string) => {
		if(name === 'ALL') {
			setFilterName(name)
			getData()
		}else if(name === 'TODAY') {
			setFilterName(name)
			getData(moment(new Date()).format('YYYY-MM-DD'))
		}
	}

	const setBuyingData = () => {
		if(data.filteredData.length === 0) {
			alert('생성할 데이터가 존재하지 않습니다.')
			return
		}
		setIsBuyingOpen(true)
	}


	// 상세팝업이 닫힐때 호출되는 함수
	// 데이터를 갱신해야 하거나 페이지 조작을 하기 위해 만들어 놓음
	const pageCallback = (callBackData: {id: number, status: string}) => {
		/**
		 * 전체데이터를 다시 호출하면 불필요한 호출이 생기고
		 * 무엇보다 스크롤이 조작을 다시해야 하는 불편함이 생기니
		 * 부분 데이터 변경으로 상태를 변경하는걸로
		 * */
		// if(filterName === 'ALL') {
		// 	getData()
		// }else if(filterName === 'TODAY') {
		// 	getData(moment(new Date()).format('YYYY-MM-DD'))
		// }

		if(callBackData.id && data.filteredData.length > 0) {
			data.filteredData.map(item => {
				if(item.orderId === callBackData.id) {
					item.deliveryStatus = callBackData.status
				}
				return item
			})
		}
	}

	const updateDimensions = () => {
		if (orderMangePageRef.current) {
			setDimensions({
				width: orderMangePageRef.current.offsetWidth,
				height: orderMangePageRef.current.offsetHeight,
			});
		}
	};


	useEffect(() => {
		getData(moment(new Date()).format('YYYY-MM-DD'))
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<div className={'orderManageWrapper'} ref={orderMangePageRef}>
			<Header/>
			<div className={'topTitleBox'}>
				<div className={'topTitle cartUnderBox'}>판매관리<span className={'topSubTitle'}>SELL MANAGE</span></div>
				<div>
					{
						filterName === 'TODAY'
							? <div className={`mainBuyingBtn activeRedBtn`} onClick={setBuyingData}>구입목록생성</div>
							: <div> </div>
							// : <div className={`mainBuyingBtn mainBuyingDefaultBtn`}>구입목록생성</div>
					}
				</div>
			</div>
			<div className={'filterBox'}>
				<div className={`filterItem ${filterName === 'ALL' ? 'activeFilter' : ''}`} onClick={()=>setFilter('ALL')}>전체</div>
				<div className={`filterItem ${filterName === 'TODAY' ? 'activeFilter' : ''}`} onClick={()=>setFilter('TODAY')}>오늘배송</div>
			</div>
			<ManageOrderList
				pageCallback={pageCallback}
				data={data.filteredData}
				height={dimensions.height - 220}
			/>
			<Loader loading={!isLoaded}/>
			<ConfirmMakeBuyingModal
				isOpen={isBuyingOpen}
				setIsOpen={setIsBuyingOpen}
				success={successMakeBuying}
			/>
		</div>
	);
};

OrderManagePage.propTypes = {

};

export default OrderManagePage;

import * as types from "../helpers/types";
import * as Api from '../../helper/api';
import * as Vo from "../../helper/vo";
import moment from 'moment';

// export const setOrderData = async (dispatch, data) : any => {
// 	dispatch(setOrder('dd'))
// }

export const getOrderData = async (dispatch) : boolean => {
	try	{
		const resData = await Api.getOrder_API()

		if (!resData.data) {return false}
		const { data, status } = resData.data

		if(status) {
			const resultOrderList: Vo.OrderVo[] = data.length > 0 ? data.map( item => {
				const tempObj: Vo.OrderVo = {}
				tempObj.id = Number(item.id)
				tempObj.orderDate = moment(item.created_dt).format('YYYY.MM.DD HH:mm')
				tempObj.deliveryDate = moment(item.delivery_dt).format('YYYY.MM.DD')
				tempObj.feeRate = Number(item.fee_rate)
				tempObj.productPrice = Number(item.product_price)
				tempObj.feePrice = Number(item.fee_price)
				tempObj.totalPrice = Number(item.total_price)
				tempObj.deliveryStatus = item.delivery_status
				tempObj.payType = item.pay_type
				tempObj.payStatus = item.pay_status
				tempObj.orderStatus = item.order_status
				return tempObj;
				}) : []
			dispatch(getOrderList(resultOrderList))
			return true
		}
	} catch (e) {
		// console.warn(e)
		return true
	}
}


export const getOrderList = (data) => (
	{
		type: types.GET_ORDER_LIST,
		data,
	}
)


export const setOrder = (data) => (
	{
		type: types.SET_ORDER,
		data,
	}
)

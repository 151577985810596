import axios from "axios";
import * as Config from "./config";
import * as Vo from '../helper/vo'
import {DeliveryStatusVO, InspectionCheckVO, ShippedCheckVO} from "./vo";
import {ChangePassVo, FindPassAuthCodeVO, ReSetPassVO} from "./vo";
import {PayType} from "./vo";

type MethodType =
	| 'get' | 'GET'
	| 'delete' | 'DELETE'
	| 'options' | 'OPTIONS'
	| 'post' | 'POST'
	| 'put' | 'PUT'

interface FetchParams {
	serverType: string,
	url: string,
	type: MethodType,
	headers?: any,
	params?: any,
	data?: any
}

export interface ResponseData {
	data: any,
	status: boolean,
	message: string,
}

const fetchData = (fetchParams: FetchParams): any => {
	let urlType: string
	if (fetchParams.serverType === 'P') {
		urlType = Config.API_URL
	} else {
		urlType = Config.API_URL
	}

	return axios({
		method: fetchParams.type,
		baseURL: urlType,
		url: fetchParams.url,
		headers: fetchParams.headers,
		params: fetchParams.params,
		data: fetchParams.data,
	})
}


/** ----------------------------------------------------------------------
 * 일반전인  Axios 는 Header 에 토큰을 담아서 보내는데 Refresh 는
 * 별도로 보내야 함 Refresh 를 요청할때 기본 요청으로 보내면 새로 받은 토큰을 셋팅하지 못함
 * ---------------------------------------------------------------------- */
const reFreshInstance = axios.create()
const reFreshFetch = (fetchParams: FetchParams): any => {
	let urlType: string
	if (fetchParams.serverType === 'P') {
		urlType = Config.API_URL
	} else {
		urlType = Config.API_URL
	}

	const config = {
		method: fetchParams.type,
		baseURL: urlType,
		url: fetchParams.url,
		headers: fetchParams.headers,
		params: fetchParams.params,
		data: fetchParams.data,
	}

	return reFreshInstance(config)
}


// ReFresh Token
export const refreshTokenData_API = async (reFreshToken: string): any => {
	const url = `/auth_api/refresh`
	const tempHeaders = {
		Authorization: reFreshToken ? `Bearer ${reFreshToken}` : '',
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}

	return await reFreshFetch({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: '',
		data: '',
	})
}



/**************************************************************************************************************
 * 로그인, 회원가입 AUTH
 ************************************************************************************************************ */

// 로그인
export interface LoginUser {
	email: string,
	pass: string,
}

export const login_API = (u: LoginUser): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('email', u.email)
	bodyFormData.append('pass', u.pass)

	const url = `/auth_api/login`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}



export interface JoinUser {
	email: string,
	phone: string,
	pass: string,
	rePass: string,
}

// 회원가입
export const joinUser_API = (u: JoinUser): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('email', u.email)
	bodyFormData.append('phone', u.phone)
	bodyFormData.append('pass', u.pass)
	bodyFormData.append('rePass', u.rePass)

	const url = `/auth_api/signUp`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}



export interface UserProfile {
	userId: number,
	userEmail: string,
	userType: string,
	payType: string,
	isAuthCompany: boolean,
	isReqGrade: boolean,
	grade: string,
	feeRate: number,
	totalPrice3m: number,
	totalPriceAll: number,
}

export interface UserCompany {
	companyId?: number | null,
	name?:  string | null,
	companyLogo?:  string | null,
	address?:  string | null,
}


// 사용자 프로파일
export const getUserProfile_API = (): any => {
	const url = `/users_api/profile`
	const tempHeaders = {
		Accept: 'application/json',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: '',
	})
}


// 비밀번호 찾기
export const sendEmailForPass_API = (email:string): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('email', email)

	const url = `/auth_api/findPass`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


// 비밀번호 변경 코드 체크
export const checkAuthCode_API = (data:FindPassAuthCodeVO): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('code', data.code)
	bodyFormData.append('email', data.email)
	bodyFormData.append('type', '1') // 1: 메일, 2: 문자 (미개발)

	const url = `/auth_api/checkCode`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


// 비밀번호 재세팅
export const reSetPass_API = (data:ReSetPassVO): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('email', data.email)
	bodyFormData.append('pass', data.pass)
	bodyFormData.append('rePass', data.rePass)

	const url = `/auth_api/resetPass`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}



// 비밀번호 변경
export const changePass_API = (data:ChangePassVo): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('pass', data.pass)
	bodyFormData.append('newPass', data.newPass)
	bodyFormData.append('newRePass', data.newRePass)

	const url = `/auth_api/changePass`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}



// 사업자인증
export const setAuthCompany_API = (userId: number, value: string): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('userId', userId)
	bodyFormData.append('approve', value)

	const url = `/auth_api/company`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


// 등급조정 [관리자]
export const setGrade_API = (userId: number, grade: string, feeRate: number): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('userId', userId)
	bodyFormData.append('grade', grade)
	bodyFormData.append('feeRate', feeRate)

	const url = `/auth_api/grade`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


// 등급조정요청 [사용자]
export const reqGrade_API = (): any => {
	// let bodyFormData = new FormData()
	// bodyFormData.append('userId', userId)
	// bodyFormData.append('grade', grade)
	// bodyFormData.append('feeRate', feeRate)

	const url = `/auth_api/reqGrade`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}


// 결제방법 변경 [관리자]
export const setPayType_API = (userId: number, value: PayType): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('userId', userId)
	bodyFormData.append('value', value)

	const url = `/auth_api/payType`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}








/**************************************************************************************************************
 * 회원정보 조회 [관리자]
 ************************************************************************************************************ */
export const getUsersData_API = (id: number| null = null) => {
	const url = `/users_api/users${id ? `/${id}` : ''}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: '',
	})
}













/**************************************************************************************************************
 * 공지사항
 ************************************************************************************************************ */
export const getNoticeData_API = () => {
	const url = `/board_api/notice`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: '',
	})
}










/**************************************************************************************************************
 * 상품 PRODUCT
 ************************************************************************************************************ */

export const getProduct_API = (id: string) => {
	const url = `/products_api/products${id ? `/${id}` : ''}`
	// const url = `/products`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: '',
	})
}


// 관리자 상품조회
export const getManageProducts_API = (id: number) => {
	const url = `/products_api/manageProducts${id ? `/${id}` : ''}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: '',
	})
}


// 관리자 상품등록(등록/수정)
export const setManageProducts_API = (params) => {
	const url = `/products_api/manageProducts`
	let bodyFormData = new FormData()
	bodyFormData.append('productId', params.productId ? params.productId : '')
	bodyFormData.append('isSell', params.isSell)
	bodyFormData.append('isMarket', params.isMarket)
	bodyFormData.append('category', params.category)
	bodyFormData.append('nameKo', params.nameKo)
	bodyFormData.append('nameTh', params.nameTh)
	bodyFormData.append('desc', params.desc)
	bodyFormData.append('price', params.price)
	bodyFormData.append('marketPrice', params.marketPrice)
	bodyFormData.append('unit', params.unit)
	bodyFormData.append('unitName', params.unitName)


	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}



// [홈] 최근상품
export const getRecentlyProduct_API = () => {
	const url = `/products_api/recently`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: '',
	})
}



/**************************************************************************************************************
 * 판매(주문) ORDER [관리자]
 ************************************************************************************************************ */

export const getManageOrder_API = (orderId: number | null = null, date: string = '') => {
	let url;
	if(orderId) {
		url = `/order_api/manageOrder/${orderId}`
	}else {
		url = `/order_api/manageOrder${date ? `?date=${date}` : ''}`
	}
	// const url = `/order_api/manageOrder${orderId ? `/${orderId}` : ''}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}


// [관리자]주문상품 입금체크
export const setPayStatus_API = (orderId: number, payStatus: string): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('orderId', orderId)
	bodyFormData.append('payStatus', payStatus)

	const url = `/order_api/payStatus`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}










/**************************************************************************************************************
 * 주문 ORDER
 ************************************************************************************************************ */

export const setOrder_API = (data:Vo.OrderVo) => {
	// console.log('setOrder_API', data)
	let bodyFormData = new FormData()
	bodyFormData.append('delivery_date', data.deliveryDate)
	bodyFormData.append('product_price', data.productPrice)
	bodyFormData.append('fee_price', data.feePrice)
	bodyFormData.append('total_price', data.totalPrice)
	bodyFormData.append('product_list', JSON.stringify(data.productList))
	// bodyFormData.append('pay_type', data.payType)
	// bodyFormData.append('fee_rate', data.feeRate)

	const url = `/order_api/order`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


export const getOrder_API = (orderId: string | number) => {
	const url = `/order_api/order${orderId ? `/${orderId}` : ''}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}

// 주문취소
export const cancelOrder_API = (orderId: number): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('orderId', orderId)

	const url = `/order_api/cancel`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}

// 입증금 사진업로드
export const setAccountPhoto_API = (paId: number, file: any) => {
	const bodyFormData = new FormData()
	bodyFormData.append('orderId', paId)
	bodyFormData.append('file', file)

	const url = `/order_api/accountPhoto`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}



/**************************************************************************************************************
 * 상품배송 DELIVERY
 ************************************************************************************************************ */

export const getDelivery_API = (date: string) => {
	const url = `/order_api/delivery?date=${date}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}



export const getDeliveryDt_API = (order_id: number | string, date: string) => {
	const url = `/order_api/deliveryInfo?orderId=${order_id}&date=${date}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}


// 상품상차 체크
export const setProductCheck_API = (data:InspectionCheckVO): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('orderItemId', data.orderItemId)
	bodyFormData.append('isChecked', data.isChecked)

	const url = `/order_api/check`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


// 상품배송중 체크
export const setShippedCheck_API = (data:ShippedCheckVO): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('orderItemId', data.orderItemId)
	bodyFormData.append('isShipped', data.isShipped)

	const url = `/order_api/shippedCheck`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}



// 배송상태 변경
export const setDeliveryStatus_API = (data:DeliveryStatusVO): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('orderId', data.orderId)
	bodyFormData.append('deliveryStatus', data.deliveryStatus)

	const url = `/order_api/deliverStatus`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


// 배송지 목록
export const getDeliveryZone_API = () => {
	const url = `/order_api/zone`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}


// 배송지역 설정 [관리자]
export const setDeliveryZone_API = (userId: number, dzId: number): any => {
	let bodyFormData = new FormData()
	bodyFormData.append('userId', userId)
	bodyFormData.append('dzId', dzId)

	const url = `/order_api/deliveryZone`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}













/**************************************************************************************************************
 * 상품포장 PACKING [관리자]
 ************************************************************************************************************ */

// 구매데이터 생성
export const setMakePackingData_API = (date: string) => {
	const bodyFormData = new FormData()
	bodyFormData.append('date', date)

	const url = `/packing_api/makePacking`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}



export const getPackingOrder_API = (date: string) => {
	const url = `/packing_api/packing?date=${date}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}



export const getPackingOrderDetail_API = (paId: number, code: string | number, date: string) => {
	const url = `/packing_api/packingInfo?paId=${paId}&code=${code}&date=${date}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}



// 구입가격 설정
export const setBuyingPrice_API = (id: number, price: number) => {
	const bodyFormData = new FormData()
	bodyFormData.append('paId', id)
	bodyFormData.append('price', price)

	const url = `/packing_api/buyPrice`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


// 공급처 설정
export const setSupplyStore_API = (paId: number, ssId: number) => {
	const bodyFormData = new FormData()
	bodyFormData.append('paId', paId)
	bodyFormData.append('ssId', ssId)

	const url = `/packing_api/supplyStore`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


// 구매영수증 이미지 등록
export const setReceipt_API = (paId: number, file: any) => {
	const bodyFormData = new FormData()
	bodyFormData.append('paId', paId)
	bodyFormData.append('file', file)

	const url = `/packing_api/receipt`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}



/**************************************************************************************************************
 * 매입조회 PACKING [관리자]
 ************************************************************************************************************ */

export const getPurchase_API = (stDate: string, endDate: string) => {
	const url = `/packing_api/purchase?stDate=${stDate}&endDate=${endDate}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}








/**************************************************************************************************************
 * 공급자관리 Supply Store
 ************************************************************************************************************ */
export const getSupplyStore_API = (ssId: number = null) => {
	const url = `/supplyStore_api/supplyStore${ssId ? `/${ssId}` : ''}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}











/**************************************************************************************************************
 * 사업자인증 COMPANY
 ************************************************************************************************************ */

export interface ReqCompany {
	companyId: number | null,
	companyNum: string,
	storeName: string,
	storeAddress: string,
	ePhone: string,
	employWorkTime: string,
	deliveryZone: string,
	file01: any,
	file02: any,
	file03: any,
	file04: any,
	oldFile01: string,
	oldFile02: string,
	oldFile03: string,
	oldFile04: string,
}

// 사업자인증 등록
export const setCompany_API = (data:ReqCompany) => {

	const bodyFormData = new FormData()
	bodyFormData.append('companyId', data.companyId)
	bodyFormData.append('companyNum', data.companyNum)
	bodyFormData.append('storeName', data.storeName)
	bodyFormData.append('storeAddress', data.storeAddress)
	bodyFormData.append('ePhone', data.ePhone)
	bodyFormData.append('deliveryZone', data.deliveryZone)
	bodyFormData.append('employWorkTime', data.employWorkTime)
	bodyFormData.append('file01', data.file01)
	bodyFormData.append('file02', data.file02)
	bodyFormData.append('file03', data.file03)
	bodyFormData.append('file04', data.file04)

	const url = `/company_api/company`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'POST',
		headers: tempHeaders,
		params: tempParams,
		data: bodyFormData,
	})
}


// 사업자조회
export const getCompany_API = (companyId: string | number) => {
	const url = `/company_api/company/${companyId ? companyId : ''}`
	const tempHeaders = {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	}
	const tempParams = {}
	return fetchData({
		serverType: 'P',
		url: url,
		type: 'GET',
		headers: tempHeaders,
		params: tempParams,
		data: null,
	})
}

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import colors from "../../../styles/colors";

const FaqItem = props => {
	const {item} = props
	const [isShow, setIsShow] = useState(false);

	return (
		<div className={'faqItem'}>
			<div className={'faqTit'} onClick={()=>setIsShow(!isShow)}>
				<div>[{item.tagName}] {item.title}</div>
				<div>
					{
						isShow
						? <FiChevronUp size={20} color={colors.gray05}/>
						: <FiChevronDown size={20} color={colors.gray05}/>
					}
				</div>
			</div>
			{
				isShow &&
				<div className={'faqCont'}>{item.contents}</div>
			}
		</div>
	);
};

FaqItem.propTypes = {
	item: PropTypes.object,
};

export default FaqItem;

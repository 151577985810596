import React, {useState} from 'react';
import {useSelector} from "react-redux";
import NoticeDtPage from "../../pages/NoticeDtPage";

const NoticeList = () => {
  const {board} = useSelector(state => state.commState);
  const [isOpen, setIsOpen] = useState(false);
  const [noticeItem, setNoticeItem] = useState(null);
  const {noticeList} = board

  const goPage = (id: number) => {
    if(!id) return

    const filteredItem = noticeList.filter(i => {
      return i.b_id === id
    })

    setNoticeItem(filteredItem.length > 0 && filteredItem[0])
    setIsOpen(true)
  }

  return (
    <div className={'helpNoticeBox'}>
      <div className={'titleBox'}>
        <div className={'t01'}>공지사항</div>
        <div> </div>
      </div>
      <div className={'helpNoticeList'}>
        {
          noticeList.length > 0 ? noticeList.map( item => {
            return(
              <div className={`helpNoticeItem ${item.focus_yn === 'Y' ? 'fontBold' : ''}`} key={item.b_id} onClick={()=>goPage(item.b_id)}>
                · {item.title}
              </div>
            )
          }) : <div> </div>
        }
      </div>
      <NoticeDtPage notice={noticeItem} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

NoticeList.propTypes = {

};

export default NoticeList;

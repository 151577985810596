/**
 * Token
 ***/
import colors from "../styles/colors";
import moment from "moment";

export interface TokenSet {
	access_token : string,
	refresh_token: string
}

export const getRefreshToken = (): Promise<string> => {
	return localStorage.getItem('refresh_token');
}

export const getAccessToken = () : Promise<string> => {
	return localStorage.getItem('access_token');
}

export const setAccess_token = (access_token) => {
	localStorage.setItem('access_token', access_token);
}

export const setToken = (tokenObj: TokenSet) => {
	localStorage.setItem('access_token', tokenObj.access_token);
	localStorage.setItem('refresh_token', tokenObj.refresh_token);
}

export const clearToken = () => {
	localStorage.removeItem('access_token');
	localStorage.removeItem('refresh_token');
}

export const openNativeUrl = (url: string) => {
	let message = 'OPEN_LINK';
	try {
		// @ts-ignore
		window.ReactNativeWebView.postMessage(JSON.stringify({
			message: message,
			url,
		}));

	} catch (err) {
		// console.log(err)
	}
}
/**
 * 날짜가 속한 해달월의 첫번째일과 마지막일을 구함
 * @param toDay
 * @returns {{firstDay: Date, lastDay: Date}}
 */
export const monthRange = (toDay):{firstDay:string, lastDay:string} => {
	const date = toDay ?  new Date(toDay) : new Date();
	const y = date.getFullYear();
	const m = date.getMonth();
	const firstDay = new Date(y, m, 1);
	const lastDay = new Date(y, m + 1, 0);
	return {firstDay: moment(firstDay).format('YYYY-MM-DD'), lastDay: moment(lastDay).format('YYYY-MM-DD')}
}


/**
 * 숫자 단위 (소수점포함)
 ***/
export const numberFormat = (number) => {
	return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	// return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const diffDayDate = (targetDate: string) => {
	if(!targetDate) return null
	const tempDate1 = moment(new Date(targetDate)).format('YYYY-MM-DD HH:mm:ss');
	return moment().diff( tempDate1, 'day');
}

export const getDeliveryStatusName = (type:string, lang = 'KO') => {
	switch (type) {
		case 'STAY':
			return lang === 'KO' ? '주문완료' : 'เตรียมสินค้าเสร็จสิ้น'
		case 'READY':
			return lang === 'KO' ? '상품준비중': 'กำลังเตรียมสินค้า'
		case 'ING':
			return lang === 'KO' ? '배송중': 'การส่งสินค้า'
		case 'DONE':
			return lang === 'KO' ? '배송완료': 'จัดส่งเรียบร้อยแล้ว'
		default:
			return '-'
	}
}


export const getPayStatusName = (type:string) : string => {
	switch (type) {
		case 'STAY':
			return '미입금'
		case 'CREDIT':
			return '신용'
		case 'DONE':
			return '입금확인'
		default:
			return '-'
	}
}

export const levelTag = (level:string): string => {
	switch (level) {
		case 'VVIP':
			return colors.level01
		case 'VIP':
			return colors.level02
		case 'GOLD':
			return colors.level03
		case 'SILVER':
			return colors.level04
		case 'BRONZE':
			return colors.level05
		default:
			return colors.level06
	}
}

import React from 'react';
import PropTypes from 'prop-types';
import {BiMinus,BiPlus,BiX} from "react-icons/bi";
import * as Utils from "../../../helper/utils";
import {IMG_NO_PRODUCT_100, IMG_PRODUCT_100_URL} from "../../../helper/config";

const CartItem = props => {

	const {item, removeItem, minusCount, plusCount} = props

	return (
		<div className={'cartItemWrapper'}>
			<div className={'imageBox'}>
				{
					item.photo
						? <img src={IMG_PRODUCT_100_URL+`${item.photo}`} alt="product_photo"/>
						: <img src={IMG_NO_PRODUCT_100} alt="no_photo"/>
				}
			</div>
			<div className={'contentBox'}>
				<div className={'titText'}>{item.nameKo} <span className={'textTh'}>{item.nameTh}</span></div>
				<div className={'descText'}>{item.desc}</div>

				<div className={'tempBox'}>
					<div className={'countBox'}>
						<div className={'roundBtn'} onClick={minusCount}><BiMinus size={18}/></div>
						<div className={'countText'}>{item.count}</div>
						<div className={'roundBtn'} onClick={plusCount}><BiPlus size={18}/></div>
					</div>
					<div className={'priceBox'}><span>{Utils.numberFormat(item.count * item.unit)} {item.unitName}</span>{Utils.numberFormat(item.count * item.price)} THB</div>
				</div>

				<div className={'removeBtn'} onClick={removeItem}>
					<BiX size={17}/>
				</div>

			</div>
		</div>
	);
};

CartItem.propTypes = {
	item: PropTypes.object,
	removeItem: PropTypes.func,
	minusCount: PropTypes.func,
	plusCount: PropTypes.func,
};

export default CartItem;

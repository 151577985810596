import React, {useEffect, useRef, useState} from 'react';
import Header from "../../components/header";
import Loader from "../../components/Loader";
import Calendar from 'react-calendar';
import moment from "moment";
import * as Utils from "../../helper/utils";
import * as Api from "../../helper/api";
import {monthRange} from "../../helper/utils";

const PurchaseManagePage = () => {
	const purchaseMangePageRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(true);
	const [dimensions, setDimensions] = useState({width: '', height: 900})
	const [value, onChange] = useState(new Date());
	const [priceData, setPriceData] = useState([]);

	const priceView = (date) => {
		const data = priceData.find((x) => x.date === moment(date).format("YYYY-MM-DD"))
		if (data !== undefined) {
			return (
				<div className="priceBox">
					<div className={''}>{data.price && Utils.numberFormat(data.price)}</div>
				</div>
			)
		}
	}

	const getData = async (date:{firstDay:string, lastDay:string}|null = null):void => {

		const defaultDateRage = Utils.monthRange()
		let startDay:string = defaultDateRage.firstDay
		let eneDay:string = defaultDateRage.lastDay

		if(date) {
			startDay = date.firstDay
			eneDay = date.lastDay
		}

		try {
			const resData = await Api.getPurchase_API(startDay, eneDay)
			if (!resData.data) return
			const { status, data } = resData.data

			if(status) {
				const resultData = data.length > 0 ? data.map( item => {
					const tempObj = {}
						tempObj.id = Number(item.pa_id)
						tempObj.price = Number(item.buy_price)
						tempObj.date = moment(item.delivery_dt).format('YYYY-MM-DD')
						return tempObj
				}) : []

				setPriceData(resultData)
			}
		}catch (e) {

		}
	}

	const dateChange = (e) => {
		onChange(e)
		// console.log('dateChange', e)
	}

	const changedMonth = (e) => {
		// console.log('dateChange', e)
		// console.log('Clicked month: ', moment(e).format('YYYY-MM-DD'))
	}

	const changedDate = (activeStartDate, view) => {
		// view 가 month 일때만 호출 한다.
		// console.log('Changed view to: activeStartDate', moment(activeStartDate).format('YYYY-MM-DD'))
		// console.log('Changed view to:view', view)
		// console.log('trans Date ***', Utils.monthRange(activeStartDate))
		if(view === 'month') {
			getData(Utils.monthRange(activeStartDate))
		}
	}

	const updateDimensions = () => {
		if (purchaseMangePageRef.current) {
			setDimensions({
				width: purchaseMangePageRef.current.offsetWidth,
				height: purchaseMangePageRef.current.offsetHeight,
			});
		}
	};

	useEffect(()=> {
		getData()
	}, [])

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	// const onActiveStartDateChangeHandler = ({ activeStartDate, value, view }) => {
	// 	console.log("vv:", activeStartDate, value, view);
	// };

	// let teamStartDate;
	// const onActiveStartDateChange = ({activeStartDate}) => teamStartDate = activeStartDate
	//
	// console.log('teamStartDate >>>', teamStartDate)

	return (
		<div className={'purchaseMangeWrapper'} ref={purchaseMangePageRef}>
			<Header/>
			<div className={'topTitleBox'}>
				<div className={'topTitle cartUnderBox'}>구매관리<span className={'topSubTitle'}>PURCHASE MANAGE</span></div>
				<div> </div>
			</div>
			<div className={'calendarBox'}>
				<Calendar
					onChange={dateChange}
					value={value}
					calendarType={'US'}
					onClickMonth={changedMonth}
					// onClickDay={(e)=>alert(e)}
					formatDay={(locale, date) => moment(date).format("DD")} // 날'일' 제외하고 숫자만 보이도록 설정
					// tileContent={({ date, view }) => { // 날짜 타일에 컨텐츠 추가하기 (html 태그)
					// 	// 추가할 html 태그를 변수 초기화
					// 	let html = [];
					// 	// 현재 날짜가 post 작성한 날짜 배열(mark)에 있다면, dot div 추가
					// 	if (mark.find((x) => x.date === moment(date).format("YYYY-MM-DD"))) {
					// 		html.push(<div className="price"></div>);
					// 	}
					// 	// 다른 조건을 주어서 html.push 에 추가적인 html 태그를 적용할 수 있음.
					// 	return (
					// 		<div className="flex justify-center items-center absoluteDiv">
					// 			{html}
					// 		</div>
					// 	);
					// }}
					onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
						changedDate(activeStartDate, view)
						// console.log('Changed view to: activeStartDate', moment(activeStartDate).format('YYYY-MM-DD'))
						// console.log('Changed view to: value', moment(value).format('YYYY-MM-DD'))
						// console.log('Changed view to:view', view)
					}}
					tileContent={({ date, view }) => { // 날짜 타일에 컨텐츠 추가하기 (html 태그)
						return priceView(date)
					}}
				/>
			</div>
			<div>
				<div>
					{/*{value}*/}
				</div>
			</div>
			<Loader loading={!isLoaded}/>
		</div>
	);
};

PurchaseManagePage.propTypes = {

};

export default PurchaseManagePage;

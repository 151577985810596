import createReducer from "../helpers/createReducers";
import * as initialType from '../helpers/initialTypes';
import * as types from "../helpers/types";


export const userState = createReducer( initialType.initialUserState, {
  [types.SET_USER_INIT](state, action) {
    return (
      initialType.initialUserState
    );
  },
  [types.SET_USER_LOGIN](state, action) {
    return ({
      ...state,
      isLogin: action.data
    });
  },
  [types.SET_USER_PROFILE](state, action) {
    return ({
      ...state,
      isLogin: true,
      profile: {
        ...state.profile,
        userId: action.data.id,
        userEmail: action.data.email,
        userType: action.data.userType,
        payType: action.data.payType,
        isAuthCompany: action.data.isAuthCompany,
        isReqGrade: action.data.isReqGrade,
        grade: action.data.grade,
        feeRate: action.data.feeRate,
        totalPrice3m: action.data.totalPrice3m,
        totalPriceAll: action.data.totalPriceAll,
      }
    });
  },
  [types.SET_USER_COMPANY](state, action) {
    return ({
      ...state,
      company: {
        ...state.company,
        companyId: action.data.companyId,
        name: action.data.name,
        companyLogo: action.data.companyLogo,
        address: action.data.address
      }
    });
  },
  [types.SET_USER_TYPE](state, action) {
    return ({
      ...state,
      profile: {
        ...state.profile,
        userType: action.data
      }
    });
  },
  [types.SET_USER_REQ_GRADE](state, action) {
    return ({
      ...state,
      profile: {
        ...state.profile,
        isReqGrade: action.data
      }
    });
  },

})

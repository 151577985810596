import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {signup} from "../../redux/actions/authAction";
import type {JoinUser} from "../../helper/api";
import Header from "../../components/header";

const SignUpPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [pass, setPass] = useState('');
	const [rePass, setRePass] = useState('');
	const [message, setMessage] = useState('');


	const validate = () => {
		if(!email) {
			setMessage('이메일을 입력해주세요.')
			return false
		}

		if(!phone) {
			setMessage('연락처를 입력해주세요.')
			return false
		}

		if(!pass) {
			setMessage('비밀번호를 입력해주세요.')
			return false
		}

		if(!rePass) {
			setMessage('비밀번호를 다시한번 입력해주세요.')
			return false
		}

		if(pass !== rePass) {
			setMessage('비밀번호가 일치하지 않습니다')
			return false
		}

		return true
	}

	const regUser = async () => {

		if(!validate()) return

		const reqData: JoinUser = {};
		reqData.email = email
		reqData.phone = phone
		reqData.pass = pass
		reqData.rePass = rePass

		const resData = await signup(dispatch, reqData)
		const {data} = resData
		console.log('resData ....', resData)
		if(data.status) {
			navigate('/home')
		}else {
			setMessage(data.message)
		}
	}


	useEffect(() => {
		if(email) { setMessage('')}
		if(phone) { setMessage('')}
		if(pass) { setMessage('')}
		if(rePass) { setMessage('')}
	}, [email, phone, pass, rePass]);


	return (
		<div className={'signInWrapper'}>
			<Header/>
			<div className={'authBox'}>
				<div className={'loginTit'}>회원가입</div>
				<div className={'idAuthBox'}>
					<div className={'inputBox'}>
						<input
							value={email}
							onChange={(e)=>setEmail(e.target.value)}
							placeholder={'Email (ID)'}
						/>
					</div>
					<div className={'inputBox'}>
						<input
							value={phone}
							onChange={(e)=>setPhone(e.target.value)}
							placeholder={'연락처'}
						/>
					</div>
					<div className={'inputBox'}>
						<input
							value={pass}
							type={'password'}
							onChange={(e)=>setPass(e.target.value)}
							placeholder={'비밀번호'}
						/>
					</div>
					<div className={'inputBox'}>
						<input
							value={rePass}
							type={'password'}
							onChange={(e)=>setRePass(e.target.value)}
							placeholder={'비밀번호 재입력'}
						/>
					</div>
					<div className={'errMessageBox mainRedText'}>
						{message}
					</div>
					<div className={'loginBtn activeBtn'} onClick={regUser}>회원가입</div>
				</div>
				<div className={'loginInfoBox'}>
					<div onClick={()=>navigate('/login') }>로그인</div>
				</div>
				<div className={'snsAuthBox'}>

				</div>
			</div>
		</div>
	);
};

SignUpPage.propTypes = {

};

export default SignUpPage;

import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {BiX} from "react-icons/bi";
import NO_IMG from '../../asset/images/no-img001.jpg'
import {IMG_ACCOUNT_300_URL, IMG_COMPANY_300_URL, IMG_RECEIPT_300_URL} from "../../helper/config";

const imageMimeType = /image\/(png|jpg|jpeg)/i;


const PreviewPhotoUpload = props => {
	const { viewType, inputName, title, oldPhoto, photo, setPhotoFile } = props
	const photoRef = useRef(null)
	const [fileDataURL, setFileDataURL] = useState(null);
	const type = viewType ? viewType : null
	// const BASE_URL = viewType === 'RECEIPT' ? IMG_RECEIPT_300_URL : IMG_COMPANY_300_URL

	const getUrl = (type:string):string => {
		switch (type) {
			case 'RECEIPT' :
				return IMG_RECEIPT_300_URL
			case 'ACCOUNT' :
				return IMG_ACCOUNT_300_URL
			default:
				return IMG_COMPANY_300_URL
		}
	}

	const getClassName = (type:string):string => {
		switch (type) {
			case 'RECEIPT' :
			case 'ACCOUNT' :
				return 'fullPreViewImage'
			default:
				return ''
		}
	}

	const onUploadImage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {return;}

		const file = e.target.files[0];
		if (!file.type.match(imageMimeType)) {
			alert("Image mime type is not valid");
			return;
		}

		setPhotoFile(file)
	}, []);


	const onUploadImageButtonClick = useCallback(() => {
		if (!photoRef.current) {
			return;
		}
		photoRef.current.click();
	}, []);

	const removePhoto = () => {
		setPhotoFile(null)
		setFileDataURL('')
	}

	useEffect(() => {
		let fileReader, isCancel = false;

		if (photo) {
			fileReader = new FileReader();
			fileReader.onload = (e) => {
				const { result } = e.target;
				if (result && !isCancel) {
					setFileDataURL(result)
				}
			}
			fileReader.readAsDataURL(photo);
		}else{
			setFileDataURL('')
		}
		return () => {
			isCancel = true;
			if (fileReader && fileReader.readyState === 1) {
				fileReader.abort();
			}
		}
	}, [photo]);


	return (
		<div className={'previewPhotoUploadWrapper'}>
			<div className={`preViewImage ${getClassName(type)}`}>
				{ fileDataURL ? <img src={fileDataURL} alt=""/> : oldPhoto ? <img src={getUrl(viewType)+`${oldPhoto}`} alt=""/> : <img src={NO_IMG} alt=""/>}
				{ fileDataURL && <div className={'removeBtn'} onClick={removePhoto}><BiX size={20}/></div>}
			</div>
			<input type="file" name={inputName} accept="image/*" style={{ display: "none" }} ref={photoRef} onChange={onUploadImage} onClick={event => event.target.value = null}/>
			<div className={'photoTitle'}>{title}</div>
			<div className={'btnBox'}>
				<div className={'buttonBtn fileFindBtn'} onClick={onUploadImageButtonClick}>select photo</div>
			</div>
		</div>
	);
};

PreviewPhotoUpload.propTypes = {
	inputName: PropTypes.string,
	title: PropTypes.string,
	viewType: PropTypes.string,
	oldPhoto: PropTypes.string,
	photo: PropTypes.object,
	setPhotoFile: PropTypes.func,
};

export default PreviewPhotoUpload;

import * as types from './../helpers/types'


export const setCartItems = (data) => (
	{
		type: types.SET_CART_ITEMS,
		data,
	}
)

export const addCartItem = (data) => (
	{
		type: types.ADD_CART_ITEM,
		data,
	}
)

export const removeCartItem = (data) => (
	{
		type: types.REMOVE_CART_ITEM,
		data,
	}
)

export const minusCartItem = (data) => (
	{
		type: types.MINUS_CART_ITEM,
		data,
	}
)

export const plusCartItem = (data) => (
	{
		type: types.PLUS_CART_ITEM,
		data,
	}
)

import React from 'react';
import PropTypes from 'prop-types';
import * as Utils from "../../../helper/utils";


const OrderItem = props => {

	const {item, goPage} = props

	const cssStatus = (status) => {
		if(status === 'READY' || status === 'ING' || status === 'DONE') {
			return 'mainColorText textBold'
		}

		return ''
	}

	return (
		<div className={'orderItemWrapper'}  onClick={()=>goPage(item.id)}>
			<div className={'itemBox01'}>

			</div>
			<div className={'itemBox02'}>
				<div className={'item01'}>
					<div className={'t01'}></div>
					<div className={'t02'}>배송일 วันส่งสินค้า : {item.deliveryDate}</div>
				</div>
				<div className={'item01'}>
					<div className={'t01'}></div>
					<div className={'t02'}>주문일 วันที่สั่งซื้อ : {item.orderDate}</div>
				</div>
			</div>

			<div className={'badgeBox'}>
				{
					item.orderStatus === 'Y' ?
					<div className={'flexRow'}>
						<div className={`deliveryStatusBox ${cssStatus(item.deliveryStatus)}`}>
							{Utils.getDeliveryStatusName(item.deliveryStatus)}
						</div>
						{
							item.payType === 'CREDIT'?
								<div className={'badgeBase activeBtn'}>신용</div>
								:
								<div className={'badgeBase badgeDefaultColor'}>
									{Utils.getPayStatusName(item.payStatus)}
								</div>
						}
					</div>
					:
					<div className={'cancelStatusBox'}>취소됨</div>
				}
			</div>

			<div className={'priceBox'}>{Utils.numberFormat(item.totalPrice)} THB</div>

			{/*<div className={'btnBox'}>*/}
				{/*<div className={'reOrderBtn'}>ReOrder</div>*/}
				{/*<div className={'showDetailBtn'}>Info</div>*/}
			{/*</div>*/}
		</div>
	);
};

OrderItem.propTypes = {
	item: PropTypes.object,
	goPage: PropTypes.func
};

export default OrderItem;

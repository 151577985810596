import * as Api from "../../helper/api";
import * as types from "../helpers/types";
import {setLoginState} from "./authAction";
import {UserCompany, UserProfile} from "../../helper/api";



export const getUserProfile = async (dispatch) : any => {

	try {
		const resData = await Api.getUserProfile_API()

		if (!resData.data) {return false}

		const {data, status} = resData.data
		const {profile, photos, price} = data

		const logoPhoto = photos && photos.length > 0 ?
			photos.find( item => { return item.type === 'LOGO'}) : null

		if(status) {
			const userProfile: UserProfile = {}
			userProfile.userId = Number(profile.id)
			userProfile.userEmail = profile.email
			userProfile.userType = profile.type
			userProfile.payType = profile.pay_type
			userProfile.grade = profile.grade
			userProfile.isAuthCompany = profile.approve === 'Y'
			userProfile.isReqGrade = profile.req_grade === 'Y'
			userProfile.feeRate = Number(profile.fee_rate)
			userProfile.totalPrice3m = price.month ? Number(price.month) : 0
			userProfile.totalPriceAll = price.all ? Number(price.all) : 0

			const userCompany:UserCompany = {}
			userCompany.companyId = profile.company_id
			userCompany.name = profile.store
			userCompany.companyLogo = logoPhoto && logoPhoto.file_name
			userCompany.address = profile.address

			dispatch(setUserProfile(userProfile))
			dispatch(setUserCompany(userCompany))
		}
	}catch (e) {
		// console.log('getUserProfile error', e)
		dispatch(setLoginState(false))
	}finally {

	}
}

export const setUserProfile = (data) => (
	{
		type: types.SET_USER_PROFILE,
		data,
	}
)

export const setUserCompany = (data) => (
	{
		type: types.SET_USER_COMPANY,
		data,
	}
)



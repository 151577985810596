import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../components/header";
import PreviewPhotoUpload from "../../components/preViewPhotoUpload";
import Loader from "../../components/Loader";
import type {ReqCompany} from "../../helper/api";
import * as Api from "../../helper/api";
import * as Config from "../../helper/config";
import * as Utils from "../../helper/utils";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {getUserProfile} from "../../redux/actions/userAction";
import {useDispatch} from "react-redux";


const AuthCompanyPage = () => {
	let {companyId} = useParams();
	const acPageRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [dimensions, setDimensions] = useState({width: '', height: 830})
	const [isLoaded, setIsLoaded] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [cpId, setCpId] = useState(	null);
	const [store, setStore] = useState('');
	const [ePhone, setEPhone] = useState('');
	const [companyNum, setCompanyNum] = useState('');
	const [deliveryZone, setDeliveryZone] = useState('SIL');  // 서버에서 호출해서 바인딩 할 예정
	const [storeAddress, setStoreAddress] = useState(null);
	const [employWorkTime, setEmployWorkTime] = useState('10:00');
	const [file01, setFile01] = useState(null);
	const [file02, setFile02] = useState(null);
	const [file03, setFile03] = useState(null);
	const [file04, setFile04] = useState(null);
	const [oldPhotos, setOldPhotos] = useState({photo01: '', photo02: '', photo03: '', photo04: ''});

	const reqCompany = async () => {
		setIsLoaded(false)

		const getParams = () : ReqCompany => {
			const tempParams:ReqCompany = {}
			tempParams.companyId = cpId ? cpId : ''
			tempParams.companyNum = companyNum
			tempParams.deliveryZone = deliveryZone
			tempParams.storeName = store
			tempParams.storeAddress = storeAddress.label
			tempParams.employWorkTime = employWorkTime
			tempParams.ePhone = ePhone
			tempParams.file01 = file01
			tempParams.file02 = file02
			tempParams.file03 = file03
			tempParams.file04 = file04

			return tempParams
		}

		try {
			const resData = await Api.setCompany_API(getParams())

			const {status} = resData.data
			if(status) {
				/**
				 * 컴퍼니 정보만 업데이트해주면 되는데..
				 * 일단 사용자 정보를 갱신하는 로직으로 활용하여 사용
				 * */
				const token = await Utils.getAccessToken()
				token && await getUserProfile(dispatch)
				setIsLoaded(true)
				navigate('/company')
			}else {
				alert('사업장인증 등록에 실패했습니다.')
			}
		}catch (e) {

		}finally {
			setIsLoaded(true)
		}
	}

	const handleCancelBtn = (companyId) => {
		if(companyId) { // 수정중이라면
			navigate('/company')
			return
		}

		navigate('/home')
	}

	const getPhotoNameForType = (photos = [], type = 'LOG') : string | null => {
		const resultPhoto = photos && photos.length > 0 ? photos.find( item => { return item.type === type }) : null
		return resultPhoto && resultPhoto.file_name
	}

	const getCompanyData = async (companyId) : void => {
		setIsLoaded(false)

		try {
			const resData = await Api.getCompany_API(companyId)

			if (!resData.data) return

			const {data, status} = resData.data
			const {company, photos} = data
			const getData: ReqCompany = {}
			getData.storeName = company.store
			getData.companyNum = company.company_num
			getData.ePhone = company.hot_phone
			getData.storeAddress = company.address
			getData.deliveryZone = company.delivery_zone
			getData.employWorkTime = company.employ_time
			getData.oldFile01 = getPhotoNameForType(photos, 'OUT')
			getData.oldFile02 = getPhotoNameForType(photos, 'IN')
			getData.oldFile03 = getPhotoNameForType(photos, 'ID')
			getData.oldFile04 = getPhotoNameForType(photos, 'LOGO')

			if(status) {
				setStore(getData.storeName)
				setEPhone(getData.ePhone)
				setCompanyNum(getData.companyNum)
				setDeliveryZone(getData.deliveryZone)
				setStoreAddress({label: getData.storeAddress})
				setEmployWorkTime(getData.employWorkTime)
				setOldPhotos({
					photo01: getData.oldFile01,
					photo02: getData.oldFile02,
					photo03: getData.oldFile03,
					photo04: getData.oldFile04,
				})
			}

		}catch (e) {
			// console.log('e >>>>>', e)
		}finally {
			setTimeout(()=> {
				setIsLoaded(true)
			}, 300)
		}
	}

	const updateDimensions = () => {
		if (acPageRef.current) {

			setDimensions({
				width: acPageRef.current.offsetWidth,
				height: acPageRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		updateDimensions()
	}, [isLoaded]);


	useEffect(() => {

		if(companyId) {
			setIsEdit(true)
			setCpId(companyId)
			getCompanyData(companyId)
		}else {
			setIsLoaded(true)
		}

		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	// useEffect(()=> {
	// 	console.log('file01 >>>', file01)
	// }, [file01])


	return (
		<div className={'authCompanyPageWrapper'} ref={acPageRef}>
			<Header/>
			<div className={'topTitleBox'}>
				<div className={'topTitle acUnderBox '}>사업자인증<span className={'topSubTitle'}>COMPANY</span></div>
				<div className={'fontSize20 textBold'}> </div>
			</div>
			<div className={'scrollWrapper'} style={{height: `${dimensions.height - 240}px`}}>
				<div className={'inputSec'}>

					<div className={'inputBox'}>
						<div className={'inputTitle'}>상점명</div>
						<div>
							<input
								value={store}
								onChange={(e)=>setStore(e.target.value)}
								placeholder={'상점명을 영문(한글)로 입력해주세요'}
							/>
						</div>
					</div>

					<div className={'inputBox'}>
						<div className={'inputTitle'}>사업자번호</div>
						<div>
							<input
								value={companyNum}
								onChange={(e)=>setCompanyNum(e.target.value)}
								placeholder={'사업자번호를 입력해주세요'}
							/>
						</div>
					</div>

					<div className={'inputBox'}>
						<div className={'inputTitle'}>긴급연락처</div>
						<div>
							<input
								value={ePhone}
								onChange={(e)=>setEPhone(e.target.value)}
								placeholder={'긴급연락처를 입력해주세요'}
							/>
						</div>
					</div>

					<div className={'inputBox'}>
						<div className={'inputTitle'}>담당자 출근시간</div>
						<div>
							<input
								value={employWorkTime}
								onChange={(e)=>setEmployWorkTime(e.target.value)}
								placeholder={'ex) 18:00'}
							/>
						</div>
					</div>

					<div className={'inputBox googlePlaceBox'}>
						<div className={'inputTitle'}>배송지주소</div>
						<GooglePlacesAutocomplete
							apiKey={Config.GOOGLE_API_KEY}
							selectProps={{
								isClearable: true,
								placeholder: '주소를 입력해주세요.',
								value: storeAddress,
								onChange: setStoreAddress,
								styles: {
									input: (provided) => ({
										...provided,
									}),
									control: (provided) => ({
										...provided,
										boxSizing: 'border-box',
										height: 50,
										background: '#f5f5f5',
										border: '1px solid #f5f5f5',
										borderRadius: 10,
										paddingLeft: 10,
									}),
								}
							}}
						/>
						{
							storeAddress &&
							<div className={'addressInfo'}>{storeAddress.label}</div>
						}
					</div>
				</div>
				<div className={'photoSec'}>
					<div className={'imageTitle'}>이미지</div>
					<div className={'imageBox'}>
						<PreviewPhotoUpload
							inputName={'file01'}
							title={'외부전경(간판포함)'}
							oldPhoto={oldPhotos.photo01}
							photo={file01}
							setPhotoFile={(file)=>setFile01(file)}
						/>
						<PreviewPhotoUpload
							inputName={'file02'}
							title={'영업장내부'}
							oldPhoto={oldPhotos.photo02}
							photo={file02}
							setPhotoFile={(file)=>setFile02(file)}
						/>
					</div>
					<div className={'imageBox'}>
						<PreviewPhotoUpload
							inputName={'file03'}
							title={'사업자등록'}
							oldPhoto={oldPhotos.photo03}
							photo={file03}
							setPhotoFile={(file)=>setFile03(file)}
						/>
						<PreviewPhotoUpload
							inputName={'file03'}
							title={'사업장 로고'}
							oldPhoto={oldPhotos.photo04}
							photo={file04}
							setPhotoFile={(file)=>setFile04(file)}
						/>
					</div>
				</div>
			</div>
			<div className={'bottomBox'}>
				<div className={'cancelCompanyBtn'} onClick={()=>handleCancelBtn(companyId)}>취소</div>
				<div className={'regCompanyBtn'} onClick={reqCompany}>인증요청</div>
			</div>
			<Loader loading={!isLoaded}/>
		</div>
	);
};

AuthCompanyPage.propTypes = {

};

export default AuthCompanyPage;

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {HiArrowLeft} from "react-icons/hi";
import ConfirmCompanyModal from "../../components/modal/confirmCompanyModal";
import ConfirmGradeModal from "../../components/modal/confirmGradeModal";
import NO_IMG from "../../asset/images/no-img001.jpg";
import * as Api from "../../helper/api";
import {MemberDtVo} from "../../helper/vo";
import {IMG_COMPANY_300_URL} from "../../helper/config";
import {numberFormat} from "../../helper/utils";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Loader from "../../components/Loader";
import DeliveryZoneSelectModal from "../../components/modal/deliveryZoneSelectModal";
import PayTypeModal from "../../components/modal/payTypeModal";




const MemberManageDetailPage = (props) => {
	const {userId, isOpen, setIsOpen, pageCallback} = props
	// const {userId} = useParams();
	// const navigate = useNavigate();
	const memberManageDtRef = useRef(null)
	const [isLoaded, setIsLoaded] = useState(false);
	const [dimensions, setDimensions] = useState({width: '', height: 830})
	const [isAuthComOpen, setIsAuthComOpen] = useState(false);
	const [isGradeOpen, setIsGradeOpen] = useState(false);
	const [isDzOpen, setIsDzOpen] = useState(false);
	const [isPtOpen, setIsPtOpen] = useState(false);
	const [data, setData] = useState({});


	const successCompany = () => {
		setAuthCompany(userId, 'Y')
	}

	const successGrade = (gradeObj: any) => {
		setGrade(userId, gradeObj.value, gradeObj.fee)
	}

	const successDeliveryZone = (zoneObj: any) => {
		setDeliveryZone(userId, zoneObj.dzId)
	}

	const successPayType = (value:string) => {
		setPayType(userId, value)
	}

	const defaultData = () => {
		return {
			userId: null,
			companyId: null,
			totalPrice: 0,
			email: '',
			grade: '',
			companyNum: '',
			storeName: '',
			deliveryZone: '',
			dzId: 0,
			address: '',
			hotPhone: '',
			phone: '',
			payType: '',
			isAuthCompany: false, // 사업자 인증 유무
			isReqGrade: false, // 등급조정 요청
			employTime: '',
			photo01: null,
			photo02: null,
			photo03: null,
			photo04: null,
		}
	}

	// 결제방법 설정
	const setPayType = async (userId: number, value: string) : void => {
		try{
			const resData = await Api.setPayType_API(userId, value)
			console.log('setPayType >>>>>', resData)
			if (!resData.data) return

			getData(userId)

		}catch (e) {
			// console.log('e', e)
		}
	}

	// 사업자 인증
	const setAuthCompany = async (userId: number, value: string): void => {
		try {
			const resData = await Api.setAuthCompany_API(userId, value)
			if (!resData.data) return

			getData(userId)

		} catch (e) {
			// console.log('e', e)
		}
	}

	// 등급 조정
	const setGrade = async (userId: number, grade: string, feeRate: number): void => {
		try {
			const resData = await Api.setGrade_API(userId, grade, feeRate)

			if (!resData.data) return

			getData(userId)

		} catch (e) {
			// console.log('e', e)
		}
	}

	// 배송지역설정
	const setDeliveryZone = async (userId: number, dzId: number): void => {
		try {
			const resData = await Api.setDeliveryZone_API(userId, dzId)

			if (!resData.data) return

			getData(userId)

		}catch (e) {
			// console.log('e', e)
		}
	}

	const pageGoBack = () => {
		// 페이지 나가기 전에 데이터 초기화
		setData(defaultData)
		setIsOpen(false)
		pageCallback(false)
	}


	const ImgView = ({fileName = '', title = ''}) => {
		return (
			<div className={'imgWrapper'}>
				<div className={'imageView'}>
					{
						fileName ? <img src={IMG_COMPANY_300_URL + `${fileName}`} alt=""/> :
							<img className={'noImg'} src={NO_IMG} alt=""/>
					}
				</div>
				<div className={'imgTitle'}>{title}</div>
			</div>
		)
	}

	const getPhotoNameForType = (photos = [], type = 'LOG'): string | null => {
		const resultPhoto = photos && photos.length > 0 ? photos.find(item => {
			return item.type === type
		}) : null
		return resultPhoto && resultPhoto.file_name
	}

	const getData = async (userId: number): void => {
		try {
			const resData = await Api.getUsersData_API(userId)
			// console.log('getData >>', resData)
			if (!resData.data) return
			const {data, status} = resData.data
			const {user, photos, totalPrice} = data

			if (status) {
				const resultData: MemberDtVo = {}
				resultData.userId = Number(user.user_id)
				resultData.companyId = user.company_id ? Number(user.company_id) : null
				resultData.email = user.email
				resultData.grade = user.grade
				resultData.companyNum = user.company_num
				resultData.storeName = user.store
				resultData.dzId = user.dz_id
				resultData.deliveryZone = user.zone_code
				resultData.zoneNameKo = user.zone_name_ko
				resultData.zoneNameEn = user.zone_name_en
				resultData.zoneNameTh = user.zone_name_th
				resultData.address = user.address
				resultData.phone = user.phone
				resultData.hotPhone = user.hot_phone
				resultData.payType = user.pay_type
				resultData.isAuthCompany = user.approve === 'Y'
				resultData.isReqGrade = user.req_grade === 'Y'
				resultData.employTime = user.employ_time
				resultData.photo01 = getPhotoNameForType(photos, 'OUT')
				resultData.photo02 = getPhotoNameForType(photos, 'IN')
				resultData.photo03 = getPhotoNameForType(photos, 'ID')
				resultData.photo04 = getPhotoNameForType(photos, 'LOGO')
				resultData.totalPrice = Number(totalPrice)

				// console.log('setData >>>>', resultData)

				setData(resultData)
			}
		} catch (e) {

		} finally {
			// setTimeout(() => {
				setIsLoaded(true)
			// }, 300)
			updateDimensions()
		}
	}

	const updateDimensions = () => {
		if (memberManageDtRef.current) {

			setDimensions({
				width: memberManageDtRef.current.offsetWidth,
				height: memberManageDtRef.current.offsetHeight,
			});
		}
	};

	const initPage = () => {
		setIsLoaded(false)

		setTimeout(() => {
			getData(Number(userId))
		}, 200)
	}

	useEffect(() => {
		updateDimensions()
	}, [isLoaded]);

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<SlidingPane
			className="some-custom-class"
			overlayClassName="some-custom-overlay-class"
			isOpen={isOpen}
			width={'100%'}
			hideHeader={true}
			onRequestClose={() => {
				// triggered on "<" on left top click or on outside click
				// setIsOpen(false);
				pageGoBack()
			}}
			onAfterOpen={initPage}
		>
			<div className={'memberManageDtWrapper'} ref={memberManageDtRef}>
				<div className={'herderBtnBox'}>
					<div className={'goBackBtn'} onClick={pageGoBack}><HiArrowLeft size={25}/></div>
					<div className={'titleText titleFont'}>회원정보</div>
				</div>
				{/*<div className={'topTitleBox'}>*/}
				{/*	<div className={'topTitle deliveryDtUnderBox'}>회원정보<span className={'topSubTitle'}>MEMBER INFO</span></div>*/}
				{/*	<div></div>*/}
				{/*</div>*/}
				<div className={'scrollWrapper'} style={{height: `${dimensions.height - 50}px`}}>

					{
						(!data.isAuthCompany && data.companyId) &&
						<div className={'todoBox'}>
							<div className={'todoItem todoBg01'}>
								<div className={'lTodoItem'}>사업자인증 요청</div>
								<div className={'rTodoItem'}>
									<div className={'buttonBtn activeBtn'} onClick={() => setIsAuthComOpen(true)}>승인</div>
								</div>
							</div>
						</div>
					}
					{
						(data.isAuthCompany && data.isReqGrade) &&
						<div className={'todoBox'}>
							<div className={'todoItem todoBg02'}>
								<div className={'lTodoItem'}>등급조정 요청</div>
								<div className={'rTodoItem'}>
									<div className={'buttonBtn activeBtn'} onClick={() => setIsGradeOpen(true)}>승인</div>
								</div>
							</div>
						</div>
					}

					<div className={'summeryBox'}>
						<div className={'summeryItem'}>
							<div className={'itemText01'}>총주문금액</div>
							<div className={'itemText02'}>{data.totalPrice > 0 ? numberFormat(data.totalPrice) : 0} THB</div>
						</div>
						<div className={'summeryItem'}>
							<div className={'itemText01'}>현재등급</div>
							<div className={'itemText02'}>{data.grade}</div>
						</div>
						<div className={'summeryItem'} onClick={()=>setIsPtOpen(true)}>
							<div className={'itemText01'}>결제방법</div>
							<div className={'itemText02'}>{data.payType}</div>
						</div>
					</div>


					<div className={'contBox letter01'}>
						<div className={'sectionBox'}>
							<div className={'lSection'}>배송지역</div>
							<div className={'rSection'} onClick={()=>setIsDzOpen(true)}>
								<div className={`dzBtn ${data.deliveryZone ? 'dzActiveBtn' : 'dzDefaultBtn'}`}>
									{data.deliveryZone ? data.deliveryZone : '미설정'}
								</div>
							</div>
						</div>
					</div>


					<div className={'contBox letter01'}>
						<div className={'sectionBox'}>
							<div className={'lSection'}>회원 고유번호</div>
							<div className={'rSection'}>{data.userId}</div>
						</div>
						<div className={'sectionBox'}>
							<div className={'lSection'}>이메일</div>
							<div className={'rSection letterInit'}>{data.email}</div>
						</div>
						<div className={'sectionBox'}>
							<div className={'lSection'}>연락처</div>
							<div className={'rSection letterInit'}>{data.phone}</div>
						</div>
					</div>

					<div className={'contBox letter01'}>
						<div className={'sectionBox'}>
							<div className={'lSection'}>사업자회원 인증</div>
							<div className={'rSection'}>{data.isAuthCompany ? '인증' : '미인증'}</div>
						</div>
						<div className={'sectionBox'}>
							<div className={'lSection'}>사업자번호</div>
							<div className={'rSection letterInit'}>{data.companyNum ? data.companyNum : '-'}</div>
						</div>
						<div className={'sectionBox'}>
							<div className={'lSection'}>상점명</div>
							<div className={'rSection'}>{data.storeName ? data.storeName : '-'}</div>
						</div>
						<div className={'sectionBox'}>
							<div className={'lSection'}>긴급연락처</div>
							<div className={'rSection'}>{data.hotPhone ? data.hotPhone : '-'}</div>
						</div>
						<div className={'sectionBox'}>
							<div className={'lSection'}>직원출근시간</div>
							<div className={'rSection'}>{data.employTime ? data.employTime : '-'}</div>
						</div>
						<div className={'sectionBox'}>
							<div className={'lSection'}>주소</div>
							<div className={'rSection'}>{data.address ? data.address : '-'}</div>
						</div>
					</div>

					<div className={'companyImageBox'}>
						<div className={'imageBox'}>
							<ImgView title={'외부전경(간판포함)'} fileName={data.photo01}/>
							<ImgView title={'영업장내부'} fileName={data.photo02}/>
						</div>
						<div className={'imageBox'}>
							<ImgView title={'사업자등록'} fileName={data.photo03}/>
							<ImgView title={'사업장 로고'} fileName={data.photo04}/>
						</div>
					</div>

					{/*<div className={'companyImageBox'}>*/}
					{/*	<ul>*/}
					{/*		<li>*/}
					{/*			<ImgView title={'외부전경(간판포함)'} fileName={data.photo01}/>*/}
					{/*		</li>*/}
					{/*		<li>*/}
					{/*			<ImgView title={'영업장내부'} fileName={data.photo02}/>*/}
					{/*		</li>*/}
					{/*		<li>*/}
					{/*			<ImgView title={'사업자등록'} fileName={data.photo03}/>*/}
					{/*		</li>*/}
					{/*		<li>*/}
					{/*			<ImgView title={'사업장 로고'} fileName={data.photo04}/>*/}
					{/*		</li>*/}
					{/*	</ul>*/}
					{/*</div>*/}

				</div>
				<ConfirmCompanyModal
					isOpen={isAuthComOpen}
					setIsOpen={setIsAuthComOpen}
					success={successCompany}
					title={'사업자승인'}
					message={'사업자로 승인하시겠습니까?'}
				/>
				<ConfirmGradeModal
					isOpen={isGradeOpen}
					setIsOpen={setIsGradeOpen}
					success={successGrade}
					price={data.totalPrice ? data.totalPrice : 0}
				/>
				<DeliveryZoneSelectModal
					isOpen={isDzOpen}
					setIsOpen={setIsDzOpen}
					oldZone={{dzId: Number(data.dzId), value:data.dzId, label: `${data.deliveryZone} ${data.zoneNameKo}`}}
					success={successDeliveryZone}
				/>
				<PayTypeModal
					isOpen={isPtOpen}
					setIsOpen={setIsPtOpen}
					oldPayType={{value: `${data.payType}`, label: `${data.payType}`}}
					success={successPayType}
				/>
			</div>
			<Loader loading={!isLoaded}/>
		</SlidingPane>
	);
};

MemberManageDetailPage.propTypes = {
	userId: PropTypes.number.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	pageCallback: PropTypes.func,
};

export default MemberManageDetailPage;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import NumberFormat from "react-number-format";
import * as Utils from "../../../helper/utils";

Modal.setAppElement('#root');
const RegBuyingPriceModal = props => {
	const {success, isOpen, setIsOpen, oldData} = props
	const [buyPrice, setBuyPrice] = useState({value:'', formatValue: ''});


	const confirmSuccess = () => {
		if(buyPrice.value) {
			success(Number(buyPrice.value))
			closeModal()
		}else{
			alert('구입금액을 입력해주세요.')
		}
	}

	const closeModal = () => {
		setIsOpen(false)
	}

	const initView = () => {
		if(oldData) {
			setBuyPrice({value: oldData, formatValue: Utils.numberFormat(oldData)})
		}else{
			setBuyPrice({value: '', formatValue: ''})
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={initView}
			// onRequestClose={()=>setIsOpen(false)}
			contentLabel="Example Modal"
			className="orderModalWrapper"
			overlayClassName={'modalOverlay'}
			shouldCloseOnOverlayClick={false}
		>
			<div className={'confirmModalWrapper regBuyingPriceWrapper'}>
				<div className={'confirmInBox'}>

					<div className={'text01'}>รายได้ (구입금액)</div>
					<div className={'inputBox'}>
						<NumberFormat
							className={'regPriceTextInput'}
							thousandSeparator={true}
							// prefix={'THB'}
							value={buyPrice.formatValue}
							onValueChange={(values) => {
								const { formattedValue, value } = values;
								// formattedValue = $2,223
								// value ie, 2223
								setBuyPrice({ value: value,  formatValue: formattedValue});
							}}
						/>
					</div>

				</div>
				<div className={'flexRow confirmBtnBox'}>
					<div className={'buttonBtn cancelBtn'} onClick={closeModal}>취소</div>
					<div className={'buttonBtn confirmBtn'} onClick={confirmSuccess}>ตรวจสอบ (확인)</div>
				</div>
			</div>
		</Modal>
	);
};

RegBuyingPriceModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	success: PropTypes.func.isRequired,
	oldData: PropTypes.any,
};

export default RegBuyingPriceModal;

import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import type {LoginUser} from "../../helper/api";
import {useDispatch} from "react-redux";
import {login} from "../../redux/actions/authAction";
import Header from "../../components/header";

const LoginPage = () => {
	const loginPageRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [dimensions, setDimensions] = useState({width: '', height: 900})
	const [email, setEmail] = useState('');
	const [pass, setPass] = useState('');
	const [message, setMessage] = useState('');


	const validate = () => {

		if(!email) {
			setMessage('이메일을 입력해주세요.')
			return false
		}

		if(!pass) {
			setMessage('비밀번호를 입력해주세요.')
			return false
		}

		return true
	}


	const doLogin = async () => {

		if(!validate()) return

		const reqData: LoginUser = {}
		reqData.email = email
		reqData.pass = pass

		const resData = await login(dispatch, reqData)

		if (!resData.data) {
			setMessage('로그인에 실패하였습니다.')
			return
		}

		const { status, message } = resData.data

		if (status) {
			if(resData) navigate('/home')
		}else {
			setMessage(message)
		}
	}


	const testLogin = async (email, pass) => {

		const testParams: LoginUser = {}
		testParams.email = email
		testParams.pass = pass

		const resData = await login(dispatch, testParams)

		if (!resData.data) {
			setMessage('로그인에 실패하였습니다.')
			return
		}

		const { status, message } = resData.data

		if (status) {
			if(resData) navigate('/home')
		}else {
			setMessage(message)
		}
	}


	const updateDimensions = () => {
		if (loginPageRef.current) {

			setDimensions({
				width: loginPageRef.current.offsetWidth,
				height: loginPageRef.current.offsetHeight,
			});
		}
	};


	useEffect(() => {
		if(email) { setMessage('')}
		if(pass) { setMessage('')}
	}, [email, pass]);


	useEffect(() => {
		updateDimensions()

		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);


	return (
		<div className={'loginWrapper'} ref={loginPageRef}>
			<Header/>
			<div className={'authBox'}>
				<div className={'loginTit'}>Login</div>
				<div className={'idAuthBox'}>
					<div className={'inputBox'}>
						<input
							value={email}
							onChange={(e)=>setEmail(e.target.value)}
							placeholder={'Email'}
						/>
					</div>
					<div className={'inputBox'}>
						<input
							value={pass}
							type={'password'}
							onChange={(e)=>setPass(e.target.value)}
							placeholder={'Password'}
						/>
					</div>
					<div className={'findPassText'} onClick={()=>navigate('/findPass')}>비밀번호 찾기</div>
					<div className={'errMessageBox mainRedText'}>
						{message}
					</div>
					<div className={'loginBtn activeBtn'} onClick={doLogin}>로그인 เข้าสู่ระบบ</div>
				</div>
				<div className={'signUpInfoBox'}>
					<div onClick={()=>navigate('/signup')}>회원가입 สมัครสมาชิก</div>
				</div>
				<div className={'snsAuthBox'}>

				</div>
			</div>
		</div>
	);
};

LoginPage.propTypes = {

};

export default LoginPage;

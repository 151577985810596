import React, {useEffect, useRef, useState} from 'react';
import Header from "../components/header";
import OrderList from "../components/orderList";
import {getOrderData} from "../redux/actions/orderAction";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const OrderPage = () => {
  const orderPageRef = useRef(null);
  const dispatch = useDispatch();
  const {isLogin} = useSelector(state => state.userState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dimensions, setDimensions] = useState({width: '', height: 450})

  const updateDimensions = () => {
    /**
     * 데이터가 가변적일땐 데이터가 없을 때를 기준으로
     * 화면이 그려지기 때문에 데이터가 변경될때 마다
     * 재호출 해서 화면사이즈를 갱신한다.
     * */
    if (orderPageRef.current) {
      setDimensions({
        width: orderPageRef.current.offsetWidth,
        height: orderPageRef.current.offsetHeight,
      });
    }
  };

  const initOrderData = async () : void => {
    setIsLoaded(false)
    isLogin && await getOrderData(dispatch)
    setTimeout(()=>{
      setIsLoaded(true)
    }, 300)
  }

  useEffect(() => {

    initOrderData()

    window.addEventListener("resize", updateDimensions);
    window.addEventListener('load', updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener('load', updateDimensions);
    };
  }, []);

  return (
    <div className={'orderWrapper'} ref={orderPageRef}>
      <Header />
      <div className={'topTitleBox'}>
        <div className={'topTitle orderUnderBox'}>주문내역<span className={'topSubTitle'}>ORDER</span></div>
        <div> </div>
      </div>
      <div className={'accountBox'}>
        <div className={'accountTit'}>주문후 11시까지 입금확인후 상품을 준비합니다.</div>
        <div className={'accountItem'}>
          <div>
            <p>Kasikorn Bank | Heesoo yoon</p>
            <p>0142828561</p>
          </div>
          <CopyToClipboard text={'0142828561'}><div className={'copyBtn'}>복사</div></CopyToClipboard>
        </div>
        <div className={'accountItem'}>
          <div>
            <p>Kasikorn Bank | Seunghoon Song</p>
            <p>0592756139</p>
          </div>
          <CopyToClipboard text={'0592756139'}><div className={'copyBtn'}>복사</div></CopyToClipboard>
        </div>
      </div>
      <OrderList
        updateDimensions={updateDimensions}
        height={dimensions.height - 300}
      />
      <Loader loading={!isLoaded}/>
    </div>
  );
};

OrderPage.propTypes = {

};

export default OrderPage;

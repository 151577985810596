import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import * as Temp from "../../../helper/tempData";
import Select from "react-select";


Modal.setAppElement('#root');
const PayTypeModal = props => {
	const {success, isOpen, setIsOpen, oldPayType} = props
	const [payTypeList, setPayTypeList] = useState([]);
	const [selectedPayType, setSelectedPayType] = useState(null);

	const closeModal = () => {
		setIsOpen(false)
	}


	const confirmSuccess = () => {
		if(selectedPayType) {
			success(selectedPayType.value)
			closeModal()
		}else{
			alert('결제방법을 선택해주세요.')
		}
	}

	const initView = () => {
		if(oldPayType){
			setSelectedPayType(oldPayType)
		}
		setPayTypeList(Temp.payTypeData)
	}

	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={initView}
			// onRequestClose={()=>setIsOpen(false)}
			contentLabel="Example Modal"
			className="orderModalWrapper"
			overlayClassName={'modalOverlay'}
			shouldCloseOnOverlayClick={false}
		>
			<div className={'confirmModalWrapper payTypeModalWrapper'}>
				<div className={'confirmInBox'}>
					<div className={'text01'}>결제방법선택</div>
					<div className={'selectView'}>
						<Select
							className={'selectBox'}
							value={selectedPayType}
							onChange={setSelectedPayType}
							options={payTypeList}
							placeholder={'결제방법을 선택해주세요.'}
							isSearchable={false}
						/>
					</div>
				</div>
				<div className={'flexRow confirmBtnBox'}>
					<div className={'buttonBtn cancelBtn'} onClick={closeModal}>취소</div>
					<div className={'buttonBtn confirmBtn'} onClick={confirmSuccess}>확인</div>
				</div>
			</div>
		</Modal>
	);
};

PayTypeModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	success: PropTypes.func.isRequired,
	oldPayType: PropTypes.object,
};

export default PayTypeModal;

import createReducer from "../helpers/createReducers";
import * as initialType from '../helpers/initialTypes';
import * as types from './../helpers/types'


export const commState = createReducer( initialType.initialCommState, {
  [types.SET_TAB_NAME](state, action) {
    return ({
      ...state,
      tabName: action.data
    });
  },
  [types.IS_SIDE_MENU](state, action) {
    return ({
      ...state,
      isOpenSide: action.data
    });
  },
  [types.SET_ORDER_MODAL](state, action) {
    return ({
      ...state,
      isOrderModal: action.data
    });
  },
  [types.IS_NATIVE_WEBVIEW](state, action) {
    return ({
      ...state,
      isNativeWebView: action.data
    });
  },
  [types.SET_ALERT_MESSAGE](state, action) {

    const setMessage = !action.data.isShow ? '' : action.data.message;
    return ({
      ...state,
      alertMessage: {
        ...state.alertMessage,
        isShow: action.data.isShow,
        message: setMessage,
      }
    });
  },
  [types.SET_BOARD_NOTICE](state, action) {
    return ({
      ...state,
      board: {
        ...state.board,
        noticeList: action.data
      }
    });
  },
})

import * as Api from "../../helper/api";
import type {JoinUser, LoginUser} from "../../helper/api";
import * as types from "../helpers/types";
import * as Utils from "../../helper/utils";
import {TokenSet} from "../../helper/utils";
import {ResponseData} from "../../helper/api";
import {getUserProfile} from "./userAction";
import {setAlertMessage} from "./commAction";
import {getOrderList} from "./orderAction";



export const login = async (dispatch, data:LoginUser) : any => {
	const params = data;
	// console.log('params >>>', params)
	try {
		const resData = await Api.login_API(params)

		if (!resData.data) {return false}

		const { data, status } = resData.data

		if (status) {
			const { access_token, refresh_token } = data.token
			const tokenSet: TokenSet = {}
			tokenSet.access_token = access_token;
			tokenSet.refresh_token = refresh_token;

			if (access_token && refresh_token) {
				// 사용자 토큰세팅
				Utils.setToken(tokenSet)
				// 사용자 로그인세팅
				getUserProfile(dispatch)
				dispatch(setAlertMessage({isShow: true, message: '로그인 성공'}))
				// dispatch(setLoginState(true))
				return resData
			} else {
				// alert('정상적으로 로그인하지 못하였습니다. 다시 시도해주세요.')
				dispatch(setLoginState(false))
				return resData
			}
		}

		return resData
	}catch (e) {
		dispatch(setLoginState(false))
		console.warn(e)
		const resData:ResponseData = {}
		resData.status = false
		resData.message = '데이터 오류가 있습니다. 관리자에게 문의해주세요'
		return resData
	}
}


export const signup = async (dispatch, data:JoinUser) : any => {
	const params = data;
	// console.log('params >>>', params)
	try {
		const resData = await Api.joinUser_API(params)

		if (!resData.data) {return false}

		const { data, status } = resData.data

		if (status) {
			const { access_token, refresh_token } = data.token
			const tokenSet: TokenSet = {}
			tokenSet.access_token = access_token;
			tokenSet.refresh_token = refresh_token;

			if (access_token && refresh_token) {
				// 사용자 토큰세팅
				Utils.setToken(tokenSet)
				// 사용자 로그인세팅
				/**
				 * 사용자 정보 세팅 해야함
				 * */
				getUserProfile(dispatch)
				// dispatch(setLoginState(true))
				return resData
			} else {
				alert('정상적으로 로그인하지 못하였습니다. 다시 시도해주세요.')
				dispatch(setLoginState(false))

				const resCustomData:ResponseData = {}
				resCustomData.status = false
				resCustomData.message = '토큰발행실패'

				return resCustomData
			}
		}else {
			return resData
		}
	}catch (e) {
		dispatch(setLoginState(false))
		// console.warn(e)
		const resCustomData:ResponseData = {}
		resCustomData.status = false
		resCustomData.message = '서버호출실패'

		return resCustomData
	}
}


export const logout = (dispatch): void => {
	Utils.clearToken()
	dispatch(setInitUser())
	dispatch(getOrderList([]))
}

export const setLoginState = (data) => (
	{
		type: types.SET_USER_LOGIN,
		data,
	}
)

const setInitUser = () => ({ type: types.SET_USER_INIT})

export const reqUserGrade = (data) => ({ type: types.SET_USER_REQ_GRADE, data })

import React from 'react';
import PropTypes from 'prop-types';
import SlidingPane from "react-sliding-pane";
import {HiArrowLeft} from "react-icons/hi";
import {IMG_ACCOUNT_BASE_URL} from "../../helper/config";
import NO_IMG from "../../asset/images/no-img001.jpg";

const AccountCheckPage = props => {
	const {id, isOpen, setIsOpen, filename, setPayStatus, pageCallback} = props

	const pageGoBack = () => {
		setIsOpen(false)
	}

	const initPage = () => {

	}

	return (
		<SlidingPane
			className="some-custom-class"
			overlayClassName="some-custom-overlay-class"
			isOpen={isOpen}
			width={'100%'}
			hideHeader={true}
			onRequestClose={() => {
				// triggered on "<" on left top click or on outside click
				// setIsOpen(false);
				pageGoBack()
			}}
			onAfterOpen={initPage}
		>
			<div className={'accountCheckWrapper'}>
				<div className={'herderBtnBox'}>
					<div className={'goBackBtn'} onClick={pageGoBack}><HiArrowLeft size={25}/></div>
					<div className={'titleText titleFont'}>입금체크</div>
				</div>
				<div className={'accountImgBox'}>
					{
						filename
						? <img src={IMG_ACCOUNT_BASE_URL + `${filename}`} alt=""/>
						: <img className={'noImg'} src={NO_IMG} alt=""/>
					}
				</div>
				<div className={'fullBottomBox'}>
					<div className={'buttonBtn cancelAccountBtn defaultBtn'} onClick={pageGoBack}>취소</div>
					<div className={`buttonBtn AccountBtn ${filename ? 'activeBtn' : 'defaultBtn'}`} onClick={filename ? setPayStatus : null}><span>입금확인</span></div>
				</div>
			</div>
		</SlidingPane>
	);
};

AccountCheckPage.propTypes = {
	id: PropTypes.number.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	filename: PropTypes.string,
	setPayStatus: PropTypes.func.isRequired,
	pageCallback: PropTypes.func,
};

export default AccountCheckPage;

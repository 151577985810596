import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import SlidingPane from "react-sliding-pane";
import {HiArrowLeft} from "react-icons/hi";

const NoticeDtPage = props => {
  const {notice, isOpen, setIsOpen} = props
  const noticeDtRef = useRef(null)
  // const [isLoaded, setIsLoaded] = useState(false);

  const pageGoBack = () => {
    // 데이터 초기화
    setIsOpen(false)
  }

  const initPage = () => {
    // setIsLoaded(false)
    //
    // setTimeout(() => {
    //   getData(Number(userId))
    // }, 200)
  }

  return (
    <SlidingPane
      className="some-custom-class"
      overlayClassName="some-custom-overlay-class"
      isOpen={isOpen}
      width={'100%'}
      hideHeader={true}
      onRequestClose={() => pageGoBack()}
      onAfterOpen={initPage}
    >
      <div className={'noticeDtWrapper'} ref={noticeDtRef}>
        <div className={'herderBtnBox'}>
          <div className={'goBackBtn'} onClick={pageGoBack}><HiArrowLeft size={25}/></div>
          <div className={'titleText titleFont'}>공지사항</div>
        </div>
        <div>
          <div className={'titleBox'}>{notice && notice.title}</div>
          <div className={'contentsBox'} dangerouslySetInnerHTML={{__html: notice && notice.contents}}></div>
        </div>
      </div>
    </SlidingPane>
  );
};

NoticeDtPage.propTypes = {
  notice: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default NoticeDtPage;

import React from 'react';
import PropTypes from 'prop-types';
import {FaCartPlus} from "react-icons/fa";
import colors from "../../../styles/colors";
import {useDispatch} from "react-redux";
import {addCartItem} from "../../../redux/actions/cartAction";
import {IMG_NO_PRODUCT_100, IMG_PRODUCT_100_URL} from "../../../helper/config";



const ProductItem = props => {
  const {item} = props
  const dispatch = useDispatch()

  const addItem = (item) => {
    dispatch(addCartItem(item))
  }

  return (
    <div className={'productItemWrapper'}>
      <div className={'imageBox'}>
        {
          item.photo
            ? <img src={IMG_PRODUCT_100_URL+`${item.photo}`} alt="product_photo"/>
            : <img src={IMG_NO_PRODUCT_100} alt="no_photo"/>
        }
      </div>
      <div className={'contentBox'}>
        <div className={'titleText'}>{item.nameKo} <span className={'text01 fontSize14 textThi'}>{item.nameTh}</span></div>
        <div className={'descText'}>{item.desc}</div>
        <div className={'text02'}>{item.price} THB <span className={'text02_01'}>( {item.unit} { item.unitName} )</span></div>
      </div>
      <div className={`cartBox ${item.count > 0 ? 'deepBg' : ''}`} onClick={()=>addItem(item)}>
        {
          item.count > 0
            ? <div className={'countText '}>{item.count}</div>
            : <div className={'iconText'}><FaCartPlus size={16} color={colors.white}/></div>
        }
      </div>

    </div>
  );
};

ProductItem.propTypes = {
  item: PropTypes.object
};

export default ProductItem;

import * as types from './../helpers/types'
import * as Api from '../../../src/helper/api'
import * as Utils from '../../../src/helper/utils'
import axios from "axios";
import {AlertMessage} from "../../components/QuickMessage";

// setToke Header
axios.interceptors.request.use(
  async config => {
    const token = await Utils.getAccessToken()
    if (token) {
      config.headers.Authorization = "Bearer "+token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
);

// refresh token
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response === undefined) {
      return Promise.reject(error);
    }

    const {data, status, config} = error.response;
    let originalRequest = config;

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (data.data === 'Expired' && !data.status) {
        console.log('refresh token request start')
        const reFreshToken = await Utils.getRefreshToken();
        if(!reFreshToken){
          return Promise.reject(error);
        }

        return Api.refreshTokenData_API(reFreshToken)
          .then( res => {
            console.log('new access token setting')
            const {data, status} = res.data;
            const {access_token} = data;
            // 토큰의 사용자가 유효하지 않은 때
            if(!status){
              Utils.clearToken()
              return Promise.reject(error);
            }
            Utils.setAccess_token(access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
            return axios(originalRequest);
          })
          .catch((error) => {
            Utils.clearToken()
            console.log('refreshToken Api error *********************************', error)
            return Promise.reject(error);
          })
      }
    }

    return Promise.reject(error.response);
  }
)


export const getBoardNotice = async (dispatch) : any => {
  try {
    const resData = await Api.getNoticeData_API()

    if (!resData.data) {return false}

    const {data, status} = resData.data

    if(status) {
      dispatch(setBoardNotice(data))
    }

  }catch (e) {
    dispatch(setBoardNotice([]))
  }
}


export const setTabState = (data) => (
  {
    type: types.SET_TAB_NAME,
    data,
  }
)

export const setIsOpenSide = (data) => (
  {
    type: types.IS_SIDE_MENU,
    data,
  }
)

export const setOrderModal = (data) => (
  {
    type: types.SET_ORDER_MODAL,
    data,
  }
)

export const setAlertMessage = (data : AlertMessage) => (
  {
    type: types.SET_ALERT_MESSAGE,
    data,
  }
)

export const setBoardNotice = (data) => (
  {
    type: types.SET_BOARD_NOTICE,
    data,
  }
)

export const setNativeView = (data) => (
  {
    type: types.IS_NATIVE_WEBVIEW,
    data,
  }
)

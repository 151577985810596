import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import '../styles/css/home.scss'
import Header from "../components/header";
import {useNavigate} from "react-router-dom";
import * as Temp from "../helper/tempData";
import {IMG_COMPANY_300_URL, IMG_USER_NO_URL} from "../helper/config";
import RecentlyList from "../components/recentlyList";
import ImageSlider from "../components/ImageSlider";

const HomePage = () => {
  const homePageRef = useRef(null);
  const navigate = useNavigate();
  const [dimensions, setDimensions] = useState({width: '', height: 850})
  const [partners, setPartners] = useState([]);

  const updateDimensions = () => {
    /**
     * 데이터가 가변적일땐 데이터가 없을 때를 기준으로
     * 화면이 그려지기 때문에 데이터가 변경될때 마다
     * 재호출 해서 화면사이즈를 갱신한다.
     * */
    if (homePageRef.current) {
      setDimensions({
        width: homePageRef.current.offsetWidth,
        height: homePageRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    setPartners(Temp.tempPartnerList)

    window.addEventListener("resize", updateDimensions);
    window.addEventListener('load', updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener('load', updateDimensions);
    };
  }, []);

  useEffect(()=> {
    updateDimensions()
  }, [partners])

  return (
    <div className={'homeWrapper'} ref={homePageRef}>
      <Header />
      <div className={'scrollWrapper'} style={{height: `${dimensions.height - 120}px`}}>
        <div className={'imageOutBox'}>
          <ImageSlider />
        </div>
        <div className={'bannerBox03'} onClick={()=>navigate(`/help/market_price`)}>
          <div className={'text01'}>야채 도매시세</div>
          <div className={'text02'}>기존 구입처와 가격을 비교해 보세요</div>
        </div>
        <div className={'bannerBox02'} onClick={()=>navigate(`/help/level`)}>
          <div className={'text01'}>등급/수수료안내</div>
          <div className={'text02'}>자세히보기</div>
        </div>
        <div className={'recentlyBox'}>
          <div className={'flexBt'}>
            <div className={'recentlyTit'}>최근 주문이 많아요</div>
            <div className={'moreText'} onClick={()=>navigate(`/products`)}>더보기</div>
          </div>
          <RecentlyList />
        </div>
        <div className={'partnerBox'}>
          <div className={'partnerTit'}>마켓다우 파트너사</div>
          <div className={'partnerCont'}>
            <ul>
              {
                partners.length > 0 && partners.map( item => {
                  return (
                    <li key={item.id}>
                      <div className={'cateItem'}>
                        {
                          item.photo
                            ? <img src={IMG_COMPANY_300_URL+`${item.photo}`} alt="logo"/>
                            : <img src={IMG_USER_NO_URL} alt="logo"/>
                        }
                      </div>
                      <div className={'itemName'}>
                        {item.companyName}
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

HomePage.propTypes = {
  setSideBar: PropTypes.func
};

export default HomePage;

import React, {CSSProperties} from 'react';
import PropTypes from 'prop-types';
import colors from "../../styles/colors";
import {HashLoader} from "react-spinners";

const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

const Loader = props => {
	const {loading} = props

	if(!loading) return

	return (
		<div className={'loader'}>
			<HashLoader color={colors.mainColor} loading={loading} cssOverride={override} size={40} />
		</div>
		// <div className={'loader'}>
		// 	<Dots color={colors.mainColor} size={20} speed={.5} />
		// </div>
	);
};

Loader.propTypes = {
	isLoaded: PropTypes.bool
};

export default Loader;

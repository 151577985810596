import React from 'react';
import Header from "../components/header";

const ReadyPage = props => {
	return (
		<div className={'readyWrapper'}>
			<Header/>
			<div className={'topTitleBox'}>
				<div className={'topTitle cartUnderBox'}>준비중<span className={'topSubTitle'}>READY</span></div>
				<div> </div>
			</div>
			<div>
				<div className={'noList'}>준비중입니다.</div>
			</div>
		</div>
	);
};

ReadyPage.propTypes = {

};

export default ReadyPage;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import Select from "react-select";
import {SupplyStoreListVo} from "../../../helper/vo";
import * as Api from "../../../helper/api";

Modal.setAppElement('#root');
const SupplyStoreSelectModal = props => {
	const {success, isOpen, setIsOpen, oldData} = props
	const [supplyStoreList, setSupplyStoreList] = useState([]);
	const [selectedSupplyStore, setSelectedSupplyStore] = useState('');

	const closeModal = () => {
		setIsOpen(false)
	}

	const confirmSuccess = () => {
		if(selectedSupplyStore) {
			success(selectedSupplyStore.ssId)
			closeModal()
		}else{
			alert('공급처를 선택해주세요.')
		}
	}

	const getSupplyStoreData = async () : void => {
		try {
			const resData = await Api.getSupplyStore_API()
			console.log('res >>', resData)
			if (!resData.data) return
			const { data, status } = resData.data
			if(status) {
				const resultData = data.length > 0 ? data.map( item => {
					const tempObj: SupplyStoreListVo = {}
					tempObj.ssId = Number(item.ss_id)
					tempObj.storeName = item.store_name
					tempObj.label = item.store_name
					tempObj.value = item.ss_id
					return tempObj
				}) : []
				setSupplyStoreList(resultData)
			}
		}catch (e) {
			// console.log('e', e)
		}
	}

	const initView = () => {
		if(oldData) {
			setSelectedSupplyStore(oldData)
		}

		getSupplyStoreData()
	}

	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={initView}
			// onRequestClose={()=>setIsOpen(false)}
			contentLabel="Example Modal"
			className="orderModalWrapper"
			overlayClassName={'modalOverlay'}
			shouldCloseOnOverlayClick={false}
		>
			<div className={'confirmModalWrapper supplyStoreModalWrapper'}>
				<div className={'confirmInBox'}>
					<div className={'text01'}>ชื่อร้าน(ซื้อ) 구매처선택</div>
					<div className={'selectView'}>
						<Select
							className={'selectBox'}
							value={selectedSupplyStore}
							onChange={setSelectedSupplyStore}
							options={supplyStoreList}
							placeholder={'구매처를 선택해주세요.'}
							isSearchable={false}
						/>
					</div>
				</div>
				<div className={'flexRow confirmBtnBox'}>
					<div className={'buttonBtn cancelBtn'} onClick={closeModal}>취소</div>
					<div className={'buttonBtn confirmBtn'} onClick={confirmSuccess}>ตรวจสอบ (확인)</div>
				</div>
			</div>
		</Modal>
	);
};

SupplyStoreSelectModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	success: PropTypes.func.isRequired,
	oldData: PropTypes.any,
};

export default SupplyStoreSelectModal;

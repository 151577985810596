/**
 * COMM
 * */
export const SET_TAB_NAME = 'SET_TAB_NAME';
export const IS_SIDE_MENU = 'IS_SIDE_MENU';
export const SET_ORDER_MODAL = 'SET_ORDER_MODAL';
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const SET_BOARD_NOTICE = 'SET_BOARD_NOTICE';
export const IS_NATIVE_WEBVIEW = 'IS_NATIVE_WEBVIEW';

/**
 * TEMP
 * */
export const SET_USER_TYPE = 'SET_USER_TYPE';




/**
 * USER
 * */
export const SET_USER_INIT = 'SET_USER_INIT';
export const SET_USER_LOGIN = 'SET_USER_LOGIN';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_COMPANY = 'SET_USER_COMPANY';
export const SET_USER_REQ_GRADE = 'SET_USER_REQ_GRADE';



/**
 * PRODUCT
 * */
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCTS_MARKET_PRICE = 'SET_PRODUCTS_MARKET_PRICE';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_SEARCH_PRODUCTS = 'SET_SEARCH_PRODUCTS';
export const SET_FILTERED_PRODUCTS = 'SET_FILTERED_PRODUCTS';
export const SET_CATEGORY_PRODUCTS = 'SET_CATEGORY_PRODUCTS';
export const SET_RECENTLY_PRODUCTS = 'SET_RECENTLY_PRODUCTS';

// 전일 가격변동 체크를 위해 담아둔다
// DB에 저장할까 했지만 사용기능이 명확하지 않아 임시로 사용하기 위해 프론트에서 처리
export const SET_PACKING_PRODUCTS = 'SET_PACKING_PRODUCTS';



/**
 * CART
 * */
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const MINUS_CART_ITEM = 'MINUS_CART_ITEM';
export const PLUS_CART_ITEM = 'PLUS_CART_ITEM';



/**
 * ORDER
 * */
export const GET_ORDER_LIST = 'GET_ORDER_LIST';
export const SET_ORDER = 'SET_ORDER';

import React, {useEffect, useState} from 'react';
import FaqItem from "./faqItem";
import * as Temp from "../../helper/tempData";

const FaqList = () => {
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    setFaqList(Temp.faqListData)
  }, []);

  return (
    <div className={'faqBox'}>
      <div className={'titleBox'}>
        <div className={'t01'}>FAQ</div>
        <div> </div>
      </div>
      <div className={'faqList'}>
        {
          faqList.length > 0 ? faqList.map(item => {
            return (
              <FaqItem
                key={item.id}
                item={item}
              />
            )
          }) : <div></div>
        }
      </div>
    </div>
  );
};

FaqList.propTypes = {};

export default FaqList;

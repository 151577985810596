import React, {useEffect, useRef, useState} from 'react';
import moment from "moment";
import Header from "../../components/header";
import Loader from "../../components/Loader";
import {useNavigate} from "react-router-dom";
import * as Api from "../../helper/api";
import * as Utils from "../../helper/utils";
import {DeliveryVo} from "../../helper/vo";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";



const WorkDeliveryPage = () => {
	const workDeliveryRef = useRef(null);
	const navigate = useNavigate();
	const {profile} = useSelector(state => state.userState);
	const [isLoaded, setIsLoaded] = useState(false);
	const [deliveryDate, setDeliveryDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
	const [dimensions, setDimensions] = useState({width: '', height: 500})
	const [data, setData] = useState([]);
	const {userType} = profile;


	const goPage = (orderId: string | number = '', date: string) : void => {
		if(!orderId || !date) return

		navigate(`/work/delivery/${orderId}/${date}`)
	}

	const getData = async (date:string) : void => {
		setIsLoaded(false)
		const targetDate = date ? date : moment(new Date()).format('YYYY-MM-DD')
		try {
			const resData = await Api.getDelivery_API(targetDate)
			// console.log('Delivery resData >>>>>', resData)
			if (!resData.data) return
			const { data, status } = resData.data
			if(status) {
				const resultData = data && data.length > 0 ? data.map(item => {
					const tempObj:DeliveryVo={}
					tempObj.orderId = item.order_id
					tempObj.orderUserId = item.user_id
					tempObj.storeName = item.store || `상점${item.user_id}`
					tempObj.deliveryDate = moment(item.delivery_dt).format('YYYY-MM-DD')
					tempObj.deliveryStatus = item.delivery_status
					return tempObj
				}) : []

				setData(resultData)
			}
		}catch (e) {
			// console.log('resData error>>>>>', e)
		}finally {
			setTimeout(()=>{
				setIsLoaded(true)
			}, 300)
			updateDimensions()
		}
	}

	useEffect(()=> {
		deliveryDate && getData(deliveryDate)
	}, [deliveryDate])

	useEffect(()=> {
		// getData()
	}, [])


	const updateDimensions = () => {
		if (workDeliveryRef.current) {
			setDimensions({
				width: workDeliveryRef.current.offsetWidth,
				height: workDeliveryRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<div className={'workDeliveryWrapper'} ref={workDeliveryRef}>
			<Header userType={'MANAGER'} />
			<div className={'topTitleBox'}>
				<div className={'topTitle deliveryUnderBox '}>배송<span className={'topSubTitle'}>DELIVERY</span></div>
				<div className={'fontSize20 textBold'}>
					{
						userType === 'ADMIN'
							?
							<DatePicker
								dateFormat="yyyy-MM-dd"
								className={'datePicker'}
								// minDate={new Date(new Date().setDate(new Date().getDate() + 1))} // 선택할 수 있는 최소 날짜값 지정
								closeOnScroll={true}    // 스크롤을 움직였을 때 자동으로 닫히도록 설정 기본값 false
								selected={new Date(deliveryDate)}
								onChange={(date) => setDeliveryDate(moment(date).format('YYYY-MM-DD'))}
								onFocus={e => e.target.blur()}
								onKeyDown={(e) => {
									e.preventDefault();
								}} // 화면을 직접 수정할 수 없게 함
							/>
							:
						moment(new Date()).format('YYYY-MM-DD')
					}
				</div>
			</div>
			<div className={'noticeBox'}>
				<div>วันนี้งานดี 기사님 오늘도 수고해주세요</div>
			</div>
			<div className={'deliveryListBox scrollWrapper'} style={{height: `${dimensions.height - 220}px`}}>
				{
					data && data.length ? data.map( (item, index) => {
						return(
							<div className={'deliveryItem'} key={item.orderId} onClick={()=>goPage(item.orderId, item.deliveryDate)}>
								<div>
									<span>{index+1}</span>
									<span className={'text01 textThi'}>{item.storeName}</span>
								</div>
								<div className={`text02 ${item.deliveryStatus === 'DONE' && 'text03'}`}>{Utils.getDeliveryStatusName(item.deliveryStatus, 'TH')}</div>
							</div>
						)
					})
					:
					<div className={'noData'}>배송내역이 존재하지 않습니다</div>
				}
			</div>
			<Loader loading={!isLoaded}/>
		</div>
	);
};

WorkDeliveryPage.propTypes = {

};

export default WorkDeliveryPage;

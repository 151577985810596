import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {HiArrowLeft, HiShoppingCart} from "react-icons/hi";
import NumberInput from "../../components/input/numberInput";
import TextInput from "../../components/input/textInput";
import Loader from "../../components/Loader";
import Switch from "react-switch";
import Select from "react-select";
import * as Temp from "../../helper/tempData";
import * as Api from "../../helper/api";
import {ManageProductVo} from "../../helper/vo";


const customStyles = {
	control: base => ({
		...base,
		height: 45,
		minHeight: 45
	})
};


const ProductManageDetailPage = props => {
	// 상태
	const {productId, isOpen, setIsOpen, pageCallback} = props
	const [isLoaded, setIsLoaded] = useState(true);
	const productManageDtRef = useRef(null)
	const [dimensions, setDimensions] = useState({width: '', height: 830})

	// 데이터
	const [isSell, setIsSell] = useState(false);
	const [isShowMarketPrice, setIsShowMarketPrice] = useState(false);
	const [category, setCategory] = useState('');
	const [nameKo, setNameKo] = useState('');
	const [nameTh, setNameTh] = useState('');
	const [desc, setDesc] = useState('');
	const [price, setPrice] = useState(0);
	const [marketPrice, setMarketPrice] = useState(0);
	const [unit, setUnit] = useState('');
	const [unitName, setUnitName] = useState('');

	const pageGoBack = () => {
		setIsOpen(false)
	}

	const initPage = () => {
		if(productId) {
			getData(productId)
		}else{
			resetData()
		}
	}

	const resetData = () => {
		// console.log('reset ******')
		setIsSell(false)
		setIsShowMarketPrice(false)
		setCategory('')
		setNameKo('')
		setNameTh('')
		setDesc('')
		setPrice(0)
		setMarketPrice(0)
		setUnit('')
		setUnitName('')
	}

	const getData = async (id) : void => {
		// console.log('getData ***')
		setIsLoaded(false)
		try {
			const resData = await Api.getManageProducts_API(id)
			if (!resData.data) return

			const { data, status } = resData.data
			const resCategory = Temp.CategoryList.find(i => i.value === data.category)
			const resUnitName = Temp.UnitNameData.find(i => i.value === data.unit_name)
			const resUnitCount = Temp.UnitCountData.find(i => i.value === data.unit)

			if(status) {
				setIsSell(data.is_sell === 'Y')
				setIsShowMarketPrice(data.is_market === 'Y')
				setCategory({value: resCategory.value, label: resCategory.label})
				setNameKo(data.name_ko || '')
				setNameTh(data.name_th || '')
				setDesc(data.desc || '')
				setPrice(Number(data.price))
				setMarketPrice(Number(data.market_price))
				setUnit({value: resUnitCount.value, label: resUnitCount.label})
				setUnitName({value: resUnitName.value, label: resUnitName.label})
			}

		}catch (e) {

		}finally {
			setIsLoaded(true)
		}
	}

	const setData = async () : void => {

		if(!validate()) return

		const params: ManageProductVo = {}
		params.productId = productId
		params.isSell = isSell ? 'Y' : 'N'
		params.isMarket = isShowMarketPrice ? 'Y' : 'N'  // 실거래표시
		params.category = category && category.value
		params.nameKo = nameKo
		params.nameTh = nameTh
		params.desc = desc
		params.price = price
		params.marketPrice = marketPrice
		params.unit = unit && parseFloat(unit.value)
		params.unitName = unitName && unitName.value

		try {
			const resData = await Api.setManageProducts_API(params)
			// console.log('res', resData)
			pageCallback(params)
			pageGoBack()
		}catch (e) {
			console.log('e', e)
		}finally {

		}
	}

	const validate = () => {
		if (category === 'ALL' || category === '') {
			alert('상품카테고리를 선택해주세요.')
			// setMessage('이메일을 입력해주세요.')
			return false
		}

		if (!nameKo) {
			alert('상품명(한국)를 입력해주세요.')
			return false
		}

		if (!nameTh) {
			alert('상품명(태국)를 입력해주세요.')
			return false
		}

		if (!price) {
			alert('판매 가격을 입력해주세요.')
			return false
		}

		if (!unit) {
			alert('판매 기준단위을 입력해주세요.')
			return false
		}

		if (!unitName) {
			alert('기준단위표시를 입력해주세요.')
			return false
		}

		return true
	}

	const updateDimensions = () => {
		if (productManageDtRef.current) {
			setDimensions({
				width: productManageDtRef.current.offsetWidth,
				height: productManageDtRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<SlidingPane
			className="some-custom-class"
			overlayClassName="some-custom-overlay-class"
			isOpen={isOpen}
			width={'100%'}
			hideHeader={true}
			onRequestClose={() => {
				// triggered on "<" on left top click or on outside click
				// setIsOpen(false);
				pageGoBack()
			}}
			onAfterOpen={initPage}
		>
			<div className={'productManageDtWrapper'} ref={productManageDtRef}>
				<div className={'herderBtnBox'}>
					<div className={'goBackBtn'} onClick={pageGoBack}><HiArrowLeft size={25}/></div>
					<div className={'titleText titleFont'}>상품정보 {productId ? '수정' : '등록'}</div>
				</div>
				<div className={'scrollWrapper'} style={{height: `${dimensions.height - 50}px`}}>
					<div className={'toggleView'}>
						<div className={'titBox'}>
							<div className={'tit001'}>판매여부</div>
							<div className={'sub001'}>เลือก ขาย</div>
						</div>
						<Switch onChange={setIsSell} checked={isSell} onColor={'#2e9063'} uncheckedIcon={false} checkedIcon={false}/>
					</div>
					<div className={'toggleView'}>
						<div className={'tit'}>실거래표시</div>
						<Switch onChange={setIsShowMarketPrice} checked={isShowMarketPrice} onColor={'#2e9063'} uncheckedIcon={false} checkedIcon={false}/>
					</div>
					<div className={'selectView'}>
						<div className={'tit'}>카테고리</div>
						<Select
							className={'selectBox'}
							value={category}
							onChange={setCategory}
							options={Temp.CategoryList}
							placeholder={'เลือก หมวดหมู่'}
							// placeholder={'카테고리를 선택해주세요. (เลือก หมวดหมู่)'}
							isSearchable={false}
							styles={customStyles}
							isDisabled={productId}
						/>
					</div>
					<TextInput
						title={'상품명 (한글)'}
						placeholderText={'ชื่อผลิตภัณฑ์ (ภาษาเกาหลี)'}
						// placeholderText={'상품명을을 입력해주세요'}
						value={nameKo}
						setValue={setNameKo}
						isRow={true}
					/>
					<TextInput
						title={'상품명 (태국)'}
						placeholderText={'ชื่อผลิตภัณฑ์ (ภาษาไทย)'}
						// placeholderText={'상품명'}
						value={nameTh}
						setValue={setNameTh}
						isRow={true}
					/>
					<TextInput
						title={'상품설명'}
						placeholderText={'상품설명'}
						value={desc}
						setValue={setDesc}
						isRow={true}
					/>
					<NumberInput
						title={'판매가격 (THB)'}
						// placeholderText={'판매가격'}
						placeholderText={'ขายราคา'}
						value={price}
						setValue={setPrice}
						isRow={true}
					/>
					<NumberInput
						title={'실거래가격 (THB)'}
						placeholderText={'실거래가격'}
						value={marketPrice}
						setValue={setMarketPrice}
						isRow={true}
					/>
					{/*<NumberInput*/}
					{/*	title={'판매 기준단위'}*/}
					{/*	placeholderText={'판매 기준단위'}*/}
					{/*	value={unit}*/}
					{/*	setValue={setUnit}*/}
					{/*	isRow={true}*/}
					{/*/>*/}
					<div className={'selectView'}>
						<div className={'tit'}>판매 기준단위</div>
						<Select
							className={'selectBox'}
							value={unit}
							onChange={setUnit}
							options={Temp.UnitCountData}
							// placeholder={'기준단위표시를 선택해주세요.'}
							placeholder={'เลือก'}
							isSearchable={false}
							styles={customStyles}
						/>
					</div>
					<div className={'selectView'}>
						<div className={'tit'}>기준단위표시</div>
						<Select
							className={'selectBox'}
							value={unitName}
							onChange={setUnitName}
							options={Temp.UnitNameData}
							// placeholder={'기준단위표시를 선택해주세요.'}
							placeholder={'เลือก'}
							isSearchable={false}
							styles={customStyles}
						/>
					</div>
					<div className={'sectionText'}>
						<div>실시간 예약</div>
					</div>
				</div>
				<div className={'fullBottomBox'}>
					<div className={'saveBtn focusBaseBtn activeBtn'} onClick={setData}><span>등록 (ยืนยันการปรับปรุง)</span></div>
				</div>
			</div>
			<Loader loading={!isLoaded}/>
		</SlidingPane>
	);
};

ProductManageDetailPage.propTypes = {
	productId: PropTypes.number,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	pageCallback: PropTypes.func,
};

export default ProductManageDetailPage;

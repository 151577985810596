import React from 'react';
import PropTypes from 'prop-types';

const TextInput = props => {
	const { title, placeholderText, value, setValue, isRow} = props
	const isRowView = isRow || false

	return (
		<div className={`inputBaseWrapper textInputWrapper ${isRowView ? 'inputRowType': ''}`}>
			<div className={`inputTitle ${isRowView ? 'inputRowTitle': ''}`}>{title}</div>
			<div className={`inputBox`}>
				<input
					value={value}
					onChange={(e)=>setValue(e.target.value)}
					placeholder={placeholderText ? placeholderText : ''}
				/>
			</div>
		</div>
	);
};

TextInput.propTypes = {
	title: PropTypes.string,
	placeholderText: PropTypes.string,
	value: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	isRow: PropTypes.bool,
};

export default TextInput;

import createReducer from "../helpers/createReducers";
import * as initialType from "../helpers/initialTypes";
import * as types from './../helpers/types'


export const orderState = createReducer( initialType.initialOrderState, {
	[types.GET_ORDER_LIST](state, action) {

		return ({
			...state,
			orderList: action.data
		});
	},
})

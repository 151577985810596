import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import Select from 'react-select';
import * as Temp from "../../../helper/tempData";
import {numberFormat} from "../../../helper/utils";


Modal.setAppElement('#root');
const ConfirmGradeModal = props => {
	const {success, isOpen, setIsOpen, price} = props
	const [selectedGrade, setSelectedGrade] = useState(null);

	const closeModal = () => {
		setIsOpen(false)
	}

	const confirmSuccess = () => {
		if(selectedGrade) {
			success(selectedGrade)
			closeModal()
		}else{
			alert('등급을 선택해주세요.')
		}
	}

	const suggestGrade = (price: number) => {
		const resultData = Temp.levelData.find( item => {
			return item.price <= price
		})

		if(resultData) {
			return resultData
		}

		return {value:'NEW', label: 'NEW'}
	}

	const initView = () => {
		// 추천값 넣어줌
		setSelectedGrade(suggestGrade(price))
	}

	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={initView}
			// onRequestClose={()=>setIsOpen(false)}
			contentLabel="Example Modal"
			className="orderModalWrapper"
			overlayClassName={'modalOverlay'}
			shouldCloseOnOverlayClick={false}
		>
			<div className={'confirmModalWrapper confirmGradeModalWrapper'}>
				<div className={'confirmInBox'}>
					<div className={'text01'}>누적금액 <span className={'spanText01'}>{price && `${numberFormat(price)} THB`}</span></div>
					<div className={'selectView'}>
						<Select
							className={'selectBox'}
							value={selectedGrade}
							onChange={setSelectedGrade}
							options={Temp.levelData}
							isSearchable={false}
						/>
					</div>
				</div>
				<div className={'flexRow confirmBtnBox'}>
					<div className={'buttonBtn cancelBtn'} onClick={closeModal}>취소</div>
					<div className={'buttonBtn confirmBtn'} onClick={confirmSuccess}>확인</div>
				</div>
			</div>
		</Modal>
	);
};

ConfirmGradeModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	success: PropTypes.func.isRequired,
	price: PropTypes.number.isRequired,
};

export default ConfirmGradeModal;

import { combineReducers }  from 'redux';
import * as userReducer from '../reducers/userReducer'
import * as commReducer from '../reducers/commReducer'
import * as productReducer from '../reducers/productReducer'
import * as cartReducer from '../reducers/cartReducer'
import * as orderReducer from '../reducers/orderReducer'

export default combineReducers(Object.assign(
  commReducer,
  userReducer,
	productReducer,
	cartReducer,
	orderReducer,
))

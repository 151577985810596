import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import * as Api from "../../helper/api";
import {DeliveryItemVo} from "../../helper/vo";
import {HiArrowLeft} from "react-icons/hi";
import Loader from "../../components/Loader";
import InspectionButton from "../../components/buttons/inspectionButton";
import ShippedButton from "../../components/buttons/shippedButton";
import DeliveryStatusButton from "../../components/buttons/deliveryStatusButton";


const WorkDeliveryDetailPage = () => {
	const {orderId, date} = useParams()
	const workDeliveryDtRef = useRef(null);
	const navigate = useNavigate();
	const [isLoaded, setIsLoaded] = useState(false);
	const [dimensions, setDimensions] = useState({width: '', height: 500})
	const [deliveryItems, setDeliveryItems] = useState([]);
	const [companyData, setCompanyData] = useState({
		storeName: '',
		hotPhone: '',
		address: '',
		deliveryZone: '',
	});
	const [isChecked, setIsChecked] = useState(false); // 상차 전부 체크
	const [isShipped, setIsShipped] = useState(false); // 배송 전부 체크
	const [isCheckSuccess, setIsCheckSuccess] = useState(false); // 상차 상태 변경
	const [isShippedSuccess, setIsShippedSuccess] = useState(false); // 배송 상태 변경


	const getData = async () : void => {
		setIsLoaded(false)
		try {
			const resData = await Api.getDeliveryDt_API(orderId, date)
			// console.log('WorkDeliveryDetail resData >>>>>', resData)
			if (!resData.data) return
			const { data, status } = resData.data
			if(status) {
				const { companyInfo, orderItems } = data

				const resultData = orderItems && orderItems.length > 0 ? orderItems.map(item => {
					const tempObj:DeliveryItemVo={}
					tempObj.id = Number(item.id)
					tempObj.code = item.code
					tempObj.nameKo = item.name_ko
					tempObj.nameTh = item.name_th
					tempObj.nameEn = item.name_en
					tempObj.orderCount = item.cnt ? Number(item.cnt) : 0
					tempObj.unit = item.unit ? Number(item.unit) : 0
					tempObj.unitName = item.unit_name
					tempObj.deliveryStatus = item.delivery_status
					tempObj.isChecked = item.is_checked === "1"
					tempObj.isShipped = item.is_shipped === "1"
					// tempObj.deliveryDate = moment(item.delivery_dt).format('YYYY-MM-DD')

					return tempObj
				}) : []

				// console.log('resultData.deliveryStatus', resultData[0].deliveryStatus)
				if(resultData.length > 0) {
					if(resultData[0].deliveryStatus === 'ING') {
						setIsCheckSuccess(true)
					} else if(resultData[0].deliveryStatus === 'DONE') {
						setIsCheckSuccess(true)
						setIsShippedSuccess(true)
					}
				}

				setDeliveryItems(resultData)

				if(companyInfo) {
					// 정보 입력 정리해야 함
					setCompanyData({
						storeName: companyInfo && companyInfo.store,
						hotPhone: companyInfo && companyInfo.hot_phone,
						address: companyInfo && companyInfo.address,
						deliveryZone: companyInfo && companyInfo.delivery_zone,
					})
				}
			}

		}catch (e) {
			// console.log('resData error>>>>>', e)
		}finally {
			setTimeout(()=>{
				setIsLoaded(true)
			}, 300)
			updateDimensions()
		}
	}

	const IsAllCheckItems = () => {
		return deliveryItems && deliveryItems.length > 0 && deliveryItems.every(item => item.isChecked)
	}

	const IsAllShippedItems = () => {
		return deliveryItems && deliveryItems.length > 0 && deliveryItems.every(item => item.isShipped)
	}

	const setItemIsCheck = (id, isValue) => {
		const result = deliveryItems && deliveryItems.length > 0 && deliveryItems.map(item => {
			if(item.id === id) item.isChecked = isValue
			return item
		})

		setDeliveryItems(result)
	}

	const setItemIsCheckShipped = (id, isValue) => {
		const result = deliveryItems && deliveryItems.length > 0 && deliveryItems.map(item => {
			if(item.id === id) item.isShipped = isValue
			return item
		})

		setDeliveryItems(result)
	}

	useEffect(() => {
		// 전체 체크가 되면 최종 하단 버튼 활성화
		if(isCheckSuccess) {
			// 배송완료 체크
			setIsShipped(IsAllShippedItems())
		}else{
			// 상품준비 체크
			setIsChecked(IsAllCheckItems())
		}

	}, [deliveryItems]);


	useEffect(()=> {
		getData()
	}, [])

	const updateDimensions = () => {
		if (workDeliveryDtRef.current) {
			setDimensions({
				width: workDeliveryDtRef.current.offsetWidth,
				height: workDeliveryDtRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<div className={'workDeliveryDtWrapper'} ref={workDeliveryDtRef}>
			{/*<Header userType={'MANAGER'} />*/}
			<div className={'herderBtnBox'}>
				<div className={'goBackBtn'} onClick={() => navigate('/work/delivery')}><HiArrowLeft size={25}/></div>
				<div className={'topTitle'}> </div>
			</div>
			<div className={'topTitleBox'}>
				<div className={'topTitle deliveryDtUnderBox'}>배송상세<span className={'topSubTitle'}>DELIVERY DETAIL</span></div>
				<div> </div>
			</div>
			<div className={'deliveryLocBox'}>
				<div className={'listBox'}>
					<div className={'box01'}>ZONE</div>
					<div className={'box02'}>{companyData.deliveryZone}</div>
				</div>
				<div className={'listBox'}>
					<div className={'box01'}>STORE</div>
					<div className={'box02'}>{companyData.storeName}</div>
				</div>
				<div className={'listBox'}>
					<div className={'box01'}>TEL</div>
					<div className={'box02'}>{companyData.hotPhone}</div>
				</div>
				<div className={'listBox h50'}>
					<div className={'box01'}>ADDR</div>
					<div className={'box02'}>{companyData.address}</div>
				</div>
			</div>
			<div className={'deliveryDtListBox scrollWrapper'} style={{height: `${dimensions.height - 360}px`}}>
				{
					deliveryItems && deliveryItems.length ? deliveryItems.map( (item, index) => {
							return(
								<div className={'deliveryDtItem'} key={item.id}>
									<div className={'textBox'}>
										<span>{index+1}</span>
										<span className={'text01 textThi'}>{item.nameTh}</span>
										<span className={'text02'}>{item.nameKo}</span>
									</div>
									<div className={'unitBox'}>
										{item.unit * item.orderCount} {item.unitName}
									</div>
									<div className={'btnBox'}>
										{
											isCheckSuccess
												?
												<ShippedButton
													id={item.id}
													isChecked={item.isShipped}
													isDisabled={isShippedSuccess}
													setItemIsCheck={setItemIsCheckShipped}
												/>
												:
												<InspectionButton
													id={item.id}
													isChecked={item.isChecked}
													isDisabled={isCheckSuccess}
													setItemIsCheck={setItemIsCheck}
												/>
										}
									</div>
								</div>
							)
						})
						:
						<div className={'noData'}>배송내역이 존재하지 않습니다</div>
				}
			</div>
			<div className={'bottomBox'}>
				{
					isShippedSuccess ?
					<div className={'shippedBtn activeDoneBtn'}>배송완료 จัดส่งเรียบร้อยแล้ว</div>
					: isCheckSuccess
						? <DeliveryStatusButton type={'DELIVERY'} id={Number(orderId)} isChecked={isShipped} setSuccess={setIsShippedSuccess}/> // 배송완료
						: <DeliveryStatusButton type={'READY'} id={Number(orderId)} isChecked={isChecked} setSuccess={setIsCheckSuccess}/> // 상품완료
				}
			</div>
			<Loader loading={!isLoaded}/>
		</div>
	);
};

WorkDeliveryDetailPage.propTypes = {

};

export default WorkDeliveryDetailPage;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DeliveryStatusVO} from "../../../helper/vo";
import * as Api from "../../../helper/api";
import {HashLoader} from "react-spinners";
import colors from "../../../styles/colors";

const DeliveryStatusButton = props => {
	const {id, type, isChecked, setSuccess} = props
	const [isLoaded, setIsLoaded] = useState(true);

	const setStatus = async (id, value) => {
		if(!isChecked) return

		const params: DeliveryStatusVO = {}
		params.orderId = id
		params.deliveryStatus = value

		setIsLoaded(false)
		try {
			const resData = await Api.setDeliveryStatus_API(params)
			if (!resData.data) return
			const { status } = resData.data
			if(status) {

			}

			setSuccess(true)

		}catch (e) {
			// console.log('setCheck error', e)
		}finally {
			setTimeout(()=> {
				setIsLoaded(true)
			}, 1000)
		}
	}

	if(type === 'STAY') {
		return (
			<div className={`shippedBtn ${isChecked ? 'activeBtn' : 'defaultBtn'}`} onClick={()=>setStatus(id, 'READY')}>
				{isLoaded
					? isChecked ? '상품준비' : '상품준비중'
					:
					<div className={'loadingBox'}>
						<HashLoader color={colors.white} loading={!isLoaded} size={20} />
					</div>
				}
			</div>
		)
	}else if(type === 'READY') { // 물건 분류매니저 사용
		return (
			<div className={`shippedBtn ${isChecked ? 'activeBtn' : 'defaultBtn'}`} onClick={()=>setStatus(id, 'ING')}>
				{isLoaded
					? isChecked ? 'เปลี่ยนการจัดส่ง' : 'เตรียมสินค้าเสร็จสิ้น'  // เปลี่ยนการจัดส่ง: 배송변경, เตรียมสินค้าเสร็จสิ้น : 준비완료
					:
					<div className={'loadingBox'}>
						<HashLoader color={colors.white} loading={!isLoaded} size={20} />
					</div>
				}
			</div>
		)
	}else if(type === 'DELIVERY') { // 배송매지저 사용
		return (
			<div className={`shippedBtn ${isChecked ? 'activeBtn' : 'defaultBtn'}`} onClick={()=>setStatus(id, 'DONE')}>
				{isLoaded
					? isChecked ? 'เช็คสินค้า' : 'กำลังจัดส่ง'  // เช็คสินค้า : 배송완료 ,กำลังจัดส่ง : 배송중
					:
					<div className={'loadingBox'}>
						<HashLoader color={colors.white} loading={!isLoaded} size={20} />
					</div>
				}
			</div>
		);
	}


};

DeliveryStatusButton.propTypes = {
	type: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	isChecked: PropTypes.bool.isRequired,
	setSuccess: PropTypes.func.isRequired,
};

export default DeliveryStatusButton;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ManageOrderItem from "./manageOrderItem";
import OrderManageDetailPage from "../../../pages/admin/OrderManageDetailPage";

const ManageOrderList = props => {
	const {data, height, pageCallback} = props
	const [isOpen, setIsOpen] = useState(false);
	const [orderId, setOrderId] = useState(0);

	const goPage = (id: number) => {
		if(!id) return
		setOrderId(id)
		setIsOpen(true)
	}

	return (
		<div className={'manageOrderListWrapper scrollWrapper'} style={{height: `${height}px`}}>
			{
				data && data.length > 0 ? data.map((item, index)=> {
					return(
						<ManageOrderItem
							key={item.orderId}
							item={item}
							goPage={goPage}
						/>
					)
				}) : <div className={'noList'}>판매목록이 없습니다.</div>
			}
			<OrderManageDetailPage id={orderId} isOpen={isOpen} setIsOpen={setIsOpen} pageCallback={pageCallback}/>
		</div>
	);
};

ManageOrderList.propTypes = {
	data: PropTypes.array,
	height: PropTypes.number,
	pageCallback: PropTypes.func,
};

export default ManageOrderList;

import React, {useEffect} from 'react';
import '../../styles/css/footer.scss'
import {useLocation, useNavigate} from "react-router-dom";
import {BiCategoryAlt, BiFile, BiHome, BiMessageSquareDetail, BiPackage} from "react-icons/bi";
import {IoStorefrontOutline, IoWalletOutline} from "react-icons/io5"
import {TbTruckDelivery} from 'react-icons/tb';
import {useDispatch, useSelector} from 'react-redux';
import {setTabState} from "../../redux/actions/commAction";
import {categoryProductData, setCategory} from "../../redux/actions/productAction";


const Footer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {isShowBottomTab} = useSelector(state => state.commState);
  const {profile} = useSelector(state => state.userState);
  const {userType} = profile;

  const handleClickTab = (tabName: string, routeName: string) => {
    // 제품템 누를때 카테고리 전체로 초기화 하여 보여준다.
    if(tabName === 'PRODUCT') {
      dispatch(setCategory('ALL'))
      dispatch(categoryProductData('ALL'))
    }
    dispatch(setTabState(tabName))
    navigate(routeName)
  }

  const Tab = ({title = '', tabNm= '', routeName= '', iconName = null}) => {

    const isEqualRoute = (routeName) : boolean => {
      const  {pathname} = location
      return pathname.includes(routeName)
    }

    return (
      <div className={`tabBox ${isEqualRoute(routeName) ? 'activeTab' : ''}`}
           onClick={() => handleClickTab(tabNm, routeName)}>
        <div className={'tabItem'}>
          <div className={'tItem01'}>{iconName}</div>
          <div className={'tItem02'}>{title}</div>
        </div>
      </div>
    )
  }

  /**
   * 사용자권한에 따른 하단 메뉴세팅
   * */
  const CustomTabView = ({userType: string = 'NORMAL'}) => {
    switch (userType) {
      case 'MANAGER':
        return(
          <div className={'tabs'}>
            <Tab title={'รายการซื้อ'} tabNm={'PACKING'} routeName={'/work/pack'} iconName={<BiPackage size={24}/>}/>
            <Tab title={'รายการจัดส่งของร้านค้า'} tabNm={'DELIVERY'} routeName={'/work/delivery'} iconName={<TbTruckDelivery size={24}/>}/>
          </div>
        )
      case 'ADMIN':
        return (
          <div className={'tabs'}>
            {/*<Tab title={'상품준비'} tabNm={'WORK'} routeName={'/work/pack'} iconName={<BiPackage size={24}/>}/>*/}
            {/*<Tab title={'배송'} tabNm={'DELIVERY'} routeName={'/work/delivery'} iconName={<TbTruckDelivery size={24}/>}/>*/}
            <Tab title={'판매'} tabNm={'SELL'} routeName={'/manage/sell'} iconName={<BiFile size={24}/>}/>
            <Tab title={'구매'} tabNm={'BUY'} routeName={'/manage/purchase'} iconName={<IoWalletOutline size={24}/>}/>
            <Tab title={'상품 +'} tabNm={'PRODUCT'} routeName={'/manage/products'} iconName={<BiCategoryAlt size={24}/>}/>
            <Tab title={'회원'} tabNm={'MEMBER'} routeName={'/manage/member'} iconName={<IoStorefrontOutline size={24}/>}/>
            {/*<Tab title={'기타'} tabNm={'DELIVERY'} routeName={'/work/delivery'} iconName={<TbDots size={24}/>}/>*/}
          </div>
        )
      default:
        return (
          <div className={'tabs'}>
            <Tab title={'홈'} tabNm={'HOME'} routeName={'/home'} iconName={<BiHome size={24}/>}/>
            <Tab title={'상품'} tabNm={'PRODUCT'} routeName={'/products'} iconName={<BiCategoryAlt size={24}/>}/>
            <Tab title={'주문내역'} tabNm={'ORDER'} routeName={'/order'} iconName={<BiFile size={24}/>}/>
            <Tab title={'고객센터'} tabNm={'CUSTOMER'} routeName={'/help'} iconName={<BiMessageSquareDetail size={24}/>}/>
          </div>
        )
    }
  }

  // 페이지 초기화
  useEffect(() => {
    if(userType === 'MANAGER') {
      handleClickTab('PACKING', '/work/pack')
    }else if(userType === 'ADMIN') {
      handleClickTab('SELL', '/manage/sell')
    }else {
      handleClickTab('HOME', '/home')
    }
  }, [userType]);


  return (
    <div className={`footerWrapper ${ !isShowBottomTab ? 'isHideTab' : ''}`}>
      <CustomTabView userType={userType} />
    </div>
  );
};

Footer.propTypes = {};

export default Footer;

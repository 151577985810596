import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import PreviewPhotoUpload from "../../preViewPhotoUpload";


Modal.setAppElement('#root');
const PhotoUpLoadModal = props => {
	const {success, isOpen, title, viewType, setIsOpen, oldData} = props
	const [file, setFile] = useState(null);

	const closeModal = () => {
		setFile(null)
		setIsOpen(false)
	}

	const confirmSuccess = async () => {
		if(file) {
			success(file)
			closeModal()
		}else{
			alert(`${title} selected photo.`)
		}
	}

	const initView = () => {
		// if(oldData) {
		// 	setSelectedSupplyStore(oldData)
		// }
		//
		// getSupplyStoreData()
	}

	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={initView}
			// onRequestClose={()=>setIsOpen(false)}
			contentLabel="Example Modal"
			className="orderModalWrapper"
			overlayClassName={'modalOverlay'}
			shouldCloseOnOverlayClick={false}
		>
			<div className={'confirmModalWrapper photoUpLoadModalWrapper'}>
				<div className={'confirmInBox'}>
					<PreviewPhotoUpload
						inputName={'file'}
						title={`${title} photo`}
						viewType={viewType}
						oldPhoto={oldData}
						photo={file}
						setPhotoFile={(file)=>setFile(file)}
					/>
				</div>
				<div className={'flexRow confirmBtnBox'}>
					<div className={'buttonBtn cancelBtn'} onClick={closeModal}>취소</div>
					<div className={'buttonBtn confirmBtn'} onClick={confirmSuccess}>ตรวจสอบ (확인)</div>
				</div>
			</div>
		</Modal>
	);
};

PhotoUpLoadModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	viewType: PropTypes.string,
	success: PropTypes.func.isRequired,
	oldData: PropTypes.any,
};

export default PhotoUpLoadModal;

import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import MarketPriceItem from "./marketPriceItem";
import moment from "moment";

const MarketPriceList = props => {
	const {updateDimensions, height, pd} = props
	const {product} = useSelector(state => state.productState);
	const {marketPriceData} = product
	const cusPd = pd ? pd : 0

	useEffect(() => {
		updateDimensions()
	}, [marketPriceData]);

	return (
		<div className={'marketPriceListWrapper'}>
			<div className={'headerBox'}>
				<div>
					<div>업데이트 {moment(new Date()).format('YYYY.MM.DD')}</div>
				</div>
				<div className={'columnBox'}>
					<span className={'spanText01'}>{`전일\n시세`}</span>
					<span className={'spanText01'}>{`마켓\n다우`}</span>
				</div>
			</div>
			<div className={'scrollWrapper'}
				 style={{height: `${height}px`, paddingLeft: `${cusPd}px`, paddingRight: `${cusPd}px`}}>
				{
					marketPriceData?.length > 0 ? marketPriceData.map((item) => {
						return (
							<MarketPriceItem
								key={item.productId}
								item={item}
							/>
						)
					}) : <div className={'noList'}>No list</div>
				}
			</div>
		</div>
	);
};

MarketPriceList.propTypes = {
	updateDimensions: PropTypes.func,
	height: PropTypes.number,
	pd: PropTypes.number
};

export default MarketPriceList;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import MemberItem from "./memberItem";
import MemberManageDetailPage from "../../../pages/admin/MemberManageDetailPage";

const MemberList = props => {

	const {data, height, pageCallback} = props
	const [isOpen, setIsOpen] = useState(false);
	const [userId, setUserId] = useState(0);

	const goPage = (id: number) => {
		if(!id) return
		setUserId(id)
		setIsOpen(true)
	}

	return (
		<div className={'memberListWrapper scrollWrapper'} style={{height: `${height}px`}}>
			{
				data && data.length > 0 ? data.map((item, index)=> {
					return(
						<MemberItem
							key={item.userId}
							item={item}
							goPage={goPage}
						/>
					)
				}) : <div> </div>
			}
			<MemberManageDetailPage userId={userId} isOpen={isOpen} setIsOpen={setIsOpen} pageCallback={pageCallback}/>
		</div>
	);
};

MemberList.propTypes = {
	data: PropTypes.array,
	height: PropTypes.number,
	pageCallback: PropTypes.func,
};

export default MemberList;

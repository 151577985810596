import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import Header from "../../components/header";
import ManageProductList from "../../components/manage/productList";
import * as Api from "../../helper/api";
import {ManageProductVo} from "../../helper/vo";
import Loader from "../../components/Loader";
import SearchManage from "../../components/searchManage";
import ProductManageDetailPage from "./ProductManageDetailPage";

const ProductMangePage = () => {
	const productMangePageRef = useRef(null);
	const {product} = useSelector(state => state.productState);
	const [isLoaded, setIsLoaded] = useState(false);
	const [dimensions, setDimensions] = useState({width: '', height: 900})
	const [productData, setProductData] = useState({initData: [], filteredData: []});
	const [isOpen, setIsOpen] = useState(false);


	const getData = useCallback(async (): void => {
		try {
			const resData = await Api.getManageProducts_API()
			if (!resData.data) return
			const { data, status } = resData.data
			if(status) {
				const resultProductData = data && data.length > 0 ? data.map(item => {

					/***
					 * 최근 패킹된 상품정보에서 가격정보를 가져온다.
					 * @returns {number}
					 */
					const getRecentlyPrice = () => {
						const packingList = product.packingData
						const packingItem = packingList.length > 0 ? packingList.find( i => i.code === item.code ) : null
						if(!packingItem) return 0

						return parseInt(packingItem.buyPrice / packingItem.orderTotalCount)
					}

					const tempObj:ManageProductVo = {}
					tempObj.productId = Number(item.id)
					tempObj.code = item.code
					tempObj.category = item.category
					tempObj.nameKo = item.name_ko
					tempObj.nameTh = item.name_th
					tempObj.nameEn = item.name_en
					tempObj.desc = item.desc || ''
					tempObj.photo = item.photo
					tempObj.unit = parseFloat(item.unit)
					tempObj.unitName = item.unit_name
					tempObj.price = Number(item.price)
					tempObj.isMarket = item.is_market === 'Y'
					tempObj.isSell = item.is_sell === 'Y'
					tempObj.recentlyPrice = getRecentlyPrice()
					tempObj.updateDate = item.updated_dt
					return tempObj
				}) : []

				setProductData({
					initData: resultProductData,
					filteredData:  resultProductData
				})
			}
		}catch (e) {
			// console.log('ee', e)
		}finally {
			setTimeout(()=>{
				setIsLoaded(true)
			}, 300)
			updateDimensions()
		}
	}, [])

	const onSearch = (keyword: string) => {
		const {initData} = productData

		if(keyword){
			const filteredData = initData.length > 0 ? initData.filter( item => {
				return item.nameKo.includes(keyword) || item.nameTh.includes(keyword)
			}): []
			setProductData({...productData, filteredData: filteredData})
			return
		}

		setProductData({...productData, filteredData: initData})
	}

	const callback = () => {
		// console.log('등록 후 ')
		getData()
	}

	const margeData = (data) => {
		// category:"GR"
		// desc:"길이 25~30 cm"
		// isMarket:"N"
		// isSell:"Y"
		// marketPrice:27
		// nameKo:"배추"
		// nameTh:"ผักกาดขาว"
		// price:27
		// productId:1
		// unit:1
		// unitName:"kg"
		/**
		 * 기존데이터 다시 호출하지 않고 데이터을 변경한다.
		 * */
		if(!data) return
		const {productId, isMarket, isSell, desc, nameKo, nameTh, price} = data
		const {initData} = productData

		initData.map(item => {
			// 일단 시세표지부분만 적용
			if(item.productId === productId) {
				item.isMarket = isMarket === 'Y'
				item.isSell = isSell
				item.nameKo = nameKo
				item.nameTh = nameTh
				item.desc = desc
				item.price = price
			}
		})
	}

	const pageCallback = (data) => {
		margeData(data)
	}

	const updateDimensions = () => {
		if (productMangePageRef.current) {
			setDimensions({
				width: productMangePageRef.current.offsetWidth,
				height: productMangePageRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		getData()
	}, [getData]);

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<div className={'productManageWrapper'} ref={productMangePageRef}>
			<Header/>
			<div className={'topTitleBox'}>
				<div className={'topTitle cartUnderBox'}>상품관리<span className={'topSubTitle'}>PRODUCT MANAGE</span></div>
				<div className={'buttonBtn activeBtn addProductBtn'} onClick={()=>setIsOpen(true)}>상품등록(ผลิตภัณฑ์ใหม่)</div>
			</div>
			<SearchManage
				onSearch={onSearch}
			/>
			<ManageProductList
				pageCallback={pageCallback}
				data={productData.filteredData}
				height={dimensions.height - 250}
			/>
			<ProductManageDetailPage productId={null} isOpen={isOpen} setIsOpen={setIsOpen} pageCallback={callback} />
			<Loader loading={!isLoaded}/>
		</div>
	);
};

ProductMangePage.propTypes = {};

export default ProductMangePage;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setAlertMessage} from "../../redux/actions/commAction";



export interface AlertMessage {
	isShow: boolean,
	message: string,
	type: string,
}

const QuickMessage = () => {
	const dispatch = useDispatch()
	const {alertMessage} = useSelector(state => state.commState);
	const {isShow, message} = alertMessage

	useEffect(() => {
		if(isShow) {
			setTimeout(()=>{
				dispatch(setAlertMessage({isShow: false, message: ''}))
			}, 2000)
		}
	}, [dispatch, isShow]);

	if(!message) return

	return (
		<div className={`quickMessageWrapper ${isShow ? 'messageShow':'messageHide'}`}>
			<div className={'messageText'}>{message}</div>
			<div className={'messageBg'} />
		</div>
	);
};

QuickMessage.propTypes = {};

export default QuickMessage;

import React from 'react';
import PropTypes from 'prop-types';
import * as Utils from "../../../../helper/utils";
import moment from "moment/moment";


const ManageOrderItem = props => {
	const {item, goPage} = props

	return (
		<div className={'manageOrderItemWrapper'} onClick={()=>goPage(item.orderId)}>
			<div className={'col01'}>{item.orderId}</div>
			<div className={'col02'}>
				<div className={'lCol'}>
					<div className={'ltBox'}>
						<div className={'levelBox'} style={{color: `${Utils.levelTag(item.userGrade)}`}}>{item.userGrade ? item.userGrade : '-'}</div>
						<div className={'payTypeBox'}>{Utils.getPayStatusName(item.payType)}</div>
					</div>
					<div className={'text01'}>{item.storeName ? item.storeName : '상점명없음'}</div>
					<div className={'text02'}>{Utils.numberFormat(item.totalPrice)} THB </div>
				</div>
				{
					item.orderStatus === 'N' ?
						<div className={'rCol'}>
							<div className={'text01'}>{moment(item.deliveryDate).format('MM.DD')}</div>
							<div>{item.orderStatus === 'N' ? '취소' : ''}</div>
						</div>
						:
						<div className={'rCol'}>
							<div className={'text01'}>{moment(item.deliveryDate).format('MM.DD')}</div>
							<div className={`text02 ${item.deliveryStatus !== 'DONE' ? 'fontSize14 mainColorText fontBold' : 'subText'}`}>{Utils.getDeliveryStatusName(item.deliveryStatus)}</div>
						</div>
				}
			</div>
		</div>
	);
};

ManageOrderItem.propTypes = {
	item: PropTypes.object,
	goPage: PropTypes.func,
};

export default ManageOrderItem;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {HashLoader} from "react-spinners";
import colors from "../../../styles/colors";
import {ShippedCheckVO} from "../../../helper/vo";
import * as Api from "../../../helper/api";


const ShippedButton = props => {
	const {id, isChecked, isDisabled, setItemIsCheck} = props
	const [isLoaded, setIsLoaded] = useState(true);

	const setData = async (id, isValue) => {

		if(isDisabled) return

		const params: ShippedCheckVO = {}
		params.orderItemId = id
		params.isShipped = isValue

		setIsLoaded(false)

		try {
			const resData = await Api.setShippedCheck_API(params)
			if (!resData.data) return
			const { status } = resData.data
			if(status) {
				// DB를 새로 불러 오는것 보다 불러온 데이터만 바꾼다.
				setItemIsCheck(id, isValue)
			}
		}catch (e) {
			// console.log('setCheck error', e)
		}finally {
			setTimeout(()=> {
				setIsLoaded(true)
			}, 100)
		}
	}


	return (
		<div className={`loadingButtonWrapper shippedBtnWrapper ${isLoaded && isChecked ? 'activeCheckBtn' : 'defaultCheckBtn'}`} onClick={()=>setData(id, !isChecked)}>
			{isLoaded
				? isChecked ? 'เสร็จสิ้น' : 'การส่งสินค้า'
				:
				<div className={'loadingBox'}>
					<HashLoader color={colors.mainColor} loading={!isLoaded} size={15} />
				</div>
			}
		</div>
	);
};

ShippedButton.propTypes = {
	id: PropTypes.number.isRequired,
	isChecked: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	setItemIsCheck: PropTypes.func.isRequired,
};

export default ShippedButton;

import React, {useEffect, useRef, useState} from 'react';
import {FaLine} from "react-icons/fa";
import Header from "../components/header";
import FaqList from "../components/faqList";
import NoticeList from "../components/noticeList";
import {useSelector} from "react-redux";
import * as Utils from "../helper/utils";
import colors from "../styles/colors";

const HelpPage = () => {
  const helpPageRef = useRef(null);
  const {isNativeWebView} = useSelector(state => state.commState);
  const [dimensions, setDimensions] = useState({width: '', height: 760})

  const goOutLink = (url : string): void => {
    if(isNativeWebView) {
      Utils.openNativeUrl(url)
    }else{
      window.open(url, '_blank')
    }
  }
  const updateDimensions = () => {
    if (helpPageRef.current) {
      setDimensions({
        width: helpPageRef.current.offsetWidth,
        height: helpPageRef.current.offsetHeight,
      });
    }
  };

  useEffect(()=> {
    setTimeout(()=> {
      updateDimensions()
    }, 200)

  }, [])

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    window.addEventListener('load', updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener('load', updateDimensions);
    };
  }, []);

  return (
    <div className={'helpWrapper'} ref={helpPageRef}>
      <Header />
      <div className={'topTitleBox'}>
        <div className={'topTitle levelUnderBox'}>고객센터<span className={'topSubTitle'}>HELP</span></div>
        <div> </div>
      </div>
      <div className={'scrollWrapper'} style={{height: `${dimensions.height - (50 + 50 + 70) }px`}}>
        <div className={'helpChannelBox'}>
          <div className={'titText01'}>입점문의/배송지역/상품교환</div>
          <div className={'addLineFriendBtn activeBtn'} onClick={()=>goOutLink('https://lin.ee/KXpRgLz')}>
            <FaLine className={'icon'} size={29} color={colors.white}/>
            <div className={'text01'}>친구추가</div>
          </div>
        </div>
        <NoticeList />
        <FaqList />
        <div className={'helpCompanyInfoBox'}>
          <div className={'textBox'}>
            <div className={'text01'}>Thailand address</div>
            <div className={'text02'}>Townhouse at 15/18 (Banncharoensuk) Bannsuancharoenjai Village , Soi EKamai 12 Sukhumvit 63Rd, Klongton Nua , Wattana Bangkok 10110, Thailand</div>
          </div>
          <div className={'textBox'}>
            <div className={'text01'}>Korea address</div>
            <div className={'text02'}>서울특별시 구로구 디지털로 30길 31, 코오롱디지털타워 빌란트 2차 507호</div>
          </div>
        </div>
      </div>
    </div>
  );
};

HelpPage.propTypes = {}

export default HelpPage;

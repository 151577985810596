import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import "swiper/css/pagination";
import {BASE_IMAGES_URL} from "../../helper/config";


const ImageSlider = () => {
  SwiperCore.use([Autoplay]);
  return (
    <div className={'imageSliderWrapper'}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
        loop
        pagination
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
      >
        <SwiperSlide><div className={'imageItem'}><img src={BASE_IMAGES_URL+`banner01.png`} alt="banner_photo"/></div></SwiperSlide>
        <SwiperSlide><div className={'imageItem'}><img src={BASE_IMAGES_URL+`banner02.png`} alt="banner_photo"/></div></SwiperSlide>
        <SwiperSlide><div className={'imageItem'}><img src={BASE_IMAGES_URL+`banner03.png`} alt="banner_photo"/></div></SwiperSlide>
        <SwiperSlide><div className={'imageItem'}><img src={BASE_IMAGES_URL+`banner04.png`} alt="banner_photo"/></div></SwiperSlide>
        <SwiperSlide><div className={'imageItem'}><img src={BASE_IMAGES_URL+`banner05.png`} alt="banner_photo"/></div></SwiperSlide>
      </Swiper>
    </div>
  );
};
export default ImageSlider;

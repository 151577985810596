import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {searchProductData} from "../../redux/actions/productAction";
import {BiSearch} from "react-icons/bi";
import {RiCloseCircleFill} from 'react-icons/ri'
import colors from "../../styles/colors";

const Search = () => {

  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('');

  const onChange = (e) => {
    setSearchText(e.target.value);
  };

  const onReset = () => {
    setSearchText('')
    dispatch(searchProductData(''))
  }

  const onKeyPress = (e) => {
    if(e.key === 'Enter')  {
      onSearch()
    }
  }

  const onSearch = () => {
    // if(!searchText) {
    //   alert('검색어를 입력해주세요.')
    //   return
    // }

    dispatch(searchProductData(searchText))
  }

  useEffect(() => {
    onSearch()
  }, [searchText]);

  return (
    <div className={'searchWrapper'}>
      <div className={'searchInput'}>
        <input
          value={searchText}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={'검색어를 입력해주세요.'}
        />
      </div>
      {
        searchText !== '' &&
        <div className={'resetBtn'} onClick={onReset}>
          <RiCloseCircleFill size={22} color={colors.gray05}/>
        </div>
      }
      <div className={'searchBtn'} onClick={onSearch}>
        <BiSearch size={25} color={colors.gray05}/>
      </div>
    </div>
  );
};

Search.propTypes = {

};

export default Search;

import React from 'react';
import PropTypes from 'prop-types';
import * as Utils from "../../../../helper/utils";

const MemberItem = props => {
	const {item, goPage} = props

	const badgeView = () => {
		if(item.companyId) {
			if(item.isAuthCompany) {
				// 인증됨
				if(item.isReqGrade) {
					return(<div className={'smBadgeBtn activeRedBtn'}>등급요청</div>)
				}else{
					return <div> </div>
				}
			}else{
				// 인증요청
				return(<div className={'smBadgeBtn activeBtn'}>인증요청</div>)
			}
		}else{
			return(<div className={'smBadgeBtn defaultBtn'}>미인증</div>)
		}
	}

	return (
		<div className={'memberItemWrapper'} onClick={()=>goPage(item.userId)}>
			<div className={'lBox'}>
				<div className={'indexBox'}>{item.userId}</div>
				<div className={'contBox'}>
					<div className={`levelBox`} style={{color: `${Utils.levelTag(item.grade)}`}}>{item.grade ? item.grade : '-'}</div>
					<div className={'text02'}>{item.storeName}</div>
				</div>
			</div>
			<div className={'rBox'}>
				{badgeView()}
			</div>
		</div>
	);
};

MemberItem.propTypes = {
	item: PropTypes.object,
	goPage: PropTypes.func,
};

export default MemberItem;


/** ****************************************************************************************************
 * 사용데이터
 */

export const levelData = [
	// {id:1, levelTag: 'VVIP', fee: 16, price: 300000, value: 'VVIP', label: 'VVIP'},
	{id:2, levelTag: 'VIP', fee: 18, price: 450000, value: 'VIP', label: 'VIP'},
	{id:3, levelTag: 'GOLD', fee: 19, price: 360000, value: 'GOLD', label: 'GOLD'},
	{id:4, levelTag: 'SILVER', fee: 20, price: 270000, value: 'SILVER', label: 'SILVER'},
	{id:5, levelTag: 'BRONZE', fee: 21, price: 150000, value: 'BRONZE', label: 'BRONZE'},
	{id:6, levelTag: 'NEW', fee: 22, price: 0, value: 'NEW', label: 'NEW'},
]

export const CategoryList = [
	{id: 1, category: 'ALL', name_ko: '전체', name_th: '', name_en: '', value: 'ALL', label: '전체'},
	{id: 2, category: 'GR', name_ko: '녹색채소', name_th: '', name_en: '', value: 'GR', label: '녹색채소 (green vegetables)'},
	{id: 3, category: 'CV', name_ko: '컬러채소', name_th: '', name_en: '', value: 'CV', label: '컬러채소 (color vegetables)'},
	{id: 4, category: 'SP', name_ko: '매운', name_th: '', name_en: '', value: 'SP', label: '매운 (Spicy)'},
	{id: 5, category: 'ME', name_ko: '버섯', name_th: '', name_en: '', value: 'ME', label: '버섯 (mushroom)'},
	{id: 6, category: 'FU', name_ko: '과일', name_th: '', name_en: '', value: 'FU', label: '과일 (Fruit)'},
	{id: 7, category: 'MS', name_ko: '육류/해산물', name_th: '', name_en: '', value: 'MS', label: '육류/해산물 (meat/sead food)'},
	{id: 8, category: 'SA', name_ko: '양념', name_th: '', name_en: '', value: 'SA', label: '양념 (Sauce)'},
	{id: 9, category: 'MB', name_ko: '가공', name_th: '', name_en: '', value: 'MB', label: '가공 (อาหารแปรรูป)'},
	{id: 10, category: 'ET', name_ko: '부자재', name_th: '', name_en: '', value: 'ET', label: '부자재 (วัสดุห่อ)'},
]

export const faqListData = [
	{id:1, tagName: '서비스 이용', title: '마켓다우는 어떤 서비스인가요?', contents: '마켓다우는 식당업 및 급식사업을 하는 대표님들의 영업에 필요한 신선 식자재 주문받아 태국 최대 도매 시장에서 구매대행 및 배송서비스를 하고 있습니다. 대표님들의 소중한 시간과 비용을 절약해드리기 위해 매일 딸랏 타이 또는 방콕인근 대형 도매시장에서 신선한 야채와 해산물, 육류, 가공품을 구매하여 영업전 배송해드리는 서비스입니다.'},
	{id:2, tagName: '서비스 이용', title: '마켓다우 배송비는 왜 무료인가요?', contents: '배송비는 따로 받지 않습니다. 그 이유는 사장님들이 필요한 야채 및 제품을 매일 구매하는 일을 마켓다우가 대행을 해드리고 있습니다. 이에 따른 구매대행 수수료를 합리적인 책정하고(구매 합계금액에 따른 차등적용 #수수료정책 참고) 있으며 그 수수료에는 차량 운영비, 인건비 및 영업 이익등이 포함되어 배송료를 따로 받지 않습니다.'},
	{id:3, tagName: '배송지역', title: '배송지역은 어디까지 인가요?', contents: '마켓다우는 2023년을 서비스를 시작으로 아속을 중심부로(10km 이내) 시작하여 배송지를 점차 늘려나갈 계획입니다. 10km 내 배송 가능지역에 대한 자세한 문의는 라인 또는 고객센터로 문의하시기 바랍니다. 현 배송 가능지역 : 수쿰빗, 아속지역, 통로&에까마이지역, 사톤지역, 나라티왓지역, 시나카린지역, 람캄행 지역'},
	{id:4, tagName: '배송', title: '배송지역은 어디까지 인가요?', contents: '배송은 일반적으로 오전5시 ~ 9시30분 전까지 교통 상황에 배송이 순차적으로 이루어지며 어플을 통해 배송중 또는 배송완료를 확인 하실수 있습니다. 확인 방법 경로 어플 하단 주문내역내에서 상태 확인이 가능합니다.'},
	{id:5, tagName: '배송', title: '새벽에 매장 문이 닫혀 있어요? 배송이 어떻게 되나요?', contents: '영업장 건물에 형태에 따라 고객 요청에 따라 배송위치를 변경하고 있습니다. 분실에 취약한 경우 협의하에 매장내 배송이 가능하며 분실 우려가 없는곳은 매장앞 배송을 원칙으로 하고 있습니다. 배송의 상태는 어플 또는 웹사이트네 하단메뉴[주문내역] 날짜별 상품준비 - 배송중 - 배송완료로 확인후 직원이 사진을 찍어 보관할(7일)예정입니다. 최종 분실에 대한 책임지지 않습니다. 따라 협의시 본사와 잠금장치가 가능한 외부장소의 마련의 협의해 주시기 바랍니다.'},
	{id:6, tagName: '주문', title: '주문이 되지 않아요?', contents: '일반 회원으로 가입 - 우축 더보기 메뉴 - 왼쪽 메뉴 최상단 [사업자관리] 정보를 채우고 [인증요청]후 본사가 확인후 승인된후 모든 서비스를 이용할수 있습니다.'},
	{id:7, tagName: '입금', title: '입금은 어떻게 해야 하나요?', contents: '어플 상단우측 장바구니 아이콘을 클릭후 주문 완료후 [주문내역] 페이지로 자동 이동합니다. 화면 상단에 계좌번호 복사하여 모바일 뱅킹 입금후 [주문내역 상세정보] 우측상단 [입금증 등록] -[입급증 photo]고객은 모바일에 입금증 사진 및 캡쳐하여 주시면 [확인대기]로 변경되면 본사에서 주문을 최종확인합니다. 단 입금이 확인이 되지 않는경우 구매 물품이 준비 및 배송되지 않습니다. (입금확인 시간 오후2~10시:59분)'},
	{id:8, tagName: '입금', title: '입금 방법은 무엇이 있나요?', contents: '현재는 무통장 입금을 원칙으로하고 있으며 향후 신용카드(직불카드)로 지불할수 있도록 준비중에 있습니다.'},
	{id:9, tagName: '입금', title: '몇시까지 입금을 해야 하나요?', contents: '입금은 오후 10시 59분까지 확인 되어야 합니다. 긴급 상황시 라인[친구추가] 고객센터 이용바랍니다.'},
	{id:10, tagName: '등급관련', title: '수수료  등급의 차이는 어떤 이유인가요?', contents: '마켓다우는 고객님의 식자구매에 소요되는 교통비용 및 시간을 절약해 드리는 서비입니다. 본 서비스를 대행함으로 마켓다우는 도매 시장을 매일 방문 구매활동에 따른 비용이 발생하며 그 만큼의 차량운행과 인력이 추가됩니다. 따라 대량 구매를 하시는 분에게 마켓다우에 운영에 도움을 줌으로 더욱 저렴하게 수수료를 제공할수 있습니다. 단 소량의 주문 고객에게도 서비스를 제공함도 중요하기에 직접 구매를 통한 차량운행 및 시간소요을 대신하여 합리적인수수료를 받고 있습니다.'},
	{id:11, tagName: '등급관련', title: '수수료 등급 요청이란?', contents: '실시간으로 고객님의 앱 또는 웹사이트 우측상단 장바구니 모양 오픈쪽 더보기 메뉴버튼 누르기 - 완쪽 [수수료안내] 에서 확인할수 있으며, 3개월 기준 또는 총 구매금액 기준으로 누적하여 합산을 보실수 있습니다. 고객님이 수시로 [3개월간 구매총합] 확인하며 3개월간 구매총합이 등급 기준을 넘겼을 경우 [등급조정 요청] 버튼을 통하여 상위 단계의 수수료를 요구할수 있습니다. 익일 오후 2시 이후에 수수료가 반영되어 계산이 됩니다.'},
	{id:12, tagName: '교환/환불', title: '재료가 신선하지 않아요?', contents: '야채는 공산품이 아니므로 포장 및 묶음의 형태에 따라 신선도 확인이 불가능한 경우가 있습니다. 이에 영업에 지장에 없도록 고객센터에 요청 하시면 당일 교환 원칙으로 기준으로 하고 있습니다. 단 문제가 있는 제품은 배송편 기사에게 전달해 주시면됩니다.'},
]


export const payTypeData = [
	{id:1, value: 'CASH', label: '[현금] CASH', desc: '현금'},
	{id:2, value: 'CREDIT', label: '[신용] CREDIT ', desc: '신용'},
	{id:3, value: 'CARD', label: '[카드] CARD', desc: '카드'},
]


export const UnitNameData = [
	{id:1, value: 'kg', label: 'kg', desc: ''},
	{id:2, value: 'ea', label: 'ea', desc: ''},
	{id:3, value: 'pack', label: 'pack', desc: ''},
]

export const UnitCountData = [
	{id:1, value: '0.5', label: '0.5', desc: ''},
	{id:2, value: '1', label: '1', desc: ''},
]




/** ****************************************************************************************************
 * 개발을 위함 임시 데이터
 */

export const tempWorkOrderData = [
	{id: 1, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S001', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 12},
	{id: 2, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S002', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 10},
	{id: 3, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S003', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 2},
	{id: 4, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S004', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 4},
	{id: 5, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S005', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 6},
	{id: 6, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S006', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 10},
	{id: 7, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S007', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 3},
	{id: 8, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S008', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 1},
	{id: 9, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S010', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 3},
	{id: 10, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S011', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 10},
	{id: 11, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S012', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 16},
	{id: 12, code: 'GR015', supplyStoreName: '키다리야채가게', shippingName: 'S013', nameKo: '양배추', nameTh: 'ผักกาดขาว', nameEn: '', unit: '1', unitName: 'kg', count: 13},
]

export const tempProduct = [
  {id: 1, nameKo: '배추', nameTh: 'ผักกาดขาว', nameEn: '', photo: '', unit: 1, price: '500', count: 0},
  {id: 2, nameKo: '양배추', nameTh: 'กะหล่ำปลี', nameEn: '', photo: '', unit: 1, price: '200', count: 0},
  {id: 3, nameKo: '오이', nameTh: 'แตงกวา', nameEn: '', photo: '', unit: 1, price: '50', count: 0},
  {id: 4, nameKo: '당근', nameTh: 'แครอท', nameEn: '', photo: '', unit: 1, price: '150', count: 0},
  {id: 5, nameKo: '고추', nameTh: 'พริก', nameEn: '', photo: '', unit: 0.1, price: '30', count: 0},
  {id: 6, nameKo: '배추2', nameTh: 'ผักกาดขาว', nameEn: '', photo: '', unit: 1, price: '500', count: 0},
  {id: 7, nameKo: '양배추2', nameTh: 'กะหล่ำปลี', nameEn: '', photo: '', unit: 1, price: '200', count: 0},
  {id: 8, nameKo: '오이2', nameTh: 'แตงกวา', nameEn: '', photo: '', unit: 1, price: '50', count: 0},
  {id: 9, nameKo: '당근2', nameTh: 'แครอท', nameEn: '', photo: '', unit: 1, price: '150', count: 0},
  {id: 10, nameKo: '고추2', nameTh: 'พริก', nameEn: '', photo: '', unit: 1, price: '30', count: 0},
  {id: 11, nameKo: '배추3', nameTh: 'ผักกาดขาว', nameEn: '', photo: '', unit: 1, price: '500', count: 0},
  {id: 12, nameKo: '양배추3', nameTh: 'กะหล่ำปลี', nameEn: '', photo: '', unit: 1, price: '200', count: 0},
  {id: 13, nameKo: '오이3', nameTh: 'แตงกวา', nameEn: '', photo: '', unit: 1, price: '50', count: 0},
  {id: 14, nameKo: '당근3', nameTh: 'แครอท', nameEn: '', photo: '', unit: 1, price: '150', count: 0},
  {id: 15, nameKo: '고추3', nameTh: 'พริก', nameEn: '', photo: '', unit: 1, price: '30', count: 0},
]

export const tempOrder = [
  {id: 1, deliveryDate: '2022-07-08', deliveryStatus: 'ING', payStatus: 'DONE', payType: 'BANK', orderPrice: '32500', productList: [] },
  {id: 2, deliveryDate: '2022-07-09', deliveryStatus: 'ING', payStatus: 'DONE', payType: 'BANK', orderPrice: '55000', productList: []},
  {id: 3, deliveryDate: '2022-07-10', deliveryStatus: 'STAY', payStatus: 'STAY', payType: 'BANK', orderPrice: '12500', productList: []},
  {id: 4, deliveryDate: '2022-07-11', deliveryStatus: 'DONE', payStatus: 'DONE', payType: 'BANK', orderPrice: '32500', productList: []},
  {id: 5, deliveryDate: '2022-07-12', deliveryStatus: 'DONE', payStatus: 'DONE', payType: 'BANK', orderPrice: '25550', productList: []},
]

export const tempDeliveryData = []

export const tempRecentlyProductList = [
	{id: 1, category: 'SP', name_ko: '고추(홍)', price: 100, photo: 'sp002.png', unit: '0.5', unitName: 'kg'},
	{id: 2, category: 'GR', name_ko: '청경채', price: 50, photo: 'gr007.png', unit: '0.5', unitName: 'kg'},
	{id: 3, category: 'CV', name_ko: '당근', price: 27, photo: 'cv003.png', unit: '1', unitName: 'kg'},
	{id: 4, category: 'CV', name_ko: '양배추(보라)', price: 40, photo: 'cv006.png', unit: '1', unitName: 'kg'},
	{id: 5, category: 'ME', name_ko: '버섯(새송이)', price: 60, photo: 'me001.png', unit: '0.5', unitName: 'kg'},
]

// 고추(홍), 청경채, 당근, 양배추(보라), 버섯(새송이)

export const tempPartnerList = [
	{id: 1, category: 'ALL', name_ko: '전체', name_th: '', photo: '011.png', companyName: '산내들'},
	{id: 2, category: 'GR', name_ko: '녹색채소', name_th: '', photo: '021.png', companyName: 'OK BBQ'},
	{id: 3, category: 'CV', name_ko: '컬러채소', name_th: '', photo: '031.png', companyName: 'KIM`S BBQ'},
	{id: 4, category: 'MB', name_ko: '가공', name_th: '', photo: '041.png', companyName: '고기세끼'},
	// {id: 5, category: 'ME', name_ko: '버섯', name_th: '', photo: '051.png', companyName: '곱창집'},
]

export const proList = [
	{id: 1, code: 'A001' ,nameKo: '배추', nameTh: 'ผักกาดขาว', nameEn: '', photo: '', unit: 1, price: '500', count: 7},
	{id: 2, code: 'A002' ,nameKo: '양배추', nameTh: 'กะหล่ำปลี', nameEn: '', photo: '', unit: 1, price: '200', count: 10},
	{id: 3, code: 'A003' ,nameKo: '오이', nameTh: 'แตงกวา', nameEn: '', photo: '', unit: 1, price: '50', count: 3},
	{id: 13, code: 'A004' ,nameKo: '오이2', nameTh: 'แตงกวา', nameEn: '', photo: '', unit: 1, price: '150', count: 3},
]

export const orderData = {
	date: '2022-07-07',
	totalPrice: 34000,
}

export const tempPackingData = [
	{id: 1, code: 'GR015', nameKo: '배추', nameTh: 'ผักกาดขาว', nameEn: '', photo: '', unit: 1, deliveryDate: '2022-08-16'},
	{id: 2, code: 'GR015', nameKo: '양배추', nameTh: 'กะหล่ำปลี', nameEn: '', photo: '', unit: 1, deliveryDate: '2022-08-16'},
	{id: 3, code: 'GR015', nameKo: '오이', nameTh: 'แตงกวา', nameEn: '', photo: '', unit: 1, deliveryDate: '2022-08-16'},
	{id: 4, code: 'GR015', nameKo: '당근', nameTh: 'แครอท', nameEn: '', photo: '', unit: 1, deliveryDate: '2022-08-16'},
	{id: 5, code: 'GR015', nameKo: '고추', nameTh: 'พริก', nameEn: '', photo: '', unit: 0.1, deliveryDate: '2022-08-16'},
]


export const tempMemberManageData = [
	{userId: 1, storeName: '홍이반찬가게', grade: 'NEW', deliveryZone: 'SIL', tel: '1111-2222', phone: '1111-2222', level: '', isAuthCompany: false, companyId: 1},
	{userId: 2, storeName: '순대국맛집', grade: 'GOLD', deliveryZone: 'SIL', tel: '1111-2222', phone: '1111-2222', level: '', isAuthCompany: true, companyId: 1},
	{userId: 3, storeName: '누이네삼겹살', grade: 'VIP', deliveryZone: 'SIL', tel: '1111-2222', phone: '1111-2222', level: '', isAuthCompany: true, companyId: 1},
	{userId: 4, storeName: '', grade: 'NEW', deliveryZone: 'SIL', tel: '1111-2222', phone: '1111-2222', level: '', isAuthCompany: false, companyId: null},
	{userId: 257, storeName: '엄마백반', grade: 'GOLD', deliveryZone: 'SIL', tel: '1111-2222', phone: '1111-2222', level: '', isAuthCompany: true, companyId: 1},
	{userId: 746, storeName: '', grade: 'NEW', deliveryZone: 'SIL', tel: '1111-2222', phone: '1111-2222', level: '', isAuthCompany: false, companyId: null},
]


export const tempProductManageData = [
	{id: 1, nameKo: '배추', nameTh: 'ผักกาดขาว', nameEn: '', photo: '', unit: 1, price: '500', count: 0},
]


export const tempNoticeData = [
	{b_id:1, title: '2022년 11월 마켓다우 오픈기념 마켓다우 오픈기념 마켓다우 오픈기념', focus_yn: 'N'},
	{b_id:2, title: '2022년 11월 마켓다우 오픈기념 마켓다우 오픈기념 마켓다우 오픈기념', focus_yn: 'N'},
	{b_id:3, title: '2022년 11월 마켓다우 오픈기념 마켓다우 오픈기념', focus_yn: 'N'},
	{b_id:4, title: '2022년 11월 마켓다우 오픈기념 마켓다우 오픈기념', focus_yn: 'N'},
	{b_id:5, title: '2022년 11월 마켓다우 오픈기념 마켓다우 오픈기념', focus_yn: 'N'},
]

export const tempDeliveryZoneData = [
	{dzId:1 , value:'1', zoneCode: 'SIL', label: '사톤, 실롬, 살라댕', zoneNameKo: '사톤, 실롬, 살라댕', zoneNameEn: 'Sathorn, Silom, Bang Rak, Surawong', zoneNameTh: ''},
	{dzId:2 , value:'2', zoneCode: 'NAT', label: '나라티왓, 팔람3', zoneNameKo: '나라티왓, 팔람3', zoneNameEn: 'Suan Phloo, Yen Akard, Narathiwas, Chan', zoneNameTh: ''},
	{dzId:3 , value:'3', zoneCode: 'RA4', label: '팔람4, 크렁떠이,룸피니,후알랑폼', zoneNameKo: '팔람4, 크렁떠이,룸피니,후알랑폼', zoneNameEn: 'annawa, Charoenkrung, Rama 3, Rama 4, Klong Toey', zoneNameTh: ''},
	{dzId:4 , value:'4', zoneCode: 'RA4', label: '팔람4, 크렁떠이,룸피니,후알랑폼', zoneNameKo: '팔람4, 크렁떠이,룸피니,후알랑폼', zoneNameEn: 'annawa, Charoenkrung, Rama 3, Rama 4, Klong Toey', zoneNameTh: ''},
	{dzId:5 , value:'5', zoneCode: 'RA4', label: '팔람4, 크렁떠이,룸피니,후알랑폼', zoneNameKo: '팔람4, 크렁떠이,룸피니,후알랑폼', zoneNameEn: 'annawa, Charoenkrung, Rama 3, Rama 4, Klong Toey', zoneNameTh: ''},
	{dzId:6 , value:'6', zoneCode: 'RA4', label: '팔람4, 크렁떠이,룸피니,후알랑폼', zoneNameKo: '팔람4, 크렁떠이,룸피니,후알랑폼', zoneNameEn: 'annawa, Charoenkrung, Rama 3, Rama 4, Klong Toey', zoneNameTh: ''},
]

export const tempSupplyStoreData = [
	{ss_id: 1, value: '1', label: '배추가게', storeCode:'', product: ''},
	{ss_id: 2, value: '2', label: '왕상추가게', storeCode:'', product: ''},
	{ss_id: 3, value: '3', label: '버섯가게', storeCode:'', product: ''},
]




import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {HiArrowLeft, HiShoppingCart} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import {setCartItems} from "../redux/actions/cartAction";
import {setAlertMessage} from "../redux/actions/commAction";
import Loader from "../components/Loader";
import * as Utils from "../helper/utils";
import * as Api from "../helper/api";
import moment from 'moment';
import PhotoUpLoadModal from "../components/modal/photoUploadModal";
import {ProductVo} from "../helper/vo";



const OrderDetailPage = props => {
	let {id} = useParams();
	const orderDetailRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const {product} = useSelector(state => state.productState);
	const [dimensions, setDimensions] = useState({width: '', height: 450})
	const [isLoaded, setIsLoaded] = useState(false);
	const [orderedProducts, setOrderedProducts] = useState([]);
	const [isOpenPhoto, setIsOpenPhoto] = useState(false);
	const [orderInfo, setOrderInfo] = useState({
		orderDate: '',
		deliveryDate: '',
		deliveryStatus: 'STAY',
		orderStatus: 'Y',
		productCount: 0,
		totalPrice: 0,
		accountPhoto: '',
		payStatus: 'STAY',
		payType: 'CASH',
		productPrice: 0,
		feePrice: 0,
		feeRate: 0,
	});


	const successAccountReceipt = (file: any) => {
		setAccountReceipt(id, file)
	}

	const orderCancel = async (orderId) : void => {
		try {
			const resData = await Api.cancelOrder_API(orderId)
			if (!resData.data) return
			const { status } = resData.data
			if(status) {
				// DB를 새로 불러 오는것 보다 불러온 데이터만 바꾼다.
				setOrderInfo({...orderInfo, orderStatus: 'N'})
				dispatch(setAlertMessage({isShow: true, message: '주문 취소완료'}))
			}
		}catch (e) {
			// console.log('setCheck error', e)
		}
	}

	const reOrderIntoCart = () => {
		/**
		 * 상세에서 주문시 이미 데이터가 준비 되어 있는데
		 * 목록에서는 주문내역이 없네;;
		 * 일단. 데이터를 카트에 넣어주고 카트로 페이지 옮김
		 *
		 * 서버에서 데이터를 만들어서 만들어진 데이터를 리턴할까나..
		 *
		 *
		 * 상품 가격 동기화를 위한
		 * 상품 가격을 가져와서 주문과 일치 시킨다.
		 * */
		const {initData} = product

		// console.log('orderedProducts >>>', orderedProducts)

		const reOrderData = orderedProducts.length > 0 && orderedProducts.map(item => {

			const aa = initData.length > 0 && initData.find(p => {
				return p.code === item.code
			})

			item.price = aa ? Number(aa.price) : 0; // 현재 판매상품의 시세를 반영
			item.isBuying = !!aa;  // 재구매 가능한 상품인지 체크
			item.photo = aa ? aa.photo : ''

			return item
		})

		// console.log('reOrderData >>>', reOrderData)

		dispatch(setCartItems(reOrderData))
		navigate('/products/cart')
	}

	const getOrderDetailData = async () : void => {
		setIsLoaded(false)
		try {
			const resData = await Api.getOrder_API(id)
			// console.log('resData >>>', resData)
			if (!resData.data) return
			const { data, status } = resData.data

			if(status) {
				const { productList,total_price,delivery_dt, delivery_status,
					product_price, fee_rate, fee_price, pay_type, pay_status,
					order_status, account_photo, created_dt
				} = data

				setOrderInfo({
					orderDate: moment(created_dt).format('YYYY.MM.DD HH:mm'),
					deliveryDate: moment(delivery_dt).format('YYYY.MM.DD'),
					deliveryStatus: delivery_status,
					orderStatus: order_status,
					productCount: productList.length > 0 ? data.productList.length : 0,
					accountPhoto: account_photo,
					productPrice: product_price ? Number(product_price) : 0,
					feeRate: fee_rate ? fee_rate : '-',
					feePrice: fee_price ? Number(fee_price) : 0,
					totalPrice: total_price ? Number(total_price) : 0,
					payType: pay_type || 'CASH',
					payStatus: pay_status || 'STAY'
				})

				const orderProductItems = productList.length > 0 ? productList.map( item => {
					const tempObj:ProductVo = {}
					tempObj.id = Number(item.id)
					tempObj.code = item.code
					tempObj.category = item.category
					tempObj.nameKo = item.name_ko
					tempObj.nameTh = item.name_th
					tempObj.nameEn = item.name_en
					tempObj.desc = item.desc
					tempObj.price = Number(item.price)
					tempObj.unit = parseFloat(item.unit)
					tempObj.unitName = item.unit_name
					tempObj.count = Number(item.cnt)
					return tempObj
					}) : []

				setOrderedProducts(orderProductItems)
			}

		}catch (e) {

		}finally {
			setTimeout(()=> {
				setIsLoaded(true)
			}, 300)
		}
	}

	const setAccountReceipt = async (id:number, file:any): void => {
		try {
			const resData = await Api.setAccountPhoto_API(id, file)
			// console.log('res >>>', resData)
		}catch (e) {
			// console.log('e', e)
		}
	}


	const updateDimensions = () => {
		if (orderDetailRef.current) {

			setDimensions({
				width: orderDetailRef.current.offsetWidth,
				height: orderDetailRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	useEffect(()=> {
		updateDimensions()
	}, [orderedProducts])

	useEffect(() => {
		window.scrollTo(0, 0)
		getOrderDetailData()
	}, []);


	return (
		<div className={'orderDetailWrapper'} ref={orderDetailRef}>
			<div className={'herderBtnBox'}>
				<div className={'goBackBtn'} onClick={() => navigate('/order')}><HiArrowLeft size={25}/></div>
				<div className={'titleText'}> </div>
			</div>
			<div className={'topTitleBox'}>
				<div className={'topTitle orderDtUnderBox'}>주문내역 상세정보<span className={'topSubTitle'}>LEVEL/FEE</span></div>
				<div className={'accountUploadBtn'} onClick={()=>setIsOpenPhoto(true)}>{orderInfo.accountPhoto ? '확인대기': '입금증 등록'}</div>
			</div>
			<div className={'orderInfoWrapper'}>
				<div className={'orderInfoBox'}>
					<div className={'orderInfoText01'}><div>배송일 วันส่งสินค้า</div><div>{orderInfo.deliveryDate}</div></div>
					<div className={'orderInfoText01'}><div>주문일 วันที่สั่งซื้อ</div><div>{orderInfo.orderDate}</div></div>
					<div className={'orderInfoText01'}><div>품목 แพคเกจ</div><div>{orderInfo.productCount} ea</div></div>
					{/*<div className={'orderInfoText01'}><div>수수요률</div><div>{orderInfo.feeRate} %</div></div>*/}
					<div className={'orderInfoText01'}><div>상품금액</div><div>{Utils.numberFormat(orderInfo.productPrice)} THB</div></div>
					<div className={'orderInfoText01'}><div>수수료 <span className={'feeRateSpan'}>{orderInfo.feeRate} %</span></div><div>{Utils.numberFormat(orderInfo.feePrice)} THB</div></div>
					<div className={'orderInfoText01'}><div>주문총액</div><div>{Utils.numberFormat(orderInfo.totalPrice)} THB</div></div>
				</div>
			</div>
			<div className={'pastProList scrollWrapper'} style={{height: `${dimensions.height - 180 - 200}px`}}>
				<div className={'tit'}>주문상품 목록</div>
				{
					orderedProducts && orderedProducts.length > 0 ? orderedProducts.map( (item, index) => {
						return (
							<div className={'pastProItem'} key={item.id}>
								<div className={'box01'}>
									<div className={'text01 textBold'}>
										<span>{index + 1}</span>{item.nameKo}
									</div>
									<div className={'text02'}>{item.nameTh}</div>
								</div>
								<div className={'box02'}>
									<span className={'span01'}>( {item.unit} {item.unitName} x {item.count} )</span>
									<span className={'span02'}>{item.unit * item.count}</span>
									<span className={'span01'}>{item.unitName}</span></div>
							</div>
						)
					}) : null
				}
			</div>
			<div className={'fullBottomBox'}>
				{
					(orderInfo.orderStatus === 'Y' && orderInfo.deliveryStatus === 'STAY') &&
					<div className={'cancelOrderBtn'} onClick={()=>orderCancel(Number(id))}>
						<span>주문취소</span>
						<span className={'text02'}>ยกเลิกการซื้อ</span>
					</div>
				}
				<div className={'reOrderBtn'} onClick={reOrderIntoCart}>
					<span>
						{/*<HiShoppingCart size={17}/>*/}
						재주문
					</span>
					<span className={'text02'}>สั่งซื้ออีกครั้ง</span>
				</div>
			</div>
			<Loader loading={!isLoaded}/>
			<PhotoUpLoadModal
				isOpen={isOpenPhoto}
				setIsOpen={setIsOpenPhoto}
				title={'입금증'}
				viewType={'ACCOUNT'}
				success={successAccountReceipt}
				oldData={orderInfo ? orderInfo.accountPhoto : ''}
			/>
		</div>
	);
};

OrderDetailPage.propTypes = {};

export default OrderDetailPage;

import React from 'react';
import PropTypes from 'prop-types';
import * as Utils from "../../../../helper/utils";


const ManageProductItem = props => {
	const {item, goPage} = props
	const diffHour : number | null = Utils.diffDayDate(item.updateDate)
	const isUpdate : boolean = diffHour && diffHour > 2
	return (
		<div className={`manageProductItemWrapper ${!item.isSell ? 'manageProductItemDisable' : ''}`} onClick={()=>goPage(item.productId)}>
			<div className={'leftBox'}>
				<div className={'colBox'}><span className={`${item.isMarket ? 'marketPriceBox': ''}`}>{item.productId}</span></div>
				<div>
					<div><span className={'titText'}>{item.nameKo}</span><span className={'subText ml10'}>{item.nameTh}</span></div>
					<div className={'subText'}>{item.desc}</div>
				</div>
			</div>
			<div className={'rightBox'}>
				<span className={'recentlyText'}>{item.recentlyPrice > 0 ? item.recentlyPrice : ''}</span>
				<span className={'priceText01'}>{item.price}</span><span className={'priceText02'}>THB</span>
				<span className={'updateCheckText'}>{diffHour}일 전</span>
				{isUpdate ? <span className={'updateCheckDot'}></span> : null}
				{/*{item.isMarket ? <span className={'marketPriceBox'}>시세</span> : ''}*/}
			</div>
		</div>
	);
};

ManageProductItem.propTypes = {
	item: PropTypes.object,
	goPage: PropTypes.func,
};

export default ManageProductItem;

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import CartItem from "./cartItem";
import {minusCartItem, plusCartItem, removeCartItem} from "../../redux/actions/cartAction";
import {setIsOpenSide, setTabState} from "../../redux/actions/commAction";
import {useNavigate} from "react-router-dom";


const CartList = props => {
	const {updateDimensions, height} = props
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const {cartList} = useSelector(state => state.cartState);

	const goPage = (routeName = '/', tabName: string = '') => {
		dispatch(setIsOpenSide(false))
		navigate(routeName)
		tabName && dispatch(setTabState(tabName))
	}

	const removeItem = (code) => {
		dispatch(removeCartItem(code))
	}

	const minusCount = (code) => {
		dispatch(minusCartItem(code))
	}

	const plusCount = (code) => {
		dispatch(plusCartItem(code))
	}

	useEffect(() => {
		updateDimensions()
	}, [cartList]);

	return (
		<div className={'cartListWrapper scrollWrapper'} style={{height: `${height}px`}}>
			{
				cartList && cartList.length > 0 ? cartList.map(item => {
					return(
						<CartItem
							key={item.productId}
							item={item}
							removeItem={()=>removeItem(item.productId)}
							minusCount={()=>minusCount(item.productId)}
							plusCount={()=>plusCount(item.productId)}
						/>
					)
				})
					: <div className={'noList'}>장바구니 텅</div>
			}
			<div className={'moreProductText'} onClick={() => goPage('/products', 'PRODUCT')}> + 상품추가하기</div>
		</div>
	);
};

CartList.propTypes = {
	updateDimensions: PropTypes.func,
	height: PropTypes.number
};

export default CartList;

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {HiArrowLeft} from "react-icons/hi";
import moment from "moment";
import * as Api from "../../helper/api";
import * as Utils from "../../helper/utils";
import {PackingInfoDtVo, PackingStoreDtVo} from "../../helper/vo";
import Loader from "../../components/Loader";
import RegBuyingPriceModal from "../../components/modal/regBuyingPriceModal";
import SupplyStoreSelectModal from "../../components/modal/supplyStoreSelectModal";
import PhotoUpLoadModal from "../../components/modal/photoUploadModal";
import {IMG_RECEIPT_100_URL} from "../../helper/config";


const WorkPackingDetailPage = props => {
	const {isOpen, setIsOpen, paId, code, date, pageCallback} = props
	const workPackingDetailRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [board, setBoard] = useState('');
	const [data, setData] = useState(null);
	const [storeList, setStoreList] = useState([]);
	const [isCopied, setIsCopied] = useState(false);
	const [dimensions, setDimensions] = useState({width: '', height: 1000})
	const [isOpenRegPrice, setIsOpenRegPrice] = useState(false);
	const [isOpenSupply, setIsOpenSupply] = useState(false);
	const [isOpenPhoto, setIsOpenPhoto] = useState(false);
	const [unitPrice, setUnitPrice] = useState(0);

	const setCopyText = (data, storeList): string => {
		let copyText = '';

		copyText = copyText + `${data.deliveryDate}\n${data.nameTh} (${data.nameKo}) \n-------------------------------\n \n`

		if (storeList && storeList.length > 0) {
			// eslint-disable-next-line array-callback-return
			storeList.map(item => {
				copyText = copyText + `${item.deliveryZone}(${item.userId}) - ${item.unit * item.orderCount} ${item.unitName}\n${item.storeName}\n \n`
			})
		}

		return copyText
	}

	const isCheckBuying = (data) : boolean => {
		if(!data) return false
		const {buyingPrice} = data
		return buyingPrice > 0
	}

	const pageGoBack = () => {
		// 페이지 나가기 전에 데이터 초기화
		setData(null)
		setStoreList([])
		setIsOpen(false)

		// 구매가격이 수정되었다면
		// 콜백으로 수정된 가격을 넘겨 리스트의 데이터를 변경한다.
		// 가격이 있으면 구입완료한것으로 판단
		if(isCheckBuying(data)) {
			pageCallback({paId: data.paId, price: data.buyingPrice})
		}
	}

	const successBuyingPrice = (price: number) => {
		setPriceData(paId, price)
	}

	const successSupplyStore = (supplyId: number) => {
		setSupplyStoreData(paId, supplyId)
	}

	const successReceipt = (file: any) => {
		setReceiptData(paId, file)
	}


	// 공급처 선택
	const setSupplyStoreData = async (paId, supplyId): void => {

		try {
			const resData = await Api.setSupplyStore_API(paId, supplyId)
			// console.log('setSupplyStoreData res', resData)
			if (!resData.data) return

			const {status} = resData.data

			if (status) getData()

		} catch (e) {
			// console.log('e', e)
		}
	}


	// 구매가격등록
	const setPriceData = async (id, price: number): void => {
		try {
			const resData = await Api.setBuyingPrice_API(id, price)

			if (!resData.data) return

			const {status} = resData.data

			if (status) getData()

		} catch (e) {
			// console.log('e', e)
		}
	}

	// 구매영수증 등록
	const setReceiptData = async (id:number, file:any): void => {
		try{
			const resData = await Api.setReceipt_API(id, file)
			// console.log('setReceiptData res >>>>', resData)
			if (!resData.data) return

			const {status} = resData.data

			if (status) getData()

		}catch (e) {
			// console.log('e', e)
		}
	}

	const getData = async (): void => {
		setIsLoaded(false)
		try {
			const resData = await Api.getPackingOrderDetail_API(paId, code, date)
			// console.log('PackingOrderDetail resData >>>>>', resData)
			if (!resData.data) return
			const {data, status} = resData.data
			const {packingInfo, packingStoreList} = data

			if (status) {
				// 패킹 기본정보
				const infoData: PackingInfoDtVo = {}
				infoData.paId = Number(packingInfo.pa_id)
				infoData.deliveryDate = packingInfo.delivery_dt && moment(packingInfo.delivery_dt).format('YYYY.MM.DD')
				infoData.nameKo = packingInfo.name_ko
				infoData.nameTh = packingInfo.name_th
				infoData.nameEn = packingInfo.name_en
				infoData.buyingPrice = Number(packingInfo.buy_price)
				infoData.orderCount = Number(packingInfo.order_cnt)
				infoData.ssId = Number(packingInfo.ss_id)
				infoData.supplyStore = packingInfo.store_name
				infoData.buyingPhoto = packingInfo.receipt_photo


				setData(infoData)

				if(infoData.buyingPrice){
					console.log('111', infoData.buyingPrice)
					console.log('222', infoData.orderCount)
					console.log('333', Math.ceil(infoData.buyingPrice / infoData.orderCount))
					setUnitPrice(Math.ceil(infoData.buyingPrice / infoData.orderCount))
				}

				// 업체별 패칭요청 목록
				const resultData = packingStoreList && packingStoreList.length > 0 ? packingStoreList.map(item => {
					const tempObj: PackingStoreDtVo = {}
					tempObj.id = item.id
					tempObj.userId = item.user_id
					tempObj.orderCount = item.order_cnt && Number(item.order_cnt)
					tempObj.code = item.code
					tempObj.deliveryId = Number(item.dz_id)
					tempObj.deliveryDate = moment(item.delivery_dt).format('YYYY-MM-DD')
					tempObj.deliveryZone = item.delivery_zone
					tempObj.nameKo = item.name_ko
					tempObj.nameTh = item.name_th
					tempObj.nameEn = item.name_en
					tempObj.unit = item.unit && Number(item.unit)
					tempObj.unitName = item.unit_name
					tempObj.storeName = item.store

					return tempObj
				}) : []

				setStoreList(resultData)
			}

		} catch (e) {

		} finally {
			setTimeout(() => {
				setIsLoaded(true)
			}, 300)
			updateDimensions()
		}
	}

	const initPage = () => {
		getData()
	}

	useEffect(() => {
		const text = data ? setCopyText(data, storeList) : ''
		setBoard(text)
	}, [data, storeList])

	useEffect(()=>{
		if(isCopied) {
			setTimeout(()=> {
				setIsCopied(false)
			}, 1500)
		}
	}, [isCopied])

	const updateDimensions = () => {
		if (workPackingDetailRef.current) {
			setDimensions({
				width: workPackingDetailRef.current.offsetWidth,
				height: workPackingDetailRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<SlidingPane
			className="some-custom-class"
			overlayClassName="some-custom-overlay-class"
			isOpen={isOpen}
			width={'100%'}
			hideHeader={true}
			onRequestClose={() => {
				// triggered on "<" on left top click or on outside click
				// setIsOpen(false);
				pageGoBack()
			}}
			onAfterOpen={initPage}
		>
			<div className={'workOrderDtWrapper'} ref={workPackingDetailRef}>
				<div className={'herderBtnBox'}>
					<div className={'goBackBtn'} onClick={pageGoBack}><HiArrowLeft size={25}/></div>
					<div className={'titleText titleFont'}>{data && data.nameTh} ({data && data.nameKo})</div>
				</div>
				{/*<div className={'topTitleBox'}>*/}
				{/*	<div className={'topTitle packDtUnderBox'}>상품준비 상세정보<span className={'topSubTitle'}>PACKING DETAIL</span></div>*/}
				{/*	<div className={'fontSize20 textBold'}></div>*/}
				{/*</div>*/}
				<div className={'infoBox'}>
					<div className={'textBox'}>
						<div className={'text01'}><span className={'subText777'}>วันส่งสินค้า (배송일)</span><span
							className={''}>{data && data.deliveryDate}</span></div>
						<div className={'text01'}><span className={'subText777'}>ชื่อสินค้า (상품명)</span><span
							className={''}>{data && data.nameKo} <span className={'textThi'}>{data && data.nameTh}</span></span></div>
						{/*<div className={'text01'}><span className={'subText777'}>ผู้ผลิต (공급처)</span><span className={''}> </span></div>*/}
					</div>
				</div>
				<div className={'manageBox'}>
					<div className={'sectionBox'}>
						<div className={'conTit'}>ผู้ผลิต (공급처)</div>
						<div className={'conBtn'}
								 onClick={() => setIsOpenSupply(true)}>{data && data.ssId ? `${data && data.supplyStore}` : `กด`}</div>
					</div>
					<div className={'sectionBox'}>
						<div className={'conTit'}>รายได้ (총 구입금액) <span className={'span01'}>[단가] : {unitPrice > 0 ? Utils.numberFormat(unitPrice) : 0} THB</span></div>
						<div className={'conBtn'} onClick={() => setIsOpenRegPrice(true)}>
							{data && data.buyingPrice ? `${data && Utils.numberFormat(data.buyingPrice)} THB` : 'กด'}
						</div>
						{/*<div className={'buttonBtn activeBtn'} onClick={()=>setIsOpenRegPrice(true)}>ตรวจสอบ</div>*/}
					</div>
					<div className={'sectionBox'}>
						<div className={'conTit'}>ใบเสร็จ ใบกำกับภาษี (영수증)</div>
						<div onClick={() => setIsOpenPhoto(true)}>
							{
								data && data.buyingPhoto
									? <div className={'imgView'}><img src={IMG_RECEIPT_100_URL+`${data.buyingPhoto }`} alt=""/></div>
									: <div className={'conBtn'}>กด</div>
							}
						</div>
						{/*<div style={{flex: 1}}>*/}
						{/*	<ReceiptPhotoUpload />*/}
						{/*</div>*/}
					</div>
				</div>
				<div className={'packingDtListBox scrollWrapper'}
					 style={{height: `${dimensions.height - 50 - 90 - 170 - 65}px`}}>
					{
						storeList && storeList.length > 0 && storeList.map((item, index) => {
							return (
								<div className={'packingDtItem'} key={item.id}>
									<div>
										<span className={'spanText01'}>{index + 1}</span>
										<span className={'spanText02'}>{item.deliveryZone}_{item.userId} </span>
										<span className={'spanText03'}>{item.storeName}</span>
									</div>
									<div>
										<span className={'text02'}>( {item.unit}{item.unitName} x {item.orderCount} )</span>
										<span className={'fontSize16 textBold'}>{item.unit * item.orderCount} {item.unitName}</span>
									</div>
								</div>
							)
						})
					}
				</div>
				<div className={'bottomBox'}>
					<CopyToClipboard text={board} onCopy={()=>setIsCopied(true)}><span className={`copyBtn ${isCopied ? 'activeBtn':'activeRedBtn'}`}>{isCopied ? 'copied':'copy'}</span></CopyToClipboard>
				</div>
				<Loader loading={!isLoaded}/>
				<RegBuyingPriceModal
					isOpen={isOpenRegPrice}
					setIsOpen={setIsOpenRegPrice}
					success={successBuyingPrice}
					oldData={data ? data.buyingPrice : ''}
				/>
				<SupplyStoreSelectModal
					isOpen={isOpenSupply}
					setIsOpen={setIsOpenSupply}
					success={successSupplyStore}
					oldData={data ? {value: data.ssId, label: data.supplyStore} : ''}
				/>
				<PhotoUpLoadModal
					isOpen={isOpenPhoto}
					setIsOpen={setIsOpenPhoto}
					title={'ใบเสร็จ'}
					viewType={'RECEIPT'}
					success={successReceipt}
					oldData={data ? data.buyingPhoto : ''}
				/>
			</div>
		</SlidingPane>
	);
};

WorkPackingDetailPage.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	paId: PropTypes.number.isRequired,
	code: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	pageCallback: PropTypes.func,
};

export default WorkPackingDetailPage;

import * as types from './../helpers/types'
import * as Api from "../../helper/api";
import * as Vo from '../../helper/vo'
import {SET_RECENTLY_PRODUCTS} from "../helpers/types";
import {SET_PACKING_PRODUCTS} from "./../helpers/types";


/**
 * 싱품정보는 카트를 통해 상품 주문정보와 연동된다.
 * 주문을 할때 상세 정보로 상품정보 (ProductVo) 품목들이 저장된다.
 */
export const getProductsData = async (dispatch) : any => {
	try {
		const resData = await Api.getProduct_API()
		if (!resData.data) {return false}
		const { data, status } = resData.data

		if (status) {
			const resultProducts: Vo.ProductVo = data.length > 0 ? data.map( item => {
				const tempObj: Vo.ProductVo = {}
				// tempObj.id = Number(item.id)
				tempObj.productId = Number(item.id)
				tempObj.sortNum = Number(item.sort_num)
				tempObj.code = item.code
				tempObj.category = item.category
				tempObj.nameKo = item.name_ko
				tempObj.nameTh = item.name_th
				tempObj.nameEn = item.name_en
				tempObj.desc = item.desc || ''
				tempObj.photo = item.photo
				tempObj.unit = parseFloat(item.unit)
				tempObj.unitName = item.unit_name
				tempObj.price = Number(item.price)
				tempObj.count = item.count ? Number(item.count) : 0
				return tempObj
			}) : []

			dispatch(setProductData(resultProducts))

			// 시세
			const resultMarketPriceProducts: Vo.MarketPriceProductsVo = data && data.filter( item => {
				return item.is_market === 'Y'
			}).map( item => {
				const tempObj: Vo.MarketPriceProductsVo = {}
				// tempObj.id = Number(item.id)
				tempObj.productId = Number(item.id)
				tempObj.code = item.code
				tempObj.category = item.category
				tempObj.nameKo = item.name_ko
				tempObj.nameTh = item.name_th
				tempObj.nameEn = item.name_en
				tempObj.desc = item.desc || ''
				tempObj.photo = item.photo
				tempObj.unit = parseFloat(item.unit)
				tempObj.unitName = item.unit_name
				tempObj.price = Number(item.price)
				tempObj.count = item.count ? Number(item.count) : 0
				tempObj.isMarket = true
				tempObj.marketPrice = item.market_price ? Number(item.market_price) : 0
				return tempObj
			})

			dispatch(setProductMarketPriceData(resultMarketPriceProducts))
		}
	}catch (e) {
		console.warn(e)
	}
}

export const getRecentlyProductsData = async (dispatch) : any => {
	try {
		const resData = await Api.getRecentlyProduct_API()
		if (!resData.data) {return false}
		const { data, status } = resData.data
		if (status) {
			const resultRecentlyData: Vo.ProductVo = data.length > 0 ? data.map( item => {
				const tempObj: Vo.ProductVo = {}
				tempObj.productId = Number(item.id)
				tempObj.code = item.code
				tempObj.category = item.category
				tempObj.nameKo = item.name_ko
				tempObj.nameTh = item.name_th
				tempObj.nameEn = item.name_en
				tempObj.desc = item.desc || ''
				tempObj.photo = item.photo
				tempObj.price = Number(item.price)
				tempObj.unit = parseFloat(item.unit)
				tempObj.unitName = item.unit_name
				tempObj.count = item.count ? Number(item.count) : 0
				return tempObj
			}): []
			dispatch(setRecentlyProductData(resultRecentlyData))
		}
	}catch (e) {
		console.warn(e)
	}
}


export const setProductData = (data) => (
	{
		type: types.SET_PRODUCTS,
		data,
	}
)

// 최근상품
export const setRecentlyProductData = (data) => (
	{
		type: SET_RECENTLY_PRODUCTS,
		data,
	}
)

// 시세
export const setProductMarketPriceData = (data) => (
	{
		type: types.SET_PRODUCTS_MARKET_PRICE,
		data,
	}
)

export const setCategory = (data) => (
	{
		type: types.SET_CATEGORY,
		data,
	}
)


export const searchProductData = (data) => (
	{
		type: types.SET_SEARCH_PRODUCTS,
		data,
	}
)


export const categoryProductData = (data) => (
	{
		type: types.SET_CATEGORY_PRODUCTS,
		data,
	}
)


export const packingProductsData = (data) => (
	{
		type: types.SET_PACKING_PRODUCTS,
		data,
	}
)

/**
 * Props 로 정의하는 방법
 * */
// export const getProductsData = () : any => {
// 	console.log('dddd')
// 	return async (dispatch) => {
// 		return Api.getProduct_API().then( res => {
// 			console.log('res >>>', res)
// 			const { data } = res.data
// 			dispatch(setProductData(data))
// 			return true
// 		})
// 	}
// }

import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import ProductItem from "./productItem";
import {useSelector} from "react-redux";
import * as Vo from '../../helper/vo';


const ProductList = (props) => {
  const {updateDimensions, height} = props
  const {product} = useSelector(state => state.productState);
  const {cartList} = useSelector(state => state.cartState);
  const [data, setData] = useState([]);

  let filteredData = JSON.parse(JSON.stringify(product.filteredData))

  // 카드에 담긴 수량과 동기화
  const setProductCount = () => {
    if(cartList.length === 0) {
      filteredData = JSON.parse(JSON.stringify(product.filteredData))
      setData(filteredData)
      return
    }

    filteredData = cartList.map(i => {
      return filteredData.map(j => {
        if(j.code === i.code) {
         j.count = i.count
        }
        return j
      })
    })[0]

    setData(filteredData)
  }

  useEffect(() => {
    setProductCount()
  }, [cartList]);

  useEffect(() => {
    setData(filteredData)
    updateDimensions()
    setProductCount()
  }, [product.filteredData]);

  return (
    <div className={'productListWrapper scrollWrapper'} style={{height: `${height}px`}}>
      {
        data?.length > 0 ? data.map((item: Vo.ProductVo) => {
          return (
            <ProductItem
              key={item.productId}
              item={item}
            />
          )
        }) : null
      }
    </div>
  );
};

ProductList.propTypes = {
  updateDimensions: PropTypes.func,
  height: PropTypes.number
};

export default ProductList;

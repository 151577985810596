import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {setOrderModal} from "../../../redux/actions/commAction";
import * as Utils from "../../../helper/utils";


Modal.setAppElement('#root');
const OrderModal = (props) => {
	const {totalPrice,deliveryDate,deliveryAddress,orderConfirm} = props
	const dispatch = useDispatch()
	const {isOrderModal} = useSelector(state => state.commState);

	const closeModal = () => {
		dispatch(setOrderModal(false));
	}

	return (
		<Modal
			isOpen={isOrderModal}
			// onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			contentLabel="Example Modal"
			className="orderModalWrapper"
			overlayClassName={'modalOverlay'}
			shouldCloseOnOverlayClick={false}
		>
			<div className={'contentBox'}>
				<div className={'conBox'}>
					<div className={'conInfoBox'}>
						<div className={'textSec'}>
							<div className={'tit'}>
								<div>배송지</div>
								<span className={'textThi fontSize12'}>(ที่อยู่จัดส่ง)</span>
							</div>
							<div className={'desc'}>{deliveryAddress}</div>
						</div>
						<div className={'textSec'}>
							<div className={'tit'}>
								<div>배송일</div>
								<span className={'textThi fontSize12'}>(วันที่จัดส่ง)</span>
							</div>
							<div className={'desc'}>{deliveryDate}</div>
						</div>
						<div className={'textSec'}>
							<div className={'tit'}>
								<div>주문금액</div>
								<span className={'textThi fontSize12'}>(ราคารวม)</span>
							</div>
							<div className={'desc mainColorText fontSize16 textBold'}>{Utils.numberFormat(totalPrice)} THB</div>
						</div>
						<div className={'textSec'}>
							<div className={'tit'}> </div>
							<div className={'desc'}>주문을 진행합니다</div>
						</div>
						{/*<div className={'text01'}>배송일 <span>{deliveryDate}</span></div>*/}
						{/*<div className={'text01'}>주문금액 <span>{totalPrice} THB</span></div>*/}
						{/*<div className={'text01'}>배송지 <span>{totalPrice} THB</span></div>*/}
						{/*<div className={'text02'}>주문을 진행합니다</div>*/}
					</div>
				</div>
				<div className={'flexRow btnBox'}>
					<div className={'buttonBtn cancelBtn'} onClick={closeModal}>취소 <span className={'textThi'}>(ยกเลิก)</span></div>
					<div className={'buttonBtn confirmBtn'} onClick={orderConfirm}>주문 <span className={'textThi'}>(สั่งซื้อ)</span></div>
				</div>
			</div>
		</Modal>
	);
};

OrderModal.propTypes = {
	totalPrice: PropTypes.number,
	deliveryDate: PropTypes.string,
	deliveryAddress: PropTypes.string,
	orderConfirm : PropTypes.func,
};

export default OrderModal;

// 배송지 ที่อยู่จัดส่ง

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Header from "../components/header";
import * as Temp from "../helper/tempData";
import * as Utils from "../helper/utils";
import * as Api from "../helper/api";
import {reqUserGrade} from "../redux/actions/authAction";


const LevelPage = () => {
	const levelPageRef = useRef(null);
	const dispatch = useDispatch()
	const {isLogin, profile} = useSelector(state => state.userState);
	const {grade, isAuthCompany, totalPrice3m, totalPriceAll} = profile
	const [dimensions, setDimensions] = useState({width: '', height: 760})
	const [isReqLevelBtn, setIsReqLevelBtn] = useState(false);


	const setReqGrade = async () => {

		if(!isReqLevelBtn) return

		try{
			setIsReqLevelBtn(false)
			const resData = await Api.reqGrade_API()

			if (!resData.data) {return false}
			const {status} = resData.data

			if (status) {
				dispatch(reqUserGrade(true))
			}
		}catch (e) {
			setIsReqLevelBtn(true)
		}
	}

	const setCompareReq = useCallback(()=> {

		// 로그인했을때만 요청한다.
		if(!isLogin) return;

		// 관리자면 요청하지 않는다.
		if(profile.userType !== 'NORMAL') return;

		// 사업자인증이 받지 않았다면
		if(!profile.isAuthCompany) return;

		// 이미 요청이 있다면 진행하지 않는다.
		if(profile.isReqGrade) {
			setIsReqLevelBtn(false)
			return;
		}

		// 현의 나의 레벨의 인덱스를 구한다.
		const nowLevelIndex = Temp.levelData.findIndex( item => item.value === profile.grade )
		// 구한 인덱스전의 값을 구한다.
		// 인덱스가 0이면 상위가 없기에 0에 해당하는 값을 리턴한다.
		const nextLevelPrice = Temp.levelData[nowLevelIndex === 0 ? 0 : nowLevelIndex-1].price

		// 최고등급이면 요청버튼을 활성화 하지 않는다.
		if(profile.grade !== 'VIP') {

			if(totalPrice3m > nextLevelPrice){
				setIsReqLevelBtn(true)
				return
			}

			setIsReqLevelBtn(false)
		}
	}, [profile.grade, profile.isReqGrade, totalPrice3m])

	const updateDimensions = () => {
		if (levelPageRef.current) {
			setDimensions({
				width: levelPageRef.current.offsetWidth,
				height: levelPageRef.current.offsetHeight,
			});
		}
	};


	useEffect(()=> {
		setCompareReq()
	}, [profile.totalPrice3m, setCompareReq])


	useEffect(() => {

		setTimeout(()=> {
			updateDimensions()
		}, 200)

		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<div className={'levelWrapper'} ref={levelPageRef} >
			<Header />
			<div className={'topTitleBox'}>
				<div className={'topTitle levelUnderBox'}>등급/수수료안내<span className={'topSubTitle'}>LEVEL/FEE</span></div>
				<div> </div>
			</div>
			<div className={'scrollWrapper'} style={{height: `${dimensions.height - 170}px`}}>
				{
					(isLogin && isAuthCompany) &&
					<div className={'myLevelBox'}>
						<div className={'myLevelTopBox'}>나의 등급</div>
						<div className={'myLevelInfoBox'}>
							<div className={'text01 textEn01'} style={{color: `${Utils.levelTag(grade)}`}}>{grade}</div>
						</div>
						<div className={'priceInfoBox'}>
							<div className={'monthPriceBox'}>
								<div className={'subText'}>3개월 누적금액</div>
								<div className={'text01'}>{Utils.numberFormat(totalPrice3m)} THB</div>
							</div>
							<div className={'allPriceBox'}>
								<div className={'subText'}>총 누적금액</div>
								<div className={'text01'}>{Utils.numberFormat(totalPriceAll)} THB</div>
							</div>
						</div>
						<div className={`levelChangeBtn ${(isReqLevelBtn && !profile.isReqGrade) ? 'activeBtn' : 'defaultBtn'}`} onClick={setReqGrade}>{profile.isReqGrade ? '요청됨' :'등급조정 요청'}</div>
					</div>
				}
				<div className={'levelInfoBox01'}>
					<div className={'levelTopBox'}>등급 / 수수료안내</div>
					<ul>
						<li className={'levelHeader'}>
							<div className={'text01 subText'}>등급</div>
							<div className={'text02 subText'}>대행수수료</div>
							<div className={'text03 subText'}>3개월 누적금액</div>
						</li>
					</ul>
					<ul>
						{
							Temp.levelData.map(item => {
								return(
									<li key={item.id}>
										<div className={'text01'}>
											<span className={`tagBase`} style={{color: `${Utils.levelTag(item.levelTag)}`}}>{item.levelTag}</span>
										</div>
										<div className={'text02'}>{item.fee}%</div>
										<div className={'text03'}>{Utils.numberFormat(item.price)} THB</div>
									</li>
								)
							})
						}
					</ul>
				</div>
				<div className={'levelInfoBox02'}>
					<div className={'infoBox'}>
						<div className={'text01'}>
							구매대행 수수료는 등급조정은 3개월간 누적구매 달성시 등급이 조정을 요청할수 있습니다.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

LevelPage.propTypes = {

};

export default LevelPage;

import React, {useEffect, useState} from 'react';
import Header from "../../components/header";
import {useNavigate} from "react-router-dom";
import * as Api from "../../helper/api";
import {ChangePassVo} from "../../helper/vo";


const ChangePassPage = () => {
	const navigate = useNavigate();
	const [step, setStep] = useState(1);
	const [message, setMessage] = useState('');
	const [pass, setPass] = useState('');
	const [newPass, setNewPass] = useState('');
	const [newRePass, setNewRePass] = useState('');

	const validate = () => {

		if(!pass) {
			setMessage('기존비밀번호를 입력해주세요.')
			return false
		}

		if(!newPass) {
			setMessage('새로운 비밀번호를 입력해주세요.')
			return false
		}

		if(!newRePass) {
			setMessage('새로운 비밀번호를 다시 입력해주세요.')
			return false
		}

		if(newPass !== newRePass) {
			setMessage('재입력한 비밀번호가 일치하지 않습니다.')
			return false
		}

		return true
	}


	const changePas =	async() => {
		if(!validate()) return false

		const params : ChangePassVo = {}
		params.pass = pass
		params.newPass = newPass
		params.newRePass = newRePass

		try {
			const resData = await Api.changePass_API(params)
			if (!resData.data) return
			const { status, message } = resData.data
			if(status){
				setStep(2)
			}else{
				setMessage(message)
			}
		}catch (e) {

		}
	}


	useEffect(() => {
		setMessage('')
	}, [pass, newPass, newRePass]);


	const stepView = () => {
		if(step === 1) {
			return (
				<>
					<div className={'inputSec'}>
						<div className={'inputBox'}>
							<input
								value={pass}
								onChange={(e)=>setPass(e.target.value)}
								placeholder={'기존 비밀번호'}
							/>
						</div>
						<div className={'inputBox'}>
							<input
								value={newPass}
								type={'password'}
								onChange={(e)=>setNewPass(e.target.value)}
								placeholder={'새로운 비밀번호'}
							/>
						</div>
						<div className={'inputBox'}>
							<input
								value={newRePass}
								type={'password'}
								onChange={(e)=>setNewRePass(e.target.value)}
								placeholder={'새로운 비밀번호 재입력'}
							/>
						</div>
					</div>
					<div className={'messageInfoBox mainRedText'}>
						{message}
					</div>
					<div className={`focusBaseBtn ${(pass && newPass && newRePass) ? 'activeBtn' : 'defaultBtn'}`} onClick={changePas}>변경하기</div>
				</>
			)
		}else if(step === 2) {
			return (
				<>
					<div className={'messageInfoBox'}>변경이 완료되었습니다.</div>
					<div className={'focusBaseBtn activeBtn'} onClick={()=>navigate('/home')}>홈</div>
				</>
			)
		}else {

		}
	}

	return (
		<div className={'changePassWrapper'}>
			<Header/>
			<div className={'layoutBox'}>
				<div className={'changePassTit'}>비밀번호 찾기</div>
				{stepView()}
			</div>
		</div>
	);
};

ChangePassPage.propTypes = {

};

export default ChangePassPage;

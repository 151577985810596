import React, {useEffect, useRef, useState} from 'react';
import Header from "../components/header";
import MarketPriceList from "../components/marketPriceList";

// const tempText = '마켓다우는 매주 월~ 토요일 태국 돈무앙 근처에있는 \n' +
// 	'땡땡딸랏(시장) 시장에서 사입하고 있으며 주 1~2회 \n' +
// 	'변동이 있는 도매 판매처3곳의 시세를 비교하여 사입 \n' +
// 	'단가의 평균하여 익일 판매가에 적용을 하고있습니다.\n' +
// 	'시세의 반영은 오후12:00~13:00 변동 품목에 한해\n' +
// 	'판매가에 반영이됩니다.'

const tempText01 = `1. 당일 판매가는 전일 구매가로 변경`;
const tempText02 = `2. 가격 업데이트 오후 4~6시 사이반영`;
const tempText03 = `3. 단위 구분 필수확인 0.5Kg ,1Kg, 1ea, 1pack`;
const tempText04 = ``;

const MarketPricePage = props => {
	const mkPriceRef = useRef(null);
	const [dimensions, setDimensions] = useState({width: '', height: 450})

	const updateDimensions = () => {
		if (mkPriceRef.current) {
			setDimensions({
				width: mkPriceRef.current.offsetWidth,
				height: mkPriceRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<div className={'marketPriceWrapper'} ref={mkPriceRef}>
			<Header />
			<div className={'topTitleBox'}>
				<div className={'topTitle mpUnderBox'}>시세<span className={'topSubTitle'}>MARKET PRICE</span></div>
				<div> </div>
			</div>
			<div className={'mkInfoBox01'}>
				<div>
					<p>{tempText01}</p>
					<p>{tempText02}</p>
					<p>{tempText03}</p>
					<p>{tempText04}</p>
				</div>
				{/*<div>{tempText}</div>*/}
			</div>
			<MarketPriceList
				updateDimensions={updateDimensions}
				height={dimensions.height - 320}
				pd={20}
			/>
		</div>
	);
};

MarketPricePage.propTypes = {

};

export default MarketPricePage;

import createReducer from "../helpers/createReducers";
import * as initialType from '../helpers/initialTypes';
import * as types from './../helpers/types'
import {SET_PACKING_PRODUCTS, SET_RECENTLY_PRODUCTS} from "./../helpers/types";


export const productState = createReducer( initialType.initialProductState, {
	[types.SET_PRODUCTS_MARKET_PRICE](state, action) {
		const resultData = action.data
		return ({
			...state,
			product: {
				...state.product,
				marketPriceData: resultData
			},
		})
	},
	[types.SET_PRODUCTS](state, action) {
		const resultData = action.data
		return ({
			...state,
			product: {
				...state.product,
				initData: resultData,
				filteredData: resultData,
			},
		})
	},
	[types.SET_CATEGORY](state, action) {
		return ({
			...state,
			product: {
				...state.product,
				category: action.data
			},
		})
	},
	[types.SET_SEARCH_PRODUCTS](state, action) {
		const { product } = state
		let resultData;

		if(action.data) {
			resultData = product.initData.filter( item => {
				return item.nameKo.includes(action.data) || item.nameTh.includes(action.data)
			})
		}else{
			resultData = product.initData
		}

		return ({
			...state,
			product: {
				...state.product,
				filteredData: resultData,
			},
		})
	},
	[types.SET_CATEGORY_PRODUCTS](state, action) {
		const { product } = state
		let resultData;

		if(action.data) {
			if(action.data === 'ALL'){
				resultData = product.initData
			}else {
				resultData = product.initData.filter( item => {
					return item.category === action.data
				})
			}
		}

		return ({
			...state,
			product: {
				...state.product,
				filteredData: resultData,
			},
		})
	},
	[types.SET_RECENTLY_PRODUCTS](state, action) {
		return ({
			...state,
			product: {
				...state.product,
				recentlyData: action.data,
			},
		})
	},
	[types.SET_PACKING_PRODUCTS](state, action) {
		return ({
			...state,
			product: {
				...state.product,
				packingData: action.data,
			},
		})
	},
})

import {compose, createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from '../reducers';
import {createLogger} from 'redux-logger';


const loggerMiddleware = createLogger();
const configureStore = (initialState) => {

	// ADD REDUX DEBUG TOOL ==> https://github.com/zalmoxisus/redux-devtools-extension#usage
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	let enhancer;
	if (process.env.NODE_ENV === 'development') {
		enhancer = composeEnhancers(
			applyMiddleware(
				thunkMiddleware,
				loggerMiddleware,
			),
		);
	} else {
		enhancer = composeEnhancers(
			applyMiddleware(
				thunkMiddleware,
			),
		);
	}

	return createStore(reducer, initialState, enhancer);
};

export default configureStore;

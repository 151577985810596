import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {categoryProductData, setCategory} from "../../redux/actions/productAction";
import * as Temp from "../../helper/tempData";



const ProductCategory = () => {
	const dispatch = useDispatch()
	const categoryRef = useRef(null)
	const {product} = useSelector(state => state.productState);
	const [categoryList, setCategoryList] = useState([]);

	const setFilter = (categoryCode:string) : void => {
		dispatch(setCategory(categoryCode))
		dispatch(categoryProductData(categoryCode))
	}

	useEffect(()=> {
		setCategoryList(Temp.CategoryList)
	}, [])

	return (
		<div className={'categoryBox'} ref={categoryRef} >
			<ul>
				{
					categoryList.length > 0 && categoryList.map( item => {
						return(
							<li key={item.id} >
								<div
									className={`cateItem ${item.category === product.category && 'itemFocus'}`}
									onClick={()=>setFilter(item.category)}>{item.name_ko}</div>
							</li>
						)
					})
				}
			</ul>
		</div>
	);
};

ProductCategory.propTypes = {

};

export default ProductCategory;

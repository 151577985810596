import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import OrderItem from "./orderItem";
import {useNavigate} from "react-router-dom";


const OrderList = props => {
	const {updateDimensions, height} = props
	const navigate = useNavigate();
	const {isLogin} = useSelector(state => state.userState);
	const {orderList} = useSelector(state => state.orderState);
	const [orderData, setOrderData] = useState([]);

	const goPage = (id: string | number = '') => {
		if(!id) return

		navigate(`/order/${id}`)
	}

	const gotoLogin = () => {
		if(!isLogin) {
			const alertMessage = window.confirm("로그인이 필요합니다. 로그인하시겠습니까?");
			if(alertMessage) {
				// console.log('로그인 수행')
				navigate('/login')
			}
		}
	}

	useEffect(() => {
		setOrderData(orderList)
		updateDimensions()
	}, [orderList]);

	return (
		<div className={'orderListWrapper scrollWrapper'}  style={{height: `${height}px`}}>
			{
				orderData && orderData.length > 0 ? orderData.map( item => {
					return(
						<OrderItem
							key={item.id}
							item={item}
							goPage={goPage}
						/>
					)
				}) :
					isLogin
					? <div className={'noList'}>No order list</div>
					: <div className={'noList'} onClick={gotoLogin}>로그인필요</div>
			}
		</div>
	);
};

OrderList.propTypes = {
	updateDimensions: PropTypes.func,
	height: PropTypes.number
};

export default OrderList;

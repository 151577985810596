import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setOrderModal} from "../redux/actions/commAction";
import {getOrderData} from "../redux/actions/orderAction";
import {setCartItems} from "../redux/actions/cartAction";
import {BiCalendarCheck} from "react-icons/bi";
import Header from "../components/header";
import CartList from "../components/cartList";
import OrderModal from "../components/modal/orderModal";
import * as Utils from "../helper/utils";
import * as Api from "../helper/api";
import * as Vo from "../helper/vo";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const CartPage = () => {
	const cartPageRef = useRef(null);
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const {cartList} = useSelector(state => state.cartState);
	const {isLogin, profile} = useSelector(state => state.userState);
	const [isOrdering, setIsOrdering] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0)
	const [productPrice, setProductPrice] = useState(0);
	const [feePrice, setFeePrice] = useState(0);
	const [feeRate, setFeeRate] = useState(22);
	const [deliveryDate, setDeliveryDate] = useState(moment(new Date()).add(1, 'd').format('YYYY-MM-DD'));
	const [dimensions, setDimensions] = useState({width: '', height: 450})

	const setPrice = useCallback(() => {
		let sumPrice = 0
		// eslint-disable-next-line no-unused-expressions,array-callback-return
		cartList.length > 0 ? cartList.map(item => {
			sumPrice += (Number(item.count) * Number(item.price))
		}) : null

		setProductPrice(sumPrice)
		setFeePrice(percentSum(sumPrice, profile.feeRate))
		setTotalPrice(sumPrice + percentSum(sumPrice, profile.feeRate))
	}, [cartList, profile.feeRate]);


	const percentSum = (price, per) => {

		if(!price || !per) return 0

		const tempPrice = price
		const tempPer = per / 100

		return Math.floor(Number(tempPrice * tempPer))
	}

	const showOrderModal = () => {
		if(!isLogin) {
			const alertMessage = window.confirm("로그인이 필요합니다. 로그인하시겠습니까?");
			if(alertMessage) {
				// console.log('로그인 수행')
				navigate('/login')
				return
			}

			// console.log('현재페이지 멈춤')
		}
		cartList.length > 0 && dispatch(setOrderModal(true));
	}

	const orderConfirm = async () => {

		if(isOrdering) {
			alert('주문을 처리중입니다. 잠시 기다려 주세요.')
			return
		}

		// 주문처리중에 다시 오더 못하도록
		setIsOrdering(true)

		let orderData: Vo.OrderVo = {}
		orderData.deliveryDate = deliveryDate;
		orderData.productPrice = productPrice;
		orderData.feePrice = feePrice;
		orderData.totalPrice = totalPrice;
		orderData.productList = cartList;
		// orderData.payType = profile.payType;
		// orderData.feeRate = feeRate;
		if(!profile.isAuthCompany) {
			alert('사업자인증 후에 주문이 가능합니다.')
			return
		}

		try {
			const resData = await Api.setOrder_API(orderData)
			// console.log('result ***', resData)

			if (!resData.data) return
			const { status } = resData.data
			if(status) {
				// 주문정보 갱신
				getOrderData(dispatch)
				// 주문상태 변경 (재오더 가능)
				setIsOrdering(false)
				// 모달창 닫기
				dispatch(setOrderModal(false))
				// 카트비우기
				dispatch(setCartItems([]))
				// 주문목록 이동
				navigate('/order')
			}

		} catch (e) {
			setIsOrdering(false)
			alert(e)
			// console.log('e', e)
		}
	}

	const updateDimensions = () => {
		/**
		 * 데이터가 가변적일땐 데이터가 없을 때를 기준으로
		 * 화면이 그려지기 때문에 데이터가 변경될때 마다
		 * 재호출 해서 화면사이즈를 갱신한다.
		 * */
		if (cartPageRef.current) {
			setDimensions({
				width: cartPageRef.current.offsetWidth,
				height: cartPageRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0)
		setPrice()
	}, [cartList, setPrice]);

	useEffect(()=> {
		profile.feeRate && setFeeRate(profile.feeRate)
	}, [profile.feeRate])

	return (
		<div className={'cartWrapper'} ref={cartPageRef}>
			<Header/>
			<div className={'topTitleBox'}>
				<div className={'topTitle cartUnderBox'}>장바구니<span className={'topSubTitle'}>CART</span></div>
				<div> </div>
			</div>
			<div className={'cartCont'}>
				<div className={'conBox'}>
					<div className={'secBox'}>
						<div className={'lBox'}>배송일자 <span className={'textThi'}>(วนที่จัดส่ง)</span></div>
						<div className={'rBox'}>
							<div className={'iconBox'}>
								<BiCalendarCheck size={20}/>
							</div>
							<DatePicker
								dateFormat="yyyy-MM-dd"
								className={'datePicker'}
								minDate={new Date(new Date().setDate(new Date().getDate()))} // 선택할 수 있는 최소 날짜값 지정
								closeOnScroll={true}    // 스크롤을 움직였을 때 자동으로 닫히도록 설정 기본값 false
								// readOnly={true}
								selected={new Date(deliveryDate)}
								onChange={(date) => setDeliveryDate(moment(date).format('YYYY-MM-DD'))}
								onFocus={e => e.target.blur()}
								onKeyDown={(e) => {
									e.preventDefault();
								}} // 화면을 직접 수정할 수 없게 함
								disabledKeyboardNavigation
							/>
						</div>
					</div>
					<div className={'secBox'}>
						<div className={'lBox'}>상품금액 (ราคา)</div>
						<div className={'rBox'}>{Utils.numberFormat(productPrice)} THB</div>
					</div>
					<div className={'secBox'}>
						<div className={'lBox'}>수수료 (ค่าธรรมเนียม)<span className={'feeText'}>{feeRate}%</span></div>
						<div className={'rBox'}>{Utils.numberFormat(feePrice)} THB</div>
					</div>
					<div className={'secBox'}>
						<div className={'lBox'}>결제금액 <span className={'textThi'}>(ราคารวม)</span></div>
						<div> </div>
						<div className={'rBox focusText'}>{Utils.numberFormat(totalPrice)} THB</div>
					</div>
					<div className={'orderBtnBox'}>
						<div className={`orderBtn ${cartList.length > 0 ? 'activeBtn' : 'defaultBtn'}`} onClick={showOrderModal}>{Utils.numberFormat(totalPrice)} THB 주문하기 (สั่งซื้อ) </div>
					</div>
				</div>
			</div>
			<CartList
				updateDimensions={updateDimensions}
				height={dimensions.height - 420} // 165
			/>
			<OrderModal
				totalPrice={totalPrice}
				deliveryDate={deliveryDate}
				deliveryAddress={'133 Pan Rd, Silom, Bang Rak, Bangkok 10500 태국'}
				orderConfirm={orderConfirm}
			/>
		</div>
	);
};

CartPage.propTypes = {};

export default CartPage;

import createReducer from "../helpers/createReducers";
import * as initialType from '../helpers/initialTypes';
import * as types from './../helpers/types'


export const cartState = createReducer( initialType.initialCartState, {
	[types.SET_CART_ITEMS](state, action) {

		return ({
			...state,
			cartList: action.data
		});
	},

	[types.ADD_CART_ITEM](state, action) {

		let oldCartList = state.cartList
		const newCartItem = action.data
		// 상품에서 추가할땐 무조건 구매 가능상태로
		newCartItem.isBuying = true
		const findItem = oldCartList.find( item => item.productId === newCartItem.productId)

		if(findItem) {  // 같은 아이디가 존재하면 카운터 증가
			oldCartList =  oldCartList.map( item => {
				if(item.productId === findItem.productId) {
					item.count = Number(item.count) + 1
				}
				return item
			})
		}else {  // 존재하지 않으면 아이템 추가
			newCartItem.count = 1
			oldCartList.push(newCartItem)
		}

		const resultData = oldCartList

		return ({
			...state,
			cartList: resultData
		});
	},
	[types.REMOVE_CART_ITEM](state, action) {

		const {cartList} = state
		const resultData = cartList.filter( item => {
			return item.productId !== action.data
		})

		return ({
			...state,
			cartList: resultData
		});
	},
	[types.MINUS_CART_ITEM](state, action) {

		const {cartList} = state
		let resultData = cartList
		resultData = resultData.map(item => {
			if(item.productId === action.data) {
				if(Number(item.count) > 1) {
					item.count -= 1
				}
			}
			return item
		})

		return ({
			...state,
			cartList: resultData
		});
	},
	[types.PLUS_CART_ITEM](state, action) {

		const {cartList} = state
		let resultData = cartList
		resultData = resultData.map(item => {
			if(item.productId === action.data) {
				item.count = Number(item.count) +1
			}
			return item
		})

		return ({
			...state,
			cartList: resultData
		});
	},
})

import React, {useEffect} from 'react';
import Header from "../../components/header";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const CompanyPage = () => {
	const navigate = useNavigate();
	const {isLogin, profile, company} = useSelector(state => state.userState);

	useEffect(() => {
		if(!company.companyId) {
			// 한번도 등록된 적이 없으면 등록 모드로
			navigate('/company/auth')
		}else if(company.companyId && !profile.isAuthCompany) {  // 인증 요청중인상태
			// navigate(`/company/auth/${company.companyId}`)
		}else if(company.companyId && profile.isAuthCompany) {  // 인증 완료상태

		}
	}, []);

	return (
		<div className={'companyPageWrapper'}>
			<Header/>
			<div className={'topTitleBox'}>
				<div className={'topTitle acUnderBox '}>사업자인증<span className={'topSubTitle'}>COMPANY</span></div>
				<div className={'fontSize20 textBold'}> </div>
			</div>
			{
				isLogin ? profile.isAuthCompany ?
					<div className={'infoBox'}>
						<div className={'infoInSec'}>
							<div className={'textBase text001'}>인증되었습니다</div>
						</div>
						<div className={'editCompanyBtn'} onClick={()=>navigate(`/company/auth/${company.companyId}`)}>수정</div>
					</div>
					:
					<div className={'infoBox'}>
						<div className={'infoInSec'}>
							<div className={'textBase text001'}>심사중입니다</div>
							<div className={'textBase text002'}>심사는 영업일기준 최대 하루정도 소요됩니다.</div>
							<div className={'textBase text002'}>긴급요청은 고객센터를 이용해주세요</div>
							<div className={'textBase text003'}>고객센터 10:00 ~ 16:00 (월~금)</div>
						</div>
						<div className={'editCompanyBtn'} onClick={()=>navigate(`/company/auth/${company.companyId}`)}>수정</div>
					</div>
					:
					<div className={'infoBox'}>
						<div className={'infoInSec'}>
							<div className={'textBase text001'}></div>
							<div className={'textBase text002'}>로그인이 필요합니다</div>
						</div>
						<div className={'editCompanyBtn'} onClick={()=>navigate(`/login`)}>로그인</div>
					</div>
			}
		</div>
	);
};

CompanyPage.propTypes = {

};

export default CompanyPage;

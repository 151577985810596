import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import moment from "moment";
import DatePicker from "react-datepicker";

Modal.setAppElement('#root');
const ConfirmMakeBuyingModal = props => {
	const {success, isOpen, setIsOpen} = props
	const [deliveryDate, setDeliveryDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

	const closeModal = () => {
		setIsOpen(false)
	}

	const confirmSuccess = () => {
		if(deliveryDate) {
			success(deliveryDate)
			closeModal()
		}else{
			alert('구매데이터 생성일을 선택해주세요.')
		}
	}

	const initView = () => {

	}

	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={initView}
			// onRequestClose={()=>setIsOpen(false)}
			contentLabel="Example Modal"
			className="orderModalWrapper"
			overlayClassName={'modalOverlay'}
			shouldCloseOnOverlayClick={false}
		>
			<div className={'confirmModalWrapper makeBuyingModalWrapper'}>
				<div className={'confirmInBox'}>
					<div className={'text01'}>사입목록승인</div>
					<div className={'text02'}>승인 배송일자 선택</div>
					<div className={'pickerBox'}>
						<DatePicker
							dateFormat="yyyy-MM-dd"
							className={'datePicker'}
							// minDate={new Date(new Date().setDate(new Date().getDate() + 1))} // 선택할 수 있는 최소 날짜값 지정
							closeOnScroll={true}    // 스크롤을 움직였을 때 자동으로 닫히도록 설정 기본값 false
							selected={new Date(deliveryDate)}
							onChange={(date) => setDeliveryDate(moment(date).format('YYYY-MM-DD'))}
							onFocus={e => e.target.blur()}
							onKeyDown={(e) => {
								e.preventDefault();
							}} // 화면을 직접 수정할 수 없게 함
						/>
					</div>
					<div className={'text03'}>[태국] 오후 11시 이후 승인</div>
					<div className={'text03'}>[한국] 오전 01시 이후 승인</div>
					<div className={'text04'}>구매목록를 생성하시겠습니까?</div>
				</div>
				<div className={'flexRow confirmBtnBox'}>
					<div className={'buttonBtn cancelBtn'} onClick={closeModal}>취소</div>
					<div className={'buttonBtn confirmBtn'} onClick={confirmSuccess}>승인</div>
				</div>
			</div>
		</Modal>
	);
};

ConfirmMakeBuyingModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	success: PropTypes.func.isRequired,
};

export default ConfirmMakeBuyingModal;

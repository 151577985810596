import React, {useCallback, useEffect, useRef, useState} from 'react';
import Header from "../../components/header";
import MemberList from "../../components/manage/memberList";
import Loader from "../../components/Loader";
import * as Api from "../../helper/api";
import {MemberListVo} from "../../helper/vo";


const MemberMangePage = () => {
	const memberMangePageRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [dimensions, setDimensions] = useState({width: '', height: 900})
	const [data, setData] = useState([]);

	// const getData = async () : void => {
	// 	try {
	// 		const resData = await Api.getUsersData_API()
	// 		if (!resData.data) return
	// 		const { data, status } = resData.data
	// 		if(status) {
	// 			const resultData = data && data.length > 0 ? data.map(item => {
	// 				const tempObj:MemberListVo={}
	// 				tempObj.userId = Number(item.user_id)
	// 				tempObj.companyId = Number(item.company_id)
	// 				tempObj.grade = item.grade
	// 				tempObj.storeName = item.store || `상점${item.user_id}`
	// 				tempObj.deliveryZone = item.delivery_zone
	// 				tempObj.tel = ''
	// 				tempObj.phone = item.phone
	// 				tempObj.isAuthCompany = item.approve === 'Y'
	// 				tempObj.isReqGrade = item.req_grade === 'Y'
	// 				return tempObj
	// 			}) : []
	//
	// 			setData(resultData)
	// 		}
	// 	}catch (e) {
	//
	// 	}finally {
	// 		setTimeout(()=>{
	// 			setIsLoaded(true)
	// 		}, 300)
	// 		updateDimensions()
	// 	}
	// }


	const getData = useCallback(async () : void => {
		try {
			const resData = await Api.getUsersData_API()
			if (!resData.data) return
			const { data, status } = resData.data
			if(status) {
				const resultData = data && data.length > 0 ? data.map(item => {
					const tempObj:MemberListVo={}
					tempObj.userId = Number(item.user_id)
					tempObj.companyId = Number(item.company_id)
					tempObj.grade = item.grade
					tempObj.storeName = item.store || `상점${item.user_id}`
					tempObj.deliveryZone = item.delivery_zone
					tempObj.tel = ''
					tempObj.phone = item.phone
					tempObj.isAuthCompany = item.approve === 'Y'
					tempObj.isReqGrade = item.req_grade === 'Y'
					return tempObj
				}) : []

				setData(resultData)
			}
		}catch (e) {

		}finally {
			setTimeout(()=>{
				setIsLoaded(true)
			}, 300)
			updateDimensions()
		}
	}, []);


	const pageCallback = (isValue) => {
		// console.log('itemCallback *****', isValue)
	}

	const updateDimensions = () => {
		if (memberMangePageRef.current) {
			setDimensions({
				width: memberMangePageRef.current.offsetWidth,
				height: memberMangePageRef.current.offsetHeight,
			});
		}
	};

	useEffect(() => {
		getData()
	}, [getData]);

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		window.addEventListener('load', updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			window.removeEventListener('load', updateDimensions);
		};
	}, []);

	return (
		<div className={'memberManageWrapper'} ref={memberMangePageRef}>
			<Header/>
			<div className={'topTitleBox'}>
				<div className={'topTitle cartUnderBox'}>회원관리<span className={'topSubTitle'}>MEMBER MANAGE</span></div>
				<div> </div>
			</div>
			<MemberList
				data={data}
				height={dimensions.height - 170}
				pageCallback={pageCallback}
			/>
			<Loader loading={!isLoaded}/>
		</div>
	);
};

MemberMangePage.propTypes = {

};

export default MemberMangePage;

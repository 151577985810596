// eslint-disable-next-line import/no-anonymous-default-export
export default {
	mainColor: '#2e9063', //11c257
	mainRed: '#dd2f41',
	mainDarkBlue: '#323a4d',
	mainDarkGreen: '#007565',

	black: '#000000',
	white: '#ffffff',

	blue: '#2196f3',
	darkBlack: '#212121',

	gray01: '#fafafa',
	gray02: '#f5f5f5',
	gray03: '#eeeeee',
	gray05: '#919191',


	green00: '#dbece3',
	green01: '#b7d9c8',
	green02: '#94c6ad',
	green03: '#6fb394',
	green04: '#47a07a',
	green05: '#008d62',
	green06: '#107451',
	green07: '#155c41',
	green08: '#164532',

	level01: '#000000',
	level02: '#dd2f41',
	level03: '#C9BA49',
	level04: '#9D9696',
	level05: '#90812E',
	level06: '#2e9063',
}

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import NumberFormat from "react-number-format";
import colors from "../../../styles/colors";
import {RiCloseCircleFill} from "react-icons/ri";
import * as Utils from "../../../helper/utils";

const NumberInput = props => {
	const { title, placeholderText, value, setValue, isRow} = props
	const isRowView = isRow || false
	const [numberValue, setNumberValue] = useState({value:'', formatValue: ''});

	const resetInput = () => {
		setNumberValue({value:'', formatValue: ''})
	}

	useEffect(() => {
		if(value && value > 0) {
			const formatValue = Utils.numberFormat(value)
			setNumberValue({value, formatValue})
		}
	}, [value]);

	return (
		<div className={`inputBaseWrapper numberInputWrapper ${isRowView ? 'inputRowType': ''}`}>
			<div className={`inputTitle ${isRowView ? 'inputRowTitle': ''}`}>{title}</div>
			<div className={'inputBox'}>
				{
					numberValue.value > 0 &&
					<div className={'resetBtn'} onClick={resetInput}>
						<RiCloseCircleFill size={22} color={colors.gray05}/>
					</div>
				}
				<NumberFormat
					className={'numInput'}
					thousandSeparator={true}
					// prefix={'THB'}
					placeholder={placeholderText}
					value={numberValue.formatValue}
					onValueChange={(values) => {
						const { formattedValue, value } = values;
						// formattedValue = $2,223
						// value ie, 2223
						setValue(Number(value))
						setNumberValue({ value: value,  formatValue: formattedValue});
					}}
					inputMode={'decimal'}
					pattern={'[0-9],*'}
				/>
			</div>
		</div>
	);
};

NumberInput.propTypes = {
	title: PropTypes.string,
	placeholderText: PropTypes.string,
	value: PropTypes.number.isRequired,
	setValue: PropTypes.func.isRequired,
};

export default NumberInput;

import React from 'react';
import PropTypes from 'prop-types';
import * as Utils from "../../../helper/utils";

const MarketPriceItem = props => {
	const {item} = props
	return (
		<div className={'marketPriceItemWrapper'}>
			<div className={'box01'}>
				<div className={''}>{item.nameKo}</div>
				<div className={'t02 subText textThi '}>{item.nameTh}</div>
			</div>
			<div className={'box01'}>
				<div className={'subText'}>{item.unit} kg</div>
				<div className={'priceText subText'}>{Utils.numberFormat(item.marketPrice)}</div>
				<div className={'priceText textBold mainColorText'}>{Utils.numberFormat(item.price)}</div>
				{/*<div className={'upDownBox'}>*/}
					{/*<div className={'downBox'}><BiChevronDown size={20}/></div>*/}
					{/*<div className={'upBox'}><BiChevronUp size={20}/></div>*/}
				{/*</div>*/}
			</div>
		</div>
	);
};

MarketPriceItem.propTypes = {
	item: PropTypes.object
};

export default MarketPriceItem;

import React, {useState} from 'react';
import {HiMail} from "react-icons/hi";
import {IoShieldHalfOutline} from "react-icons/io5";
import colors from "../../styles/colors";
import Header from "../../components/header";
import * as Api from "../../helper/api";
import {FindPassAuthCodeVO, ReSetPassVO} from "../../helper/vo";
import {useNavigate} from "react-router-dom";


const FindPassPage = props => {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [step, setStep] = useState(1);
	const [code, setCode] = useState('');
	const [newPass, setNewPass] = useState('');
	const [newRePass, setNewRePass] = useState('');

	// const [isCodeSendSuccess, setIsCodeSendSuccess] = useState(false);		// 이메일로 코드 발송성공
	// const [isCodeCheckSuccess, setIsCodeCheckSuccess] = useState(false);	// 코드확인성공

	const sendEmail = async () => {
		if(!email) {
			alert('이메일을 입력해주세요.')
			return
		}

		try {
			const resData = await Api.sendEmailForPass_API(email)
			if (!resData.data) return
			const { status, message } = resData.data
			if(status){
				// setIsCodeSendSuccess(true)
				setStep(2)
			}else{
				alert(message)
			}
		}catch (e) {

		}
	}

	const checkCode = async () => {
		if(!code) {
			alert('코드를 입력해주세요.')
			return
		}

		const params : FindPassAuthCodeVO = {}
		params.code = code
		params.email = email
		params.type = '1'

		try {
			const resData = await Api.checkAuthCode_API(params)
			if (!resData.data) return
			const { status, message } = resData.data
			if(status){
				// setIsCodeCheckSuccess(true)
				setStep(3)
			}else{
				alert(message)
			}
		}catch (e) {

		}
	}

	const reSetPass = async () => {

		if(!email) {
			return
		}

		if(!newPass) {
			alert('새 비밀번호를 입력해주세요.')
			return
		}

		if(!newRePass) {
			alert('새 비밀번호를 다시 입력해주세요.')
			return
		}

		if(newPass !== newRePass) {
			alert('새비밀번호가 재입력한 비밀번호와 일치하지 않습니다.')
			return
		}

		const params : ReSetPassVO = {}
		params.email = email
		params.pass = newPass
		params.rePass = newRePass

		try {
			const resData = await Api.reSetPass_API(params)
			if (!resData.data) return
			const { status, message } = resData.data
			if(status){
				// setIsCodeCheckSuccess(true)
				setStep(4)
			}else{
				alert(message)
			}
		}catch (e) {

		}
	}


	const stepView = () => {

		if(step === 1) {
			return (
				<div className={'stepBox'}>
					<div className={'section'}>
						<div className={'secBox activeSec'}>
							<div className={'iconBox'}>
								<HiMail size={21} color={colors.mainColor}/>
							</div>
							<div className={'inputBox'}>
								<input
									value={email}
									onChange={(e)=>setEmail(e.target.value)}
									placeholder={'sample@marketdawoo.com'}
								/>
							</div>
							{/*<div>*/}
							{/*	<div>Email</div>*/}
							{/*	<div className={'subGrayColorText'}>Send to your email</div>*/}
							{/*</div>*/}
						</div>
						<div>

						</div>
					</div>
					<div className={`continueBtn ${email.length > 0 ? 'activeBtn' : 'defaultBtn'}`} onClick={sendEmail}>계속</div>
				</div>
			)
		}else if(step === 2) {
			return (
				<div className={'stepBox'}>
					<div className={'stepInfo'}>
						<p>입력하신 이메일로 보안코드를 전송하였습니다.</p>
						<p>스팸메일함도 확인해주세요.</p>
					</div>
					<div className={'section'}>
						<div className={'secBox activeSec'}>
							<div className={'iconBox'}>
								<IoShieldHalfOutline size={21} color={colors.mainColor}/>
							</div>
							<div className={'inputBox'}>
								<input
									value={code}
									onChange={(e) => setCode(e.target.value)}
									placeholder={'6자리 코드를 입력해주세요.'}
								/>
							</div>
						</div>
						<div>

						</div>
					</div>
					<div className={`continueBtn ${code.length === 6 ? 'activeBtn' : 'defaultBtn'}`} onClick={checkCode}>확인</div>
				</div>
			)
		}else if(step ===3) {
			return (
				<div className={'stepBox'}>
					<div className={'section'}>
						<div className={'secBox activeSec'} style={{height:55}}>
							{/*<div className={'iconBox'}>*/}
							{/*	<HiMail size={21} color={colors.mainColor}/>*/}
							{/*</div>*/}
							<div className={'inputBox'}>
								<input
									value={newPass}
									type={'password'}
									onChange={(e) => setNewPass(e.target.value)}
									placeholder={'새로운 비밀번호를 입력해주세요'}
								/>
							</div>
						</div>
						<div className={'secBox activeSec pd20'} style={{height:55}}>
							{/*<div className={'iconBox'}>*/}
							{/*	<HiMail size={21} color={colors.mainColor}/>*/}
							{/*</div>*/}
							<div className={'inputBox'}>
								<input
									value={newRePass}
									type={'password'}
									onChange={(e) => setNewRePass(e.target.value)}
									placeholder={'새로운 비밀번호를 재입력해주세요'}
								/>
							</div>
						</div>
						<div>

						</div>
					</div>
					<div className={`continueBtn ${(newPass && newRePass) ? 'activeBtn' : 'defaultBtn'}`} onClick={reSetPass}>확인</div>
				</div>
			)
		}else if(step === 4) {
			return (
				<div className={'stepBox'}>
					<div>변경이 완료되었습니다.</div>
					<div className={'continueBtn activeBtn'} onClick={()=>navigate('/login')}>로그인</div>
				</div>
			)
		}else {

		}
	}

	return (
		<div className={'findPassWrapper'}>
			<Header/>
			<div className={'layoutBox'}>
				<div className={'findPassTit'}>비밀번호 찾기</div>
				{stepView()}
			</div>
		</div>
	);
};

FindPassPage.propTypes = {

};

export default FindPassPage;

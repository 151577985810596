import React from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";


Modal.setAppElement('#root');
const ConfirmCompanyModal = props => {
	const {title, message, success, isOpen, setIsOpen} = props

	const closeModal = () => {
		setIsOpen(false)
	}

	const confirmSuccess = () => {
		success()
		closeModal()
	}

	return (
		<Modal
			isOpen={isOpen}
			// onAfterOpen={afterOpenModal}
			// onRequestClose={()=>setIsOpen(false)}
			contentLabel="Example Modal"
			className="orderModalWrapper"
			overlayClassName={'modalOverlay'}
			shouldCloseOnOverlayClick={false}
		>
			<div className={'confirmModalWrapper'}>
				<div className={'confirmInBox'}>
					<div className={'text01'}>{title}</div>
					<div className={'text01'}>{message}</div>
				</div>
				<div className={'flexRow confirmBtnBox'}>
					<div className={'buttonBtn cancelBtn'} onClick={closeModal}>취소</div>
					<div className={'buttonBtn confirmBtn'} onClick={confirmSuccess}>확인</div>
				</div>
			</div>
		</Modal>
	);
};

ConfirmCompanyModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	success: PropTypes.func.isRequired,
	title: PropTypes.string,
	message: PropTypes.string,
};

export default ConfirmCompanyModal;

import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {IMG_PRODUCT_300_URL, IMG_USER_NO_URL} from "../../helper/config";
import colors from "../../styles/colors";
import {FaCartPlus} from "react-icons/fa";
import {addCartItem} from "../../redux/actions/cartAction";

const RecentlyList = () => {
  const dispatch = useDispatch()
  const {product} = useSelector(state => state.productState);

  const addItem = (item) => {
    dispatch(addCartItem(item))
  }

  return (
    <div className={'recentlyCont'}>
      <ul>
        {
          product.recentlyData.length > 0 && product.recentlyData.map( item => {
            return(
              <li key={item.productId}>
                <div className={'cateItem'}>
                  <div className={'cartBtn'} onClick={()=>addItem(item)}><FaCartPlus size={15} color={colors.white}/></div>
                  <div className={'imgBox'}>
                    {
                      item.photo
                        ? <img src={IMG_PRODUCT_300_URL+`${item.photo}`} alt="logo"/>
                        : <img src={IMG_USER_NO_URL} alt="logo"/>
                    }
                  </div>
                  <div className={'textBox'}>
                    <div className={'text01'}>{item.nameKo} </div>
                    <div className={'tb01'}>
                      <div className={'text02'}>{item.price} THB</div>
                      <div className={'text03'}>{item.unit} {item.unitName}</div>
                    </div>
                  </div>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  );
};

RecentlyList.propTypes = {

};

export default RecentlyList;

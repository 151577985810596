// export const URL1 = 'https://www.gridalab.com'
export const URL1 = 'https://www.marketdawoo.com'


export const PREFIX_ROUTE = '';

export const API_URL = `${URL1}/api`;

export const IMG_RECEIPT_300_URL = `${URL1}/static/photo/receipt/300/`;
export const IMG_RECEIPT_100_URL = `${URL1}/static/photo/receipt/100/`;

export const IMG_ACCOUNT_BASE_URL = `${URL1}/static/photo/account/base/`;
export const IMG_ACCOUNT_300_URL = `${URL1}/static/photo/account/300/`;
export const IMG_ACCOUNT_100_URL = `${URL1}/static/photo/account/100/`;

export const IMG_COMPANY_300_URL = `${URL1}/static/photo/company/300/`;

export const IMG_PRODUCT_300_URL = `${URL1}/static/photo/product/300/`;
export const IMG_PRODUCT_100_URL = `${URL1}/static/photo/product/100/`;

export const IMG_USER_NO_URL = `${URL1}/static/images/profile.jpg`;
export const IMG_NO_PRODUCT_100 = `${URL1}/static/images/no_product_100.png`;
export const IMG_NO_PRODUCT_300 = `${URL1}/static/images/no_product_300.png`;

export const GOOGLE_API_KEY = 'AIzaSyA-JYLr_XizoIksiyo2rffCplBzczWEAPw';


export const BASE_IMAGES_URL = `${URL1}/static/images/`;

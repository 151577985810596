import React, {useEffect, useRef, useState} from 'react';
import Search from "../components/search";
import ProductList from "../components/productList";
import Header from "../components/header";
import ProductCategory from "../components/productCategory";


const ProductPage = () => {
  const productPageRef = useRef(null);
  const [dimensions, setDimensions] = useState({width: '', height: 450})

  const updateDimensions = () => {
    /**
     * 데이터가 가변적일땐 데이터가 없을 때를 기준으로
     * 화면이 그려지기 때문에 데이터가 변경될때 마다
     * 재호출 해서 화면사이즈를 갱신한다.
     * */
    if (productPageRef.current) {
      setDimensions({
        width: productPageRef.current.offsetWidth,
        height: productPageRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    window.addEventListener('load', updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener('load', updateDimensions);
    };
  }, []);

  return (
    <div className={'productPageWrapper'} ref={productPageRef}>
      <Header />
      <Search />
      <ProductCategory />
      <ProductList
        updateDimensions={updateDimensions}
        height={dimensions.height - 245}
      />
    </div>
  );
};

ProductPage.propTypes = {

};

export default ProductPage;

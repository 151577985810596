import React, {useEffect, useState} from 'react';
import {BiSearch} from "react-icons/bi";
import {RiCloseCircleFill} from 'react-icons/ri'
import colors from "../../styles/colors";
import PropTypes from "prop-types";

const SearchManage = (props) => {
    const { onSearch } = props

    const [searchText, setSearchText] = useState('');

    const onChange = (e) => {
        setSearchText(e.target.value);
    };

    const onReset = () => {
        setSearchText('')
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter')  {
            onSearchKeyword()
        }
    }

    const onSearchKeyword = () => {
        // if(!searchText) {
        //     alert('검색어를 입력해주세요.')
        //     return
        // }
        onSearch(searchText)
    }

    useEffect(() => {
        onSearchKeyword()
    }, [searchText]);


    return (
        <div className={'searchWrapper searchManage'}>
            <div className={'searchInput'}>
                <input
                    value={searchText}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    placeholder={'검색어를 입력해주세요.'}
                />
            </div>
            {
                searchText !== '' &&
                <div className={'resetBtn'} onClick={onReset}>
                    <RiCloseCircleFill size={22} color={colors.gray05}/>
                </div>
            }
            <div className={'searchBtn'} onClick={onSearchKeyword}>
                <BiSearch size={25} color={colors.gray05}/>
            </div>
        </div>
    );
};

SearchManage.propTypes = {
    onSearch: PropTypes.func,
};

export default SearchManage;

import React from 'react';
import '../../styles/css/header.scss'
import PropTypes from "prop-types";
import {BiMenu} from "react-icons/bi";
import {HiShoppingCart} from "react-icons/hi";
import {setIsOpenSide, setTabState} from "../../redux/actions/commAction";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import logoTitle from '../../asset/images/logo_title.png'
import logoTitle2 from '../../asset/images/logo_small.png'

// const defaultProps = {
//   userType: 'NORMAL'
// }
//
// type HeaderProps = { userType?: string } & typeof defaultProps;

const Header = () => {
  // const {bgColor} = props
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {cartList} = useSelector(state => state.cartState);
  const {profile} = useSelector(state => state.userState);
  const {userType} = profile;

  const goPage = (routeName = '/', tabName: string = '') => {
    dispatch(setIsOpenSide(false))
    navigate(routeName)
    tabName && dispatch(setTabState(tabName))
  }

  return (
    <div className={`headerWrapper`}>
      <div className={'rowBox'}>
        <div className={'lBox'} style={{backgroundImage: `url(${logoTitle2})`}}></div>
        <div className={'rBox'}>
          {
            userType === 'NORMAL'
              ?
            <div className={'cartBtn'} onClick={() => goPage('/products/cart', 'PRODUCT')}>
              <HiShoppingCart size={21}/>
              {cartList.length > 0 && <div className={'cartCounter'}>{cartList.length}</div>}
            </div>
              :
            <div className={'cartBtn'}> </div>
          }
          <div className={'menuBtn'} onClick={() => dispatch(setIsOpenSide(true))}><BiMenu size={26}/></div>
        </div>
      </div>
    </div>
  );
};

// Header.defaultProps = defaultProps
Header.propTypes = {

};

export default Header;
